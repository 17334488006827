export const authPaths = {
    user: (userId: string) => `/account/${userId}` as const,
    fidlogin: (provider: string) => `/account/fid-login/${provider}` as const,
    fidAbort: (provider: string) =>
        `/account/fid-login/${provider}/abort` as const,
    fidLink: '/account/fid-link/login' as const,
    login: (userId: string) => `${authPaths.user(userId)}/login` as const,
    logout: (userId: string) => `${authPaths.user(userId)}/logout` as const,
    simpleSession: '/account/simple-session' as const,
    signup: '/account' as const,
    passwordReset: (userId: string) =>
        `${authPaths.user(userId)}/recover-password` as const,
    activateAccount: (userId: string) =>
        `${authPaths.user(userId)}/activate` as const,
};

export const envPaths = {
    env: '/env' as const,
};
export const homePaths = {
    home: '/home' as const,
};
export const catalogPaths = {
    catalog: '/catalog' as const,
    results: '/catalog/filter' as const,
    articles: '/catalog/articles' as const,
    recommended: '/catalog/recommended' as const,
    search: '/catalog/search-suggestions' as const,
    lastSeen: '/catalog/last-seen' as const,
};
export const basketPaths = {
    all: '/checkout/basket' as const,
    updateBasket: (bindex: string) => `/checkout/basket?bindex=${bindex}` as const,
    accessories: '/checkout/basket/accessories' as const,
    paypalInstallments: () => `${basketPaths.all}/paypal-installments` as const,
    ppcpPaymentInfo: '/checkout/basket/paypal-payment-info' as const,
};
export const utilsPaths = {
    eta: '/utils/eta-calculator' as const,
    parseSeoUrl: '/seo-url/parse-seo-url' as const,
    globalRank: '/utils/global-rank' as const,
    productsHistory: '/utils/products-history' as const,
    banners: '/utils/banner-find' as const,
    billingDataByIdCIF: (rfc: string, cif: string) => `utils/billing-data/by-idcif/${rfc}/${cif}` as const,
    billingDataByCertificate: 'utils/billing-data/by-certificate' as const,
    usageCodeById: (id: string) => `/utils/billing-data/usage-codes/${id}` as const,
    getCmsPage: (id: string) => `/cms-pages/${id}` as const,
    contentReports: '/utils/content-reports' as const,
    sendEsdCode: '/utils/send-esd-code' as const,
    deleteHistory: '/utils/products-history' as const,
    noStockSubscription: '/utils/no-stock-subscription' as const,
    userDeniedCarriers: (userName: string) => `/account/${userName}/denied-carriers` as const,
    validateBankAccount: (clabe: string) => `/utils/bank-accounts/${clabe}/validate` as const,
};

export const billingDataPaths = {
    all: (userId: string) => `${authPaths.user(userId)}/billing-data` as const,
    single: (userId: string, billingId: string) =>
        `${billingDataPaths.all(userId)}/${billingId}` as const,
    select: (userId: string, billingId: string) =>
        `${authPaths.user(userId)}/billing-data/${billingId}/select` as const,
};

export const accountPaths = {
    user: (userId: string) => `/account/${userId}` as const,
    questions: (userId: string) => `/account/${userId}/questions`,
    answers: (userId: string) => `/account/${userId}/answers`,
    reviews: (userId: string) => `/account/${userId}/reviews`,
    wishlists: (email: string) => `/account/${email}/wishlists` as const,
    updateWishlist: (email: string, wishlistId: string) => `/account/${email}/wishlists/${wishlistId}` as const,
    noReviews: (userId: string) => `/account/${userId}/no-reviewed-articles`,
    productsHistory: (userEmail: string) => `/account/${userEmail}/products-history`,
    publicProfile: (userId: string) => `/public/user/${userId}`,
    addresses: (userId: string) => `/account/${userId}/addresses`,
    addressesSingle: (userId: string, addressId: string) => `/account/${userId}/addresses/${addressId}`,
    billingData: (userId: string) => `/account/${userId}/billing-data`,
    billingDataSingle: (userId: string, billingId:string) => `/account/${userId}/billing-data/${billingId}`,
    billingDataSelect: (userId: string, billingId:string) => `/account/${userId}/billing-data/${billingId}/select`,
    updatePublicProfile: (email: string) => `/account/${email}/public-profile/update`,
    paypalBillingAgreement: (email: string) => `/account/${email}/paypal/billing-agreements` as const,
    deletePaypalBillingAgreement: (email: string, agreementId: string) => `/account/${email}/paypal/billing-agreements/${agreementId}` as const,
    orders: (email: string, id: string | undefined = undefined) => `/account/${email}/orders${id ? `/${id}` : ''}` as const,
    ordersTrackingCodes: (email: string, id: string | undefined = undefined) => `/account/${email}/orders${id ? `/${id}` : ''}/tracking-codes` as const,
    ordersConfirmationMail: (email: string, orderId: string) => `/account/${email}/orders/${orderId}/confirmation-email` as const,
    ordersPreviewMail: (email: string, orderId: string) => `/account/${email}/orders/${orderId}/preview-email` as const,
    cancelOrders: (email: string, orderId: string) => `/account/${email}/orders/${orderId}` as const,
    addressValidate: (email: string, addressId: string) => `/account/${email}/addresses/${addressId}/activate` as const,
    md5: (userId: string) => `${authPaths.user(userId)}/delivery-address-md5` as const,
    ordersOverdueProducts: (email: string, orderId: string) => `${authPaths.user(email)}/orders/${orderId}/overdue-articles` as const,
    licenses: (email: string) => `${authPaths.user(email)}/licenses` as const,
    paymentBlock: (email: string, orderId: string) => `${authPaths.user(email)}/orders/${orderId}/payment-block` as const,
    paypalRememberedCards: (email: string) => `/account/${email}/paypal/remembered-cards` as const,
    paypalDeleteRememberedCard: (email: string, cardId: string) => `/account/${email}/paypal/remembered-cards/${cardId}` as const,
    paypalCreateRememberedCard: (email: string) => `/account/${email}/paypal/remembered-cards` as const,
    proportionalDeliveryCost: (email: string, orderId: string) => `${authPaths.user(email)}/orders/${orderId}/proportional-delivery-cost` as const,
    returnCases: (email: string, orderId: string) => `${authPaths.user(email)}/orders/${orderId}/return-cases` as const,
    articlesPerWaybill: (email: string, orderId: string) => `${authPaths.user(email)}/orders/${orderId}/articles-per-waybill`as const,
};

export const refundsPaths = {
    saf: (userEmail: string) =>
        `utils/refunds/${userEmail}/available-saf` as const,
    refundHistory: (userEmail: string) => `/account/${userEmail}/refunds`,
    saveRefund: (userEmail: string) => `/account/${userEmail}/save-refund`,
    bankAccounts: (userEmail: string) => `/account/${userEmail}/bank-accounts`,
    bankAccountConfirm: (userEmail: string, bankAccountId: string) =>
        `/account/${userEmail}/bank-accounts/${bankAccountId}/confirm`,
    bankAccountRegenerateCode: (userEmail: string, bankAccountId: string) =>
        `/account/${userEmail}/bank-accounts/${bankAccountId}/regenerate-code`,
    bankAccountDelete: (userEmail: string, bankAccountId: string) =>
        `/account/${userEmail}/bank-accounts/${bankAccountId}`,
};

export const productPaths = {
    details: (productId: string) => `/details/${productId}` as const,
    questions: (productId: string) =>
        `${productPaths.details(productId)}/questions-and-answers`,
    questionSingle: (productId: string, questionId: string) =>
        `${productPaths.questions(productId)}/${questionId}` as const,
    reviewsMasterTables: '/reviews',
    reviews: (productId: string) => `${productPaths.details(productId)}/reviews`,
    rateReview: (productId: string, reviewId: string) =>
        `${productPaths.reviews(productId)}/${reviewId}/vote`,
    wishlist: (productId: string) =>
        `${productPaths.details(productId)}/wishlist`,
};

export const validatorPaths = {
    address: (email: string, addressId: string) => `/validator/${email}/addresses/${addressId}` as const,
    deleteAccount: (email: string) => `/validator/${email}/delete` as const,
    verifyIdentity: (email: string) => `/validator/${email}/identity` as const,
};
export const brandsPaths = {
    allBrands: '/brands' as const,
};

export const qnaPaths = {
    questionsAndAnswers: (articleId: string) =>
        `/details/${articleId}/questions-and-answers` as const,
    questionsNotifications: (questionId: string) =>
        `/questions/${questionId}/notification`,
    postAnswer: (articleId: string, questionId: string) =>
        `/details/${articleId}/questions-and-answers/${questionId}` as const,
};

export const saveForLaterPaths = {
    all: '/checkout/saved-for-later' as const,
    single: (articleId: string) =>
        `/checkout/saved-for-later/${articleId}` as const,
};
export const downloadsPaths = {
    all: (userId: string) => `${authPaths.user(userId)}/downloads` as const,
};

export const paymentMethodPaths = {
    all: '/checkout/payment-methods' as const,
    validate: () => `${paymentMethodPaths.all}/validate` as const,
};

export const vouchersPaths = {
    add: '/checkout/vouchers' as const,
};

export const warrantiesPaths = {
    all: (email: string) => `${authPaths.user(email)}/warranties/small` as const,
    findProductBySerial: (serialNumber: string) => `/warranties/care-pack/find-serial/${serialNumber}` as const,
    deleteWarrantyData: (email: string, orderNumber: string, articleId: string, warrantyId: string) =>
        `${authPaths.user(email)}/orders/${orderNumber}/warranties/${articleId}/data/${warrantyId}` as const,
    createWarrantyData: (email: string, orderNumber: string, articleId: string) =>
        `${authPaths.user(email)}/orders/${orderNumber}/warranties/${articleId}` as const,
    getSingleWarrantyData: (email: string, orderNumber: string, brand: string) =>
        `${authPaths.user(email)}/orders/${orderNumber}/warranties/brand/${brand}` as const,
    updateWarrantyData: (email: string, orderNumber: string, articleId: string, warrantyId: string) =>
        `${authPaths.user(email)}/orders/${orderNumber}/warranties/${articleId}/data/${warrantyId}` as const,
    sendWarrantyData: (email: string, orderNumber: string, brand: string) =>
        `${authPaths.user(email)}/orders/${orderNumber}/warranties/brand/${brand}/send` as const,
    sendAIGEmail: (email:string, orderId: string) =>
        `${authPaths.user(email)}/orders/${orderId}/aig-email` as const,
};
export const reviewsPaths = {
    create: 'msReviews/reviews' as const,
    getAll: 'msReviews/reviews' as const,
};

// Note: /orders not /account/orders
export const ordersPaths = {
    order: (orderId: string) => `/orders/${orderId}` as const,
    cancelOverdueProducts: (orderId: string) => `${ordersPaths.order(orderId)}/cancel-expired-articles` as const,
    sendWayBillByReturnCase: (orderId: string, returnCaseId: string) => `${ordersPaths.order(orderId)}/return/${returnCaseId}/send-waybill` as const,
    sendWayBillByOrder: (orderId: string) => `${ordersPaths.order(orderId)}/send-waybill` as const,
    archiveReturnCase: (orderId: string, returnCaseId: string) => `${ordersPaths.order(orderId)}/return/${returnCaseId}/archive` as const,
    createReturnCase: (orderId: string) => `${ordersPaths.order(orderId)}/return` as const,
    updateReturnCase: (orderId: string, rmId: string) => `${ordersPaths.order(orderId)}/return/${rmId}/update` as const,
    paymentInstructions: (orderId: string) => `${ordersPaths.order(orderId)}/payment-information` as const,
};

// Note: /paypal not /account/paypal
export const paypalPaths = {
    installments: '/paypal/installments/msi' as const,
    paypalInstallments: `/paypal/installments` as const,
    paypalTransactionContext: `/paypal/transaction-context` as const,
    paypalCreateOrder: `/paypal/orders` as const,
    paypalSettings: `/paypal/settings` as const,
    paypalFraudnet: `/paypal/settings/fraudnet` as const,
    paypalPaymentInfo: (id: string) => `/paypal/orders/${id}` as const,
};

export const afPaths = {
    getAf: (id: string) => `/alchemy-force/${id}/pdp` as const,
    getBrands: (id: string) => `/alchemy-force/${id}/brands` as const,
    getCrossSellingArticles: (id: string) => `/alchemy-force/${id}/cross-selling` as const,
    getAttributes: (id: string) => `/alchemy-force/${id}/attributes` as const,
    getComponents: (id: string) => `/alchemy-force/${id}/components` as const,
    getEta: `/checkout/basket/eta-assembly` as const,
    installments: '/paypal/installments/msi' as const,
    detailsMsi: (id: string) => `/details/${id}/msi-data` as const,
    addCart: '/checkout/basket',
    getArticles: (query: string) => `https://www.abasteo.mx/api/v0.1/catalog/articles${query}` as const,
    getConfigurator: (id: string) => `/alchemy-force/${id}/configurator` as const,
    getVersions: (id: string) => `/alchemy-force/${id}/versions` as const,
};
