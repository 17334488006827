<script setup lang="ts">
  import CpEmptyState from '~/components/cp/CpEmptyState/CpEmptyState.vue';
  import Cp404 from '~/components/cp/CpPage404/CpPage404.vue';

  const {t} = useI18n();
  const {b, e} = useCpBem('cp-error-page');

  const props = defineProps({
    error: Error,
  });

  const navHeaderOptions = ref({
    showLongSearchBar: true,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: false,
  });

  // Use this var if you need to hide header and footer in error screen
  const showNavigationComponents = ref(true);
  if (props.error?.message === 'infinite redirect' && process.server) {
    console.log(props.error);
  }
  if (useRoute().name === 'ppcp-form') showNavigationComponents.value = false;
</script>

<template>
    <div :class="b">
        <CpHeader
            v-if="navHeaderOptions && showNavigationComponents"
            :class="e('header')"
            v-bind="navHeaderOptions"
        />
        <Cp404 v-if="error.statusCode === 404" />

        <CpEmptyState
            v-else
            icon="security_update_warning"
            :title="t('title')"
            :body="t('body')"
            :button-label="t('button')"
            @empty-state-action="clearError (error.url)"
        />
        <CpBottomNavMenu v-if="error.statusCode !== 404 && showNavigationComponents" :active="null" />
    </div>
</template>

<style scoped lang="scss">
  .cp-error-page {
    display: flex;
    flex-direction: column;
    height: 100vh;

    &__header {
      z-index: 50;
    }
  }
</style>

<i18n locale="es-US" lang="yaml">
  title: 'Error inesperado'
  body: 'Lo sentimos, ocurrió un error al realizar la acción; favor de intentarlo nuevamente. Si el problema persiste, comunícate con nosotros.'
  button: 'Reintentar'
</i18n>
