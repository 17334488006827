<script setup lang="ts">
  import {useI18n} from 'vue-i18n';
  import useCpBem from '~/composables/useCpBem';
  import CpIconEmptyState from '~/components/cp/CpIcon/CpIconEmptyState/CpIconEmptyState.vue';
  import CpButton from '~/components/cp/CpButton/CpButton.vue';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import CpTextParser from '~/components/cp/CpTextParser/CpTextParser.vue';

  defineProps<{
      icon: string
      title: string
      buttonLabel: string
      subtitle?: string
      body?: string
      widthOutPadding?: boolean
      isSearch?: boolean
      centerTitle?: boolean
      mainBtnTestId?: string
      useBeforeContentSlot?: boolean
  }>();
  const {b, e, em} = useCpBem('cp-empty-state');
  const {t} = useI18n();
  const emit = defineEmits<{
    (e:'empty-state-action'): void
  }>();

</script>
<template>
    <div :class="b">
        <slot v-if="useBeforeContentSlot" name="beforeContent" />
        <div :class="e('main-container')">
            <CpIconEmptyState :icon="icon" :class="e('icon')" />
            <h2 :class="em('title', {centerTitle})">
                {{ title }}
            </h2>
            <h2 v-if="subtitle" :class="e('title')">
                {{ subtitle }}
            </h2>
            <CpTextParser v-if="body"
                :text="body"
                variant="body"
                block
                :class="e('body-simple')"
            />
            <div v-else :class="em('body', { 'width-out-padding': widthOutPadding })">
                <slot v-if="!isSearch" name="body" />
                <div v-else :class="e('search-body')">
                    <div :class="e('search-title')">
                        <CpText variant="subheading" type="neutral-dark" style="margin-bottom: 8px">
                            {{ t('searchAdvices') }}:
                        </CpText>
                    </div>
                    <CpText variant="body-regular" type="neutral-dark" style="margin-bottom: 8px">
                        · {{ t('checkSpelling') }}
                    </CpText>
                    <CpText variant="body-regular" type="neutral-dark" style="margin-bottom: 8px">
                        · {{ t('useGeneralTerms') }}
                    </CpText>
                    <CpText variant="body-regular" type="neutral-dark">
                        · {{ t('nextButton') }}.
                    </CpText>
                </div>
            </div>
            <CpButton
                :label="buttonLabel"
                color="primary"
                kind="label"
                hollow
                full-width
                :class="e('actions')"
                :data-testid="mainBtnTestId ? mainBtnTestId : 'empty-state-main-button'"
                @click="emit('empty-state-action')"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
  .cp-empty-state {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: $cp-gutter;

      &__main-container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
      }

      &__icon {
          margin-bottom: 8px;
      }

      &__title {
          color: var(--color-primary);
          font-family: var(--cp-font-family);
          &--center-title {
            text-align: center;
          }
      }

      &__body {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 32px;
          margin-bottom: 32px;
          padding: 0 16px;

          &--width-out-padding {
            padding: 0;
          }
      }

      &__body-simple {
        text-align: center;
        margin-top: $cp-gutter-xlarge;
        margin-bottom: $cp-gutter-xlarge;
        line-height: normal;
      }

      &__search-body {
          display: flex;
          flex-direction: column;
      }

      &__search-title {
          display: flex;
      }

      &__slot {
          margin-top: 32px;
          margin-bottom: 32px;
      }

      &__actions {
          width: 100%;
      }
  }
</style>

<i18n locale="es-US" lang="yaml">
  searchAdvices: "Consejos de busqueda"
  checkSpelling: "Revisa la ortografía"
  useGeneralTerms: "Amplía tu búsqueda utilizando menos palabras o más generales."
  nextButton: "O presiona el siguiente botón"
</i18n>
