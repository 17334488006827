import {defineStore} from 'pinia';
import {StorageRepository} from '~/utils/storageRepository';
import {STORE_KEYS} from '~/stores/storeKeys';

export interface RecentQueryType {
  query: string
  kind: 'AUTOCOMPLETE' | 'RECENT'
}

export interface IRecentQueriesStore {
  recentQueries: RecentQueryType[],
  autocompleteQueries: RecentQueryType[],
  selectedQuery: string,
  focus: boolean
}

export const useRecentQueriesStore = defineStore(STORE_KEYS.RecentQueries, {
  state: (): IRecentQueriesStore => {
    return {
      recentQueries: [],
      autocompleteQueries: [],
      selectedQuery: '',
      focus: false,
    };
  },
  actions: {
    async refresh() {
      const storage = new StorageRepository<RecentQueryType>('recentQueries');
      this.recentQueries = await storage.find();
    },
    async addNewQuery(query: string) {
      const storage = new StorageRepository<RecentQueryType>('recentQueries');
      const queryInDB = await storage.findOne((e: RecentQueryType) => e.query === query);
      if (queryInDB) {
        await this.deleteSingleRecentQuery(query);
      }
      await storage.create({query, kind: 'RECENT'});
      this.recentQueries.push({query, kind: 'RECENT'});
    },
    async deleteSingleRecentQuery(query: string) {
      const storage = new StorageRepository<RecentQueryType>('recentQueries');
      await storage.delete((e: RecentQueryType) => e.query === query);
      this.recentQueries.splice(this.recentQueries.findIndex((e: RecentQueryType) => e.query === query), 1);
    },
    async refreshAutocomplete() {
      if (this.selectedQuery.length <= 1) {
        this.autocompleteQueries = [];
        return;
      }

      try {
        const {data: catalogSearchData} = await useCatalogSearchParams(this.selectedQuery);

        this.autocompleteQueries = catalogSearchData.value ?
          catalogSearchData.value?.autocomplete.map((a) => ({query: a, kind: 'AUTOCOMPLETE'})) :
          [];
      } catch {
        this.autocompleteQueries = [];
      }
    },
    selectQuery(query: string) {
      this.selectedQuery = query;
    },
  },
  getters: {
    getRecentQueries(state) {
      return state.recentQueries;
    },
    getSelectedQuery(state) {
      return state.selectedQuery;
    },
    getQueriesToShow(state) {
      try {
        const selected = state.selectedQuery ?? '';
        const recent = state.recentQueries
            .filter((q: RecentQueryType) => q.query.toString().toLowerCase().includes(selected.toLowerCase()))
            .reverse()
            .slice(0, 8);

        const autocomplete = state.autocompleteQueries
            .slice(0, 8);

        return [...recent, ...autocomplete];
      } catch {
        return [];
      }
    },
    getFocus(state) {
      return state.focus;
    },
  },
});
