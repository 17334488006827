import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';
import type {IWishlist} from '~/types';
export interface WishlistProductToAdd {
    id: string;
    price: number;
    sku: string;
    title: string;
    brand?: string;
    category?: string;
}

interface WishlistAdd {
    product: WishlistProductToAdd;
    wishlistId: string;
}

interface WishlistProductUpdate {
    productId: string;
    wishlistId: string;
}

interface WishlistProductMove {
    currentWishlist: string;
    productId: string;
    selectedWishlist: string;
}

interface WishlistDefaultUpdate {
    wishlistId: string;
}

interface WishlistUpdate {
    description: string;
    id: string;
    title: string;
}

// NOTE: for now we'll only use deleList action but with it can be used for every action tracking
type wishlistActions = 'addProduct' | 'createList' | 'deleteList' | 'updateList' | 'setDefaultList'
interface wishlistAction {
    type: wishlistActions
    wishlist: IWishlist
    productId?: string
}

interface IWishlistStore {
    selectedWishlist: IWishlist
    editWishlist: Partial<IWishlist>
    currentArticleId: string
    lastAction: wishlistAction | null
}
// TODO: type state
export const useWishlistStore = defineStore(STORE_KEYS.Wishlist, {
    state: ():IWishlistStore => ({
        selectedWishlist: {
            id: '',
            title: '',
            description: '',
            isDefault: false,
            articles: [],
        } as IWishlist,
        editWishlist: {
            id: '',
            title: '',
            description: '',
            isDefault: false,
        },
        currentArticleId: '',
        lastAction: null,
    }),
    actions: {
        setCurrentList(data: IWishlist) {
            this.selectedWishlist = data;
        },
        setEditList(data: Pick<IWishlist, 'id' | 'title' | 'isDefault' | 'description'>) {
            this.editWishlist = data;
        },
        setCurrentArticle(id: string) {
            this.currentArticleId = id;
        },
        setLastAction(action: wishlistActions, wishlist: IWishlist, productId?: string) {
            this.lastAction = {
                type: action,
                wishlist,
                productId,
            };
        },
        updateCurrentList(data: IWishlist[]) {
            const list = data.find((list) => list.id === this.selectedWishlist.id);
            if (list) {
 this.selectedWishlist = list;
}
        },
    },
    getters: {
        getSelectedWishlist: (state) => state.selectedWishlist,
        getWishlistToEdit: (state) => state.editWishlist,
        getLastWishlistAction: (state) => state.lastAction,
    },
});
