export interface BemModifiers {
    [mod: string]: string | boolean | undefined
}
// based on a block or element, apply modifiers
export function makeCssClass(base: string, modifiers: BemModifiers): string[] {
  const c = [base];
  for (const m of Object.keys(modifiers)) {
    const mv = modifiers[m];
    if (!mv) {
 continue;
} // if falsy value then don't do anything
    if (typeof mv === 'string') { // button--type-primary
      c.push(`${base}--${camelToKebab(m)}-${camelToKebab(mv)}`);
    } else { // boolean, button--primary
      c.push(`${base}--${camelToKebab(m)}`);
    }
  }
  return c;
}
function camelToKebab(camel: string): string {
  return camel.replace(/[A-Z]/g, '-$&').replace(/^-/, '').toLowerCase();
}
export default function useCpBem(block: string) {
  const b = camelToKebab(block);
  return {
    /** block only */
    b,
    /** element */
    e(e: string|TemplateStringsArray, ...bb: string[]): string {
      // in case of tagged string templates
      if (Array.isArray(e)) {
 e = e.reduce((c, x, i) => c + x + (bb[i] ?? ''), '');
}
      return `${b}__${camelToKebab(e as string)}`;
    },
    /** modifiers to a block or element */
    m(be: string, m: BemModifiers): string[] {
      return makeCssClass(be, m);
    },
    /** block, with modifiers */
    bm(m: BemModifiers): string[] {
      return makeCssClass(b, m);
    },
    /** element, with modifiers */
    em(e: string, m: BemModifiers): string[] {
      if (!m) {
 throw new Error('cp bem "em" requires an object with options, use "e" when no options are needed');
}
      return makeCssClass(`${b}__${camelToKebab(e)}`, m);
    },
  };
}
