<template>
    <div>
        <!-- see: https://nuxt.com/docs/getting-started/seo-meta#add-external-css -->
        <!-- see: https://developers.google.com/fonts/docs/material_symbols#use_in_web -->
        <!--<Link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" crossorigin="" />-->
        <!-- <Meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" /> -->
        <!--        TODO ADD this validation only for ios-->
        <!-- <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">-->
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<style lang="scss">
@import 'assets/global.css';
@import 'node_modules/vuetify/lib/styles/main.sass';
</style>
