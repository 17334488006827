import type {AsyncDataOptions} from '#app';
import type {ICatMan} from '~/types';
import {QUERY_KEYS} from '~/utils/services/keys';
import {categories} from '#nuxt-prepare';

export const getFlatCategories = (categoryTree: ICatMan[]) => {
  const flatCategories: Record<string, ICatMan> = {};
  const reduce = (tree: Array<ICatMan>) =>
    tree.forEach((category) => {
      flatCategories[category.id] = category;
      reduce(category.subCategories ?? []);
    });

  reduce(categoryTree);

  return flatCategories;
};

export const flatCategories = getFlatCategories(categories as unknown as ICatMan[] ?? []);

export const fetchFlatCategories = async () => {
  const flatCategories = await getFlatCategories(categories as unknown as ICatMan[]);
  return flatCategories;
};

export const useFlatCategories = (categoriesIds?: string[], numberToDisplay = 6, options: AsyncDataOptions<Record<string, ICatMan>, any> = {}) => {
  const cacheOptions = useClientCache();
  if (Array.isArray(categoriesIds)) {
    const key = 'home-transform';
    return useCachedAsyncData(QUERY_KEYS.categories.flat + key, fetchFlatCategories, {
      getCachedData: cacheOptions.getCachedData,
      transform: (data) => {
        return categoriesIds.map((id) => ({
          ...data![id],
          fetchedAt: new Date(),
        })).slice(0, numberToDisplay);
      },
    }, {cacheKey: QUERY_KEYS.categories.flat + key, cacheTags: [QUERY_KEYS.categories.flat + key], cacheExpires: 3600 * 12});
  }
  return useCachedAsyncData(QUERY_KEYS.categories.flat, fetchFlatCategories, {...cacheOptions, ...options},
    {cacheKey: QUERY_KEYS.categories.flat, cacheTags: [QUERY_KEYS.categories.flat], cacheExpires: 3600 * 12},
  );
};

export const fetchCategoryTree = () => {
  return categories as unknown as ICatMan[];
};

export const useCategoryTree = (options: AsyncDataOptions<ICatMan[], any> = {}) => {
  return useAsyncData(QUERY_KEYS.categories.tree, async () => {
    return await fetchCategoryTree();
  }, options);
};
