// import { useOrder } from '~/composables/';

export const PaymentTypes = {
    oxxo: 'cpoxxopay',
    referenced: 'bca77b7e8bdb60c1d26035e3ace2fcdb',
    /** PayPal Billing Agreement */
    paypal: 'cppaypalbilling',
    /** PayPal PPCP */
    paypal_ppcp: 'cppaypalppcp',
} as const;

export const OrderStatuses = {
    CANCELLED: 0,
    CANCELLATION_PROCESS: 10,
    NULL: 1000,
    PENDING_TO_BE_PAID: 1010,
    WAITING_FOR_ONEWAY_BILL: 1020,
    PARTIALLY_ON_TRANSIT: 1030,
    FULLY_ON_TRANSIT: 1040,
    PARTIALLY_DELIVERED: 1050,
    FULLY_DELIVERED: 1060,
    PARTIALLY_ON_TRANSIT_DELIVERED: 1070,
    WAITING_FOR_COURIER: 1080,
    PENDING_TO_BE_SHIPPED: 2010,
    PARTIALLY_ON_TRANSIT2: 2020,
    FULLY_ON_TRANSIT2: 2030,
    PARTIALLY_DELIVERED2: 2040,
    DELIVERED_PAYMENT_PENDING_CARD_EXPIRED: 2050,
    DELIVERED_PAYMENT_PENDING_CARD_NOT_EXPIRED: 2060,
    DELIVERED_AND_PAID: 2070,
    PARTIALLY_ON_TRANSIT_DELIVERED2: 2090,
    PENDING_TO_BE_SHIPPED2: 2100,
} as const;

export const useOrderStatusCase = (order: any) => {
    // const order = useOrder()

    switch (order.status.nr) {
        case OrderStatuses.FULLY_DELIVERED:
            return 'delivered-full';
        case OrderStatuses.PARTIALLY_DELIVERED:
        case OrderStatuses.PARTIALLY_DELIVERED2:
        case OrderStatuses.PARTIALLY_ON_TRANSIT_DELIVERED:
        case OrderStatuses.PARTIALLY_ON_TRANSIT_DELIVERED2:
        case OrderStatuses.DELIVERED_AND_PAID:
            return 'delivered-partial';
        case OrderStatuses.PENDING_TO_BE_PAID:
            if (
                order.payment.type !== PaymentTypes.oxxo &&
                order.payment.type !== PaymentTypes.referenced
            ) {
                return 'processing-payment';
            }
            return 'pending-payment';
        case OrderStatuses.DELIVERED_PAYMENT_PENDING_CARD_EXPIRED:
        case OrderStatuses.DELIVERED_PAYMENT_PENDING_CARD_NOT_EXPIRED:
            return 'expired-card';
        case OrderStatuses.WAITING_FOR_ONEWAY_BILL:
        case OrderStatuses.PARTIALLY_ON_TRANSIT:
        case OrderStatuses.PARTIALLY_ON_TRANSIT2:
        case OrderStatuses.FULLY_ON_TRANSIT:
        case OrderStatuses.FULLY_ON_TRANSIT2:
        case OrderStatuses.WAITING_FOR_COURIER:
        case OrderStatuses.PENDING_TO_BE_SHIPPED:
        case OrderStatuses.PENDING_TO_BE_SHIPPED2:
            return 'waiting';
        case OrderStatuses.CANCELLED:
            return 'cancelled';
        case OrderStatuses.CANCELLATION_PROCESS:
            return 'cancellation-process';
        case OrderStatuses.NULL:
            return 'null';
        default:
            console.error(
                `Unhandled order status: ${order.status.nr} - ${order.status.text}`,
            );
    }
};
