import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';

export const useBannerManagerStore = defineStore(
  STORE_KEYS.BannerManager,
  () => {
    // display info about cells, etc.
    const debug = ref(false);
  },
);
