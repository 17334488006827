import validate from "/opt/atlassian/pipelines/agent/build/node_modules/nuxt/dist/pages/runtime/validate.js";
import router_45global from "/opt/atlassian/pipelines/agent/build/middleware/router.global.ts";
export const globalMiddleware = [
  validate,
  router_45global
]
export const namedMiddleware = {
  "auth-guard": () => import("/opt/atlassian/pipelines/agent/build/middleware/auth-guard.ts"),
  "autonomous-refunds-guard": () => import("/opt/atlassian/pipelines/agent/build/middleware/autonomous-refunds-guard.ts"),
  "internal-users-guard": () => import("/opt/atlassian/pipelines/agent/build/middleware/internal-users-guard.ts"),
  "return-module-guard": () => import("/opt/atlassian/pipelines/agent/build/middleware/return-module-guard.ts"),
  "warranties-guard": () => import("/opt/atlassian/pipelines/agent/build/middleware/warranties-guard.ts"),
  "without-session-guard": () => import("/opt/atlassian/pipelines/agent/build/middleware/without-session-guard.ts"),
  auth: () => import("/opt/atlassian/pipelines/agent/build/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/auth.mjs")
}