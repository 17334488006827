import type {H3Event} from 'h3';
import type {KeysOf} from 'nuxt/dist/app/composables/asyncData';
import {type NuxtApp, type AsyncDataOptions, callWithNuxt} from '#app';

export function useCachedAsyncData<ResT, DataT = ResT, PickKeys extends KeysOf<DataT> = KeysOf<DataT>, DefaultT = null>(
  key: string,
  handler: (ctx?: NuxtApp) => Promise<ResT>,
  options: AsyncDataOptions<ResT, DataT, PickKeys, DefaultT>,
  cacheOptions: {
    cacheKey: string
    cacheTags: string[]
    cacheExpires?: number
    event?: H3Event
  },
) {
  // We need to cache transformed value to prevent value from being transformed every time.
  const transform = options?.transform;
  // Remove transform from options, so useAsyncData doesn't transform it again
  const optionsWithoutTransform = {
    ...options,
    transform: undefined,
  };

  const app = useNuxtApp();

  return useAsyncData(
    key,
    async () => {
      const {value, addToCache, expires} = await useDataCache<DataT | Awaited<ResT>>(cacheOptions.cacheKey, cacheOptions.event);

      if (value) {
        const currentTime = Math.floor(Date.now() / 1000) + (cacheOptions.cacheExpires ?? 0);
        if (expires && expires < currentTime - 5 * 60) {
          callWithNuxt(app, () => {
            handler().then((_result) => {
              const result = transform ? transform(_result) : _result;
              addToCache(result as DataT, cacheOptions.cacheTags, cacheOptions.cacheExpires);
            });
          });
        }
        /**
         * This helps to update fetchedAt for every client with the current time
         * and avoid refresh requests when client cache (5 minutes) is expired
         */
        // @ts-ignore
        if ('fetchedAt' in value) {
          value.fetchedAt = new Date();
        }
        return value;
      }

      const _result = await callWithNuxt(app, async () => await handler());
      const result = transform ?
        transform(_result) :
        _result;

      addToCache(result, cacheOptions.cacheTags, cacheOptions.cacheExpires);

      return result;
    },
    optionsWithoutTransform,
  );
}
