import {getAFCatalog, getAFCatalogArticles, getAFCatalogFilter} from '~/utils/services/api/ms-catalog/ms-catalog';
import type {CatalogType, ICatalog, ICpArticle, IFilters} from '~/types';
import type {AsyncDataOptions} from '#app';

export type OrderCatalogTypes = 'cprelevance-desc' | 'oxtitle-desc' | 'oxtitle-asc' | 'oxvarminprice-desc'
  | 'oxvarminprice-asc' | 'oxstock-desc' | 'oxinsert-desc' | 'oxrating-desc';

export const useAFCatalog = (id: string, type: CatalogType, page: number) => {
  const app = useNuxtApp();
  const key = `catalog-ms/${type}/${id}/page-${page}`;
  return useCachedAsyncData(
    key,
    () => {
      return getAFCatalog(id, type, page);
    },
    {
      ...useClientCache(),
      transform: (input: any) => {
        const {
          autocomplete, filters, id, title, specialLinks, redirectUrl,
          seoProps, featuredCategories, bannerContext, isParent,
        } = input?.data ?? {};
        return {
          autocomplete,
          isParent: isParent,
          filters: {
            baseLink: filters.baseLink,
            filterElements: filters.filterElements?.map((a: any) => {
              return {
                title: a.title,
                tpl: a.tpl,
                showToggleButton: a.showToggleButton,
                hide: a.hide,
                id: a.id,
                max: a.max,
                min: a.min,
                name: a.name,
                rootCategories: a.rootCategories,
                checkBoxes: a.checkBoxes?.map((b: any) => {
                  return {
                    canHide: b.canHide,
                    count: b.count,
                    title: b.title,
                    value: b.value,
                    hash: b.hash,
                    name: b.name,
                  };
                }),
              };
            }),
          },
          id,
          title,
          specialLinks,
          redirectUrl,
          featuredCategories,
          seoProps,
          bannerContext: bannerContext.filter((banner: any) => banner.value.length > 0),
          fetchedAt: new Date(),
        } as unknown as ICatalog;
      },
    },
    {
      cacheKey: key,
      cacheTags: [key],
      cacheExpires: 3600 * 12,
      event: app.ssrContext?.event,
    },
  );
};

export const useAFCatalogFilter = (
  id: string, type: string, filters: any, orderBy: string, page: number, isAF = false, pageSize = 24,
) => {
  const app = useNuxtApp();
  const key = `catalog-filter-ms/${type}/${id}/page-${page}/${orderBy}/filters-${hashCode(JSON.stringify(filters))}`;
  return useCachedAsyncData(
    key,
    () => {
      return getAFCatalogFilter(id, type, filters, orderBy, page, pageSize);
    },
    {
      ...useClientCache(),
      transform: (input: any) => {
        const {articleIds, urls, total, seoProps, counts} = input?.data ?? {};
        return {
          articleIds,
          urls,
          total,
          seoProps,
          counts,
          fetchedAt: new Date(),
        } as unknown as IFilters;
      },
    },
    {
      cacheKey: key,
      cacheTags: [key],
      cacheExpires: 3600 * 12,
      event: app.ssrContext?.event,
    },
  );
};

export const afArticlesTransform = (data: { data: ICpArticle[] }) => {
  const articlesMap = data.data.map(({
    id,
    title,
    sku,
    offer,
    rating,
    price,
    tPrice,
    picture,
    isDailyOffer,
    eol,
    stockStatus,
    freeShipping,
    link,
    isNew,
    badges,
    listAttributes,
    brand,
    pictures,
    category,
    isEsd,
    shipping,
  }: any) => ({
    id,
    title,
    sku,
    stars: rating.global,
    reviews: rating.total,
    priceOld: formatAsCurrency(tPrice),
    price: formatAsCurrency(price),
    offer: offer?.percent ?? Math.round(((1 - ((price / tPrice))) * 100)),
    isNew,
    outStock: eol || stockStatus === -1,
    isFeatured: isDailyOffer,
    freeShipping,
    productImage: picture,
    graphicImage: badges?.find((b: { cl: string[], tags: string[] }) =>
      b.cl.includes('alist') && b.tags.includes('center-bottom'))?.badgeUrl,
    processorKind: listAttributes?.find((a: { value: string }) =>
      a.value.toLowerCase().includes('amd')) ? 'amd' : 'intel',
    specs: listAttributes?.map((a: { title: string, value: string }) =>
      ({title: a.title, description: a.value})) ?? [],
    link,
    picture,
    brand,
    fetchedAt: new Date(),

    rating,
    isDailyOffer,
    eol,
    stockStatus,
    pictures,
    category,
    isEsd,
    shipping,
  }));

  return {
    articles: articlesMap as unknown as ICpArticle[],
  };
};

export const articlesTransform = (data: { data: ICpArticle[] }) => {
  const transformArticles = data.data.map(({
    id,
    rating,
    title,
    offer,
    price,
    tPrice,
    etaRange,
    picture,
    isDailyOffer,
    eol,
    stockStatus,
    isEsd,
    freeShipping,
    link,
    isOffer,
    sku,
    brand,
    pictures,
    category,
    shipping,
  }) => ({
    id,
    rating: {
      ...rating,
      global: parseInt((rating.global as string) ?? '0'),
      total: parseInt((rating.total as string) ?? '0'),
    },
    title,
    offer: {
      ...offer,
      percent: price != tPrice ? Math.round(((1 - (((price as number) / tPrice))) * 100)).toString() : false,
    },
    etaRange,
    price,
    tPrice,
    picture,
    isDailyOffer,
    eol,
    stockStatus: (stockStatus as string) === 'OutOfStock' ? -1 : 1,
    isEsd,
    freeShipping,
    link,
    isOffer,
    sku,
    brand,

    // New props
    stars: rating.global,
    reviews: rating.total,
    productImage: picture,
    outStock: eol || stockStatus === -1,
    fetchedAt: new Date(),
    pictures,
    category,
    shipping,
  }));
  return {articles: transformArticles as unknown as ICpArticle[], fetchedAt: new Date()};
};

export const useAFCatalogArticles = (
  articlesIds: string[],
  isAF = false,
  type = '',
  bySku = false,
  options: AsyncDataOptions<{ articles: ICpArticle[] }> = {},
) => {
  const key = 'articles-' + type + '-' + articlesIds?.join(',');
  return useAsyncData(
    key,
    () => {
      return getAFCatalogArticles(articlesIds, bySku ? true : undefined);
    },
    {
      ...useClientCache(),
      transform: (input: any) => isAF ? afArticlesTransform(input) : articlesTransform(input),
      ...options,
    },
  );
};

export const useGetOneCatalogArticle = (
  articleId: string,
  isAF = false,
) => {
  const key = 'article-' + articleId;
  return useAsyncData(
    key,
    () => {
      return getAFCatalogArticles([articleId], undefined);
    },
    {
      ...useClientCache(),
      transform: (input: any) => {
        const dataMap = isAF ? afArticlesTransform(input) : articlesTransform(input);

        if (dataMap.articles.length > 0) {
          return dataMap.articles[0] as any;
        }

        return undefined as any;
      },
    },
  );
};
