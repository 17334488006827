import {usePdp, useWarrantiesStore, useRouterStore} from '~/stores';

export const useCpGoBack = () => {
  try {
    const router = useRouter();
    const route = useRoute();
    const store = useRouterStore();
    store.forceAmbiguous = true;

    // Default page
    if (router.options.history.state.back === null) {
      const pdpPagesChild: string[] = [
        'details-gallery', 'details-all-questions-and-answers', 'details-all-reviews',
        'details-ask-question', 'details-specs', 'details-write-new-review',
      ];
      if (pdpPagesChild.includes(route.name as string)) {
        return router.replace(useCpNavigateToParams('details', usePdp().getProduct?.link));
      }

      if (route.name === 'details') {
        return router.replace(useCpNavigateToParams('catalog', usePdp().getProduct?.category.link));
      }

      if (route.name === 'af-details') {
        return router.replace(useCpNavigateToParams('catalog-af', usePdp().getProduct?.category.link));
      }

      if (route.name === 'catalog') {
        return router.replace(useCpNavigateToParams('categories'));
      }

      if (route.name === 'catalog-af') {
        return router.replace(useCpNavigateToParams('home'));
      }

      const accountPagesChild: string[] = [
        'account-settings', 'account-my-warranties', 'account-orders',
        'account-shipping-billing', 'account-favorites', 'account-my-reviews',
        'account-autonomous-refunds', 'account-my-questions', 'account-my-downloads',
        'account-non-reviewed-products', 'account-settings-subscriptions',
      ];
      if (accountPagesChild.includes(route.name as string)) {
        return router.replace(useCpNavigateToParams('account'));
      }

      const menuPagesChild: string[] = [
        'information-center', 'sac', 'connect-with-us',
        'view-history',
      ];
      if (menuPagesChild.includes(route.name as string)) {
        return router.replace(useCpNavigateToParams('menu'));
      }

      if (route.name === 'basket') {
        return router.replace(useCpNavigateToParams('home'));
      }
    }

    // TODO: This routes changes to plugin/router.ts
    const basketPagesChild = [
      'checkout-confirmation', 'checkout-billing', 'checkout-payment',
      'checkout-address',
    ];
    if (basketPagesChild.includes(route.name as string)) {
      return router.replace(useCpNavigateToParams('basket'));
    }

    if (route.name === 'order-confirmation') {
      return router.replace(useCpNavigateToParams('home'));
    }

    if (route.name === 'account-my-warranties-manage-info') {
      return navigateTo({name: 'account-my-warranties'});
    }
    if (route.name === 'account-my-warranties-serial-number') {
      return navigateTo({name: 'account-my-warranties-manage-info', query: {
        brand: useWarrantiesStore().getBrand,
        orderId: useWarrantiesStore().getOrderNumber,
      }});
    }
    if (route.name === 'rm-success-screen' || route.name === 'rm-update-report') {
      return navigateTo({name: 'account-orders'});
    }

    router.back();
  } catch { }
};
