import {defineStore} from 'pinia';
import {STORE_KEYS} from '~/stores/storeKeys';
import type {IPaypalInstallmentsMSI} from '~/types';

interface PayPalPPCPData {
    /** ID generated after successfully providing credit card info */
    id: string | null;
    isVaultCard: boolean;
    /** Month used for payment installments */
    month: string | null;
    type: 'hf' | 'rc' | null;
}
export type paypalInstallment = { month: number | string, payments: number, total: number } | null
export type installmentsRestrictions = { show: boolean, minimiumReached: boolean, minOrderTotal: number }
interface CheckoutStore {
    /** Month used for payment installments */
    month: number | null;
    /** PayPal PPCP Data */
    ppcp: PayPalPPCPData;
    /** To avoid calling twice setPaypalTransactionContext */
    ppcpTransactionContextSetted: boolean;
    globalSelectedInstallments: paypalInstallment;
    globalInstallmentsRestrictions: installmentsRestrictions;
    /* TEMPORAL prop to see if basket has items */
    basketCount: number;
    paypalInstallmentsMSI: null | IPaypalInstallmentsMSI,
    customEmail: string | null,
    externalMetaDataId: string | null,
}

/**
 * Checkout Store
 *
 * Some data that we need to provide for Billing Agreement and PPCP payments, such as month installments, is not persistent
 * in the user's basket. Therefore, we need a local store for handling some of the data needed for each case:
 *
 * Billing Agreement: Payment installments months
 * PPCP: Payment installment months, PPCP ID and Type (Generated after the user successfully submits a valid Credit/Debit card info
 *
 * */
export const useCheckoutStore = defineStore(STORE_KEYS.Checkout, {
    state: (): CheckoutStore => {
        return {
            month: 1,
            ppcpTransactionContextSetted: false,
            ppcp: {
                month: '1',
                id: null,
                type: null,
                // We will assume that the most common case scenario for ppcp is with vault cards
                isVaultCard: true,
            },
            globalSelectedInstallments: null,
            globalInstallmentsRestrictions: {show: false, minimiumReached: false, minOrderTotal: 0},
            basketCount: 0,
            paypalInstallmentsMSI: null,
            externalMetaDataId: null,
            customEmail: null,
        };
    },
    actions: {
        reset() {
            this.ppcpTransactionContextSetted = false;
            this.month = 1;
            this.ppcp = {month: '1', id: null, type: null, isVaultCard: true};
            this.globalSelectedInstallments = null;
            this.globalInstallmentsRestrictions = {show: false, minimiumReached: false, minOrderTotal: 0};
        },
        setBasketCount(count: number) {
 this.basketCount = count;
},
    },
    getters: {
        getBasketCount: (state) => state.basketCount,
    },
});
