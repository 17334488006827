import {useAFCatalog} from '~/composables/queries/useCatalog';
import {useCpNavigateToParams} from '~/composables/useCpNavigateToParams';
import {useRecentQueriesStore} from '~/stores';

export const useSearchTerm = async (querySelected: string) => {
    const recentQueriesStore = useRecentQueriesStore();
    const {data: catalogData} = await useAFCatalog(querySelected, 'search', 0);
    if (catalogData.value && catalogData.value.redirectUrl) {
        await recentQueriesStore.addNewQuery(querySelected);
        // TODO: Fix, check with shops/microservices this
        const page = (catalogData.value.autocomplete || !catalogData.value.redirectUrl.endsWith('.html')) ?
            'catalog' : 'details';
        return navigateTo(useCpNavigateToParams(page, catalogData.value.redirectUrl));
    }
    await recentQueriesStore.addNewQuery(querySelected);
    return navigateTo(useCpNavigateToParams('catalog', catalogData.value?.filters.baseLink));
};
