import {defineStore} from 'pinia';
import last from 'lodash/last';
import {STORE_KEYS} from './storeKeys';
import type {ICatMan} from '~/types';

interface CategoriesMenuStore {
  activeCategoryTree: ICatMan[],
  categories: ICatMan[],
  flatCategories?: Record<string, ICatMan>
}
export const useCategoriesMenuStore = defineStore(STORE_KEYS.CategoriesMenu, {
  state: (): CategoriesMenuStore => ({
    activeCategoryTree: [],
    categories: [],
    flatCategories: undefined,
  }),
  actions: {
    setActiveCategoryTree(tree: ICatMan[]) {
      this.activeCategoryTree = tree;
    },
    setFlatCategories(flatCat: Record<string, ICatMan>) {
      this.flatCategories = flatCat;
    },
    setCategories(categories: ICatMan[]) {
      this.categories = categories;
    },
  },
  getters: {
    activeRoot(): ICatMan | undefined {
      return this.activeCategoryTree[0];
    },
    parentCategories(): ICatMan[] {
      return this.activeCategoryTree.slice(1);
    },
    activeCategory(): ICatMan | undefined {
      return last(this.activeCategoryTree);
    },
    getFlatCategories(state): Record<string, ICatMan> | undefined {
      return state.flatCategories;
    },
  },
});
