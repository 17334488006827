import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';
import type {IReportSubtype, IReportType, IFileUpload, ISingleOrderReturnCase} from '~/types';

export type actionsKind = 'updateReport' | 'changePreferredAction' | 'cancelReport' | 'productNotReceived' | 'productReturnRequest' | 'warrantyRequest'

export interface IReturnsModuleStore {
  selectedAction?: actionsKind
  selectedReason?: IReportSubtype | IReportType
  preferredAction?: 'replacement' | 'refund'
  packageStatus?: 'opened' | 'closed'
  deliveryStatus?: 'partiallyReceived' | 'notReceived'
  uploadedImages?: IFileUpload[]
  quantityToReport: number
  description: string
  selectedReturnCase: ISingleOrderReturnCase | undefined
  serialNumbers: string
  openedArticlesNumber: number
  articleDaysSinceDelivered: number
  articleDeliveryDate: string
}

export const useReturnsModuleStore = defineStore(STORE_KEYS.ReturnsModule, {
    state: (): IReturnsModuleStore => ({
      selectedAction: undefined,
      selectedReason: undefined,
      preferredAction: undefined,
      packageStatus: undefined,
      deliveryStatus: undefined,
      quantityToReport: 0,
      description: '',
      selectedReturnCase: undefined,
      serialNumbers: '',
      openedArticlesNumber: 0,
      articleDaysSinceDelivered: 0,
      articleDeliveryDate: '',
    }),
    actions: {
      reset() {
        this.selectedAction = undefined;
        this.selectedReason = undefined;
        this.preferredAction = undefined;
        this.packageStatus = undefined;
        this.deliveryStatus = undefined;
        this.quantityToReport = 0;
        this.description = '';
        this.uploadedImages = undefined;
        this.selectedReturnCase = undefined;
        this.serialNumbers = '';
        this.openedArticlesNumber = 0;
        this.articleDaysSinceDelivered = 0;
      },
    },
    getters: {},
});
