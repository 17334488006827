import 'floating-vue/dist/style.css';

export default defineNuxtPlugin({
  name: 'cp-plugin',
  parallel: true,
  env: {
    islands: false,
  },
  setup() {
    onNuxtReady(() => {
      const {createSimpleSession} = useAuthActions();
      createSimpleSession();
    });
  },
});
