import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';
import {useCpUser} from '~/composables/useCpUser';

interface IUXNavigationStore {
    showDownloadModal: boolean,
    showDownloadAppSection: boolean,
}

export const useUXNavigationStore = defineStore(STORE_KEYS.UXNavigationStore, {
  state: (): IUXNavigationStore => ({
    showDownloadModal: true,
    showDownloadAppSection: true,
  }),
  getters: {
    canShowDownloadSection: (state) => state.showDownloadAppSection &&
      !useCpUser().user.value?.saldo?.appliedSaldo?.includes('first_login_app'),
  },
});
