<script setup lang="ts">
  import {computed} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import type {ICpTypeWithModifier} from '~/composables/useCpType';

  const props = withDefaults(defineProps<{
    text: string,
    variant?: 'body'|'caption'
    regularType?: ICpTypeWithModifier,
    boldType?: ICpTypeWithModifier,
    format?: boolean
    block?: boolean
    isSpan?: boolean
  }>(), {
    regularType: 'neutral-dark',
    boldType: 'neutral-dark',
    variant: 'body',
    block: false,
    isSpan: false,
  });
  /** Gets an array of strings between <strong> ... </strong> */
  const boldText = computed(() => props.text?.match(/(\*.*?\*)|(<strong>.*?<\/strong>)/g) ?? []);

  const parseBoldText = computed(() => {
    const final: string[] = [];
    let tempText = props.text;
    if (boldText.value.length === 0) {
      final.push(props.text);
      return final;
    }
    boldText.value.forEach((bold, index) => {
      const nextIndex = tempText.indexOf(bold);
      final.push(tempText.substring(0, nextIndex));
      final.push(bold);
      tempText = tempText.substring(nextIndex + bold.length, tempText.length);
      if (index === boldText.value.length - 1) {
        final.push(tempText);
      }
    });
    return final;
  });

  const {b, em} = useCpBem('cp-text-parser');
</script>

<template>
    <component :is="isSpan ? 'span' : 'div'" :class="b">
        <CpText
            v-for="(textItem, number) in parseBoldText"
            :key="number"
            :variant="textItem[0]==='*' ? `${variant}-bold` : `${variant}-regular`"
            :class="em('text', { 'wrap': format, block })"
            :type="textItem[0]==='*' ? boldType : regularType"
        >
            {{ textItem.replaceAll('*','') }}
        </CpText>
    </component>
</template>

<style scoped lang="scss">
.cp-text-parser {
  width: auto;
  &__text {
    &--wrap {
      white-space: pre-wrap;
    }
  }
}
</style>
