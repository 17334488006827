export const useClientCache = <T>(minutesExpirationTime = 5) => {
  const app = useNuxtApp();
  return {
    transform: (data: T) => ({
      ...data,
      fetchedAt: new Date(),
    }),
    getCachedData: (key: string) => {
      const cachedData = app.payload.data[key] || app.static.data[key];
      if (!cachedData || !cachedData.fetchedAt) {
        return;
      }

      const expirationTimeInMs = minutesExpirationTime * 60 * 1000;
      const expirationDate = new Date(cachedData.fetchedAt);
      expirationDate.setTime(expirationDate.getTime() + expirationTimeInMs);

      if (expirationDate.getTime() < Date.now()) {
        return;
      }

      return cachedData as T;
    },
  };
};
