import {ofetch} from 'ofetch';
import type {CpUser} from '~/typings/next-auth';
// These imports should be live here? or inside the /server folder?
import type {
  AuthCredentials,
  LoginSuccessResponse,
  SimpleSession,
  FidLoginResponsePayload,
  FidLoginData,
  FidLoginResponseCandidate,
  FidLoginResponseNewUser,
} from '~/utils/services/api/account/auth/auth';
import {authPaths} from '~/utils/services/paths';
import crypto from 'crypto';

/** Passphrase to sign cptoken @see IT-28178 for more info */
const PASSPHRASE = 'CyberpuertaIsCool';

export const AuthActions = {
  // Just refetch the user, with the same session token. Useful for FidLink or after mutate user properties.
  REFRESH: 'refresh-user',
  // Login again with the the refreshToken. Useful for when the session token is expired.
  ROTATE: 'rotate-token',
  // Create a simple session, just for basic things like basket/view history...
  CREATE_SIMPLE_SESSION: 'create-simple-session',
};

const $cpNitroFetch = ofetch.create({
  baseURL: 'https://www.cyberpuerta.mx/api/v0.1',
  parseResponse: JSON.parse,
  query: {webMobile: true},
});

/**
 * Generate a Cyberpuerta Token.
 * Secure token with a hashing mechanism to protect login endpoints in order
 * to avoid DDoS attacks.
 *
 * @see IT-28178
 */
const generateCPT = () => {
  // 1. Get the current UTC datetime in the format Y-m-d H:i
  const now = new Date();
  // The `toISOString()` method returns the date in ISO format (YYYY-MM-DDTHH:mm:ss.sssZ).
  // We then use `slice(0, 16)` to extract the date and time up to the minutes,
  // and `replace('T', ' ')` to replace the 'T' with a space to match the desired format (Y-m-d H:i).
  const currentDate = now.toISOString().slice(0, 16).replace('T', ' ');
  // 2. Encode date using base64
  const encodedDate = Buffer.from(currentDate).toString('base64');
  // 3. Concatenate the encoded date with a "passphrase"
  const concatenatedDatePassphrase = `${encodedDate}${PASSPHRASE}`;
  // 4. Generate an MD5 hash of the concatenated
  const hash = crypto.createHash('md5').update(concatenatedDatePassphrase).digest('hex');
  return hash;
};

export const login = (credentials: AuthCredentials, accessToken: string, userAgent: string) => {
  const {email, ...passwordOrToken} = credentials;
  const cpt = generateCPT();
  return $cpNitroFetch<LoginSuccessResponse>(authPaths.login(email), {
    body: passwordOrToken,
    query: {cpt},
    method: 'POST',
    headers: {
      'User-Agent': userAgent,
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getUser = (email: string, accessToken: string, userAgent: string) => {
  return $cpNitroFetch<CpUser>(authPaths.user(email), {
    headers: {
      'User-Agent': userAgent,
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getSimpleSession = (userAgent: string) => {
  return $cpNitroFetch<SimpleSession>(authPaths.simpleSession, {
    method: 'POST',
    headers: {
      'User-Agent': userAgent,
    },
  });
};

export const fidLogin = (params: FidLoginData, accessToken: string, userAgent: string) => {
  return $cpNitroFetch<FidLoginResponsePayload>(authPaths.fidlogin(params.provider as string), {
    method: 'POST',
    body: params,
    headers: {
      'User-Agent': userAgent,
      // TODO: check this value.
      Referrer: 'web-mobile',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getCurrentSession = (headers: any) => {
  return $fetch('/api-nuxt/auth/token', {
    headers,
  });
};

/**
 * Fid Util - Tells if the provider account you are logging in with can be linked to an existing CP Account
 * */
export const isFidCandidateToLink = (data: FidLoginResponsePayload): data is FidLoginResponseCandidate => {
  return 'isCandidate' in data && data.isCandidate;
};

/**
 * Fid Util - Tells if the provider account did not exist before, meaning it has just been created
 * */
export const isFidNewUser = (data: FidLoginResponsePayload): data is FidLoginResponseNewUser => {
  return 'newRegister' in data && data.newRegister;
};
