<script setup lang="ts">
const {t} = useI18n();
const {b, e} = useCpBem('cp-unavailable-page');
</script>

<template>
    <div :class="b">
        <CpEmptyState
            icon="sentiment_dissatisfied"
            :title="t('title')"
            :button-label="t('button')"
            data-testid="notFoundRoute-alert"
            @empty-state-action="navigateTo({ name: 'home' })"
        >
            <template #body>
                <div :class="e('empty-state')">
                    <CpText variant="subheading" type="neutral-dark">
                        {{ t('body.title') }}
                    </CpText>
                    <CpText :class="e('empty-state-item')" variant="body-regular" type="neutral-dark">
                        {{ t('body.line1') }}
                    </cptext>
                    <CpText :class="e('empty-state-item')" variant="body-regular" type="neutral-dark">
                        {{ t('body.line2') }}
                    </cptext>
                    <CpText :class="e('empty-state-item')" variant="body-regular" type="neutral-dark">
                        {{ t('body.line3') }}
                    </cptext>
                </div>
            </template>
        </CpEmptyState>
    </div>
</template>

<style scoped lang="scss">
  .cp-unavailable-page {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__empty-state {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &__empty-state-item {
      margin-left: 16px;
      text-indent: -8px;
    }
  }
</style>

<i18n locale="es-US" lang="yaml">
  title: 'Error 404'
  body:
    title: "Página no encontrada"
    line1: "· Lo que buscas no está disponible o no existe."
    line2: "· Te invitamos a seguir en nuestro sitio."
    line3: "· O presiona el siguiente botón."
  button: 'Regresar al sitio'
</i18n>
