import {DateTime} from 'luxon';
import {type ICpAddress, type ICpArticle, type IWarranty} from '~/types';
import {OrderStatuses} from '~/composables/useOrderStatusCase';
import {useUXNavigationStore, useRouterStore} from '~/stores';

export const DOMAIN = 'https://www.cyberpuerta.mx';

const formatter = new Intl.NumberFormat('en', {
    style: 'currency',
    currency: 'USD',
});
export const formatAsCurrency = (n: number, showCents = true) => {
    return showCents ? formatter.format(n) : formatter.format(n).replace('.00', '');
};
export const parsePriceInt = (price: number|string) => {
    return typeof price === 'string' ? parseInt(price) : price;
};
export const formatAddressInline = (address: ICpAddress) => {
    const {
        postalCode,
        interiorNumber,
        streetName,
        streetNumber,
        addressLevel1,
        addressLevel2,
        addressLevel3,
    } = address;
    return `${streetName} ${streetNumber}${
        interiorNumber ? ` Int. ${interiorNumber}` : ''
    } ${addressLevel3}, ${postalCode}, ${addressLevel2}, ${addressLevel1}`;
};

/** Returns a boolean value indicating if a given text is a valid url that can be parsed as such */
export const isValidUrl = (text: string) => {
    try {
        // eslint-disable-next-line no-new
        new URL(text);
        return true;
    } catch (e) {
        return false;
    }
};

export const setDateFormat = (date: string | Date, format?: string) => {
    if (!date) {
        return '';
    }
    // Workaround for JS engines inconsistencies. See: https://github.com/expo/expo/issues/782
    const newDate = typeof date === 'string' ? date.includes(' ') ? new Date(date.replace(' ', 'T')) : new Date(date + 'T00:00:00') : date;
    if (!format) {
        return DateTime.fromJSDate(newDate).toFormat('dd/MM/yyyy');
    }
    return DateTime.fromJSDate(newDate).setLocale('es-MX').toFormat(format);
};

/**
 * Creates an array with all falsey values removed. The values false, null, 0, "", undefined, and NaN are falsey.
 * Taken from https://lodash.com/docs/4.17.15#compact
 * @param array The array to compact.
 * @returns Returns the new array of filtered values.
 */
export const compact = (array: any[]) => {
    return array.filter((a) => ![false, null, 0, '', undefined].includes(a));
};

// TODO: Move this to fixtures
export const CHECK_CP_WEBSITE = 'https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx';
export const DOWNLOAD_RFC = 'https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal';
export const defaultRFCData = {
    rfc: 'XAXX010101000',
    cpTaxAddress: '44600',
    taxRegimeData: '616',
    invoiceUsageCode: 'S01',
};
export const checkCommercialEnterpriseName = (text: string) => {
    // const acronym = ['S.A.', 'S. de R.L.', 'S.A.S.', 'S.C.', 'S.A.P.I.'];
    const regex =
        /( S.?A.? DE C.?V.?)|( S.?A.?P.?I)|( DE R.?L$)|( S.? en C.?)|( S.?C.?$)|( S.?O.?F.?O.?M.?)|( S.?A.?S.?$)|( S.?A.?B.?$)|( C.?V.?$)|( S.?A.?$)|( A.?C.?$)|(S.? DE .? R.?L.? M.?I.?)/i;
    const reg = new RegExp(regex);
    return reg.test(text);
    // return acronym.some(val => text.includes(val));
};
export const getRFCType = (rfc: string | undefined) => {
    if (!rfc) {
        return 'invalid';
    }
    const regexMoral = /^([A-Z\xD1\x26]{3}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{2}[A\d])$/u;
    if (regexMoral.test(rfc)) {
        return 'legal';
    }
    const regexFisica = /^([A-Z\xD1\x26]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{2}[A\d])$/u;
    if (regexFisica.test(rfc)) {
        return 'natural';
    }
    return 'invalid';
};
export const sleep = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};

export const getShortName = (fullName: string | undefined) => {
    return fullName ? fullName.split(' ')[0] : '';
};

/** Takes a number as rate (e.g. 0.923) and returns a string with % */
export const formatAsPercentage = (n: number) => {
    return (n * 100).toFixed(1).toString() + '%';
};

export const GENERIC_ORGANIZATION_NAME = /^(?!.*P(U|Ú)BLICO EN GENERAL).*$/i;
export const RFC_REGEX = /^([A-Z\xD1\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z\d]{2}[A\d])$/u;
export const ZIP_REGEX = /^(\d){5}$/;
export const LAST_DIGITS_REGEX = /^(\d){4}$/;
export const IDCIF_REGEX = /^(\d){11}$/;
export const GENERIC_RFC = /^X([AE])XX010101000$/i;
export const EMAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const NUMBER_REGEX = /^[0-9]+$/;
export const PHONE_REGEX = /^\d{10}$/;
export const CVV_REGEX = /^\d{3,4}$/;
export const FULLNAME_REGEX = /^\w+\s+\w+.*$/;
export const CARDNUMBER_REGEX = /^(?:\d{13,19}|[35]\d{14}|6(?:011|5\d{2})\d{12})$/;
export const EXPIRATIONDATE_REGEX = /^(0[1-9]|1[0-2])\s*\/\s*\d{2}$/;

export const EXTERNAL_LINKS = {
    appStore: 'itms-apps://itunes.apple.com/mx/app/apple-store/id1636030641',
    emailActivation: 'https://www.cyberpuerta.mx/activacion',
    identityVerification: 'https://www.cyberpuerta.mx/mi-cuenta',
    discord: 'https://discord.com/invite/wsbm5pZb7e',
    whatsapp: 'https://wa.me/5213347371360',
    facebook: 'https://www.facebook.com/Cyberpuerta',
    x: 'https://x.com/Cyberpuerta',
    appGallery: 'appmarket://details?id=com.cyberpuerta',
    playStore: 'market://details?id=com.cyberpuerta',
    jobBank: 'https://cyberpuerta.breezy.hr/',
    mailtoCustomerSupport: 'mailto:info@cyberpuerta.mx',
    mailtoAppSupport: 'mailto:web-mobile-support@cyberpuerta.mx',
    mailtoFeedback: 'mailto:feedback@cyberpuerta.mx',
    telCustomerSupport: 'tel:3347371360',
    paypalSuccessRedirect: 'cyberpuerta://paypal-billing-agreement-success',
    paypalCancelRedirect: 'cyberpuerta://paypal-billing-agreement-cancel',
    paypalPpcp: 'cyberpuerta://paypal-ppcp',
    paypalAccount: 'https://www.paypal.com/myaccount/autopay/',
    oauth: 'com.cyberpuerta:/oauthredirect',
    oauthIOS: 'com.cyberpuerta.ios:/oauthredirect',
    ekomi: 'https://www.ekomi.es/testimonios-cyberpuerta.html',
    intermittenceIssues: 'https://www.cyberpuerta.mx/estado-de-la-tienda/',
    fiscalData: 'https://www.sat.gob.mx/aplicacion/login/53027/genera-tu-constancia-de-situacion-fiscal',
    playStoreAppURL: 'https://play.google.com/store/apps/details?id=com.cyberpuerta',
    appStoreAppURL: 'https://apps.apple.com/mx/app/cyberpuerta/id1636030641',
    tiktok: 'https://www.tiktok.com/@cyberpuerta.mx',
};

export const isAppleDeviceAndRunningSarafi = (userAgent = navigator?.userAgent) => {
  return (
    (userAgent?.includes('Mac') || userAgent?.includes('iPhone')) &&
    userAgent?.includes('Safari') &&
    !(userAgent?.includes('CriOS') || userAgent?.includes('FxiOS'))
  );
};

export const downloadApp = () => {
    const params = !!useRouterStore().tradeQueryParams ?
    new URLSearchParams({...useRouterStore().tradeQueryParams}) :
    null;
    if (navigator?.userAgent?.includes('Mac') || navigator?.userAgent?.includes('iPhone')) {
        // TT logic is not tested in iOS, feel free to change this code.
        const uri = `${EXTERNAL_LINKS.appStoreAppURL}${params ? '?' + params.toString() : ''}`;
        window?.open(encodeURI(uri));
    } else {
        const uri = `${EXTERNAL_LINKS.playStoreAppURL}${params ? '&referrer=' + params?.toString().replaceAll('&', '%26') : ''}`;
        window?.open(uri);
    }
    useUXNavigationStore().showDownloadModal = false;
};

/*
    Apple store rate is 4.8 but 5 stars are displayed so I'll keep this rate for now
*/

export const getReviewsData = ():{reviews: string, rate: number} =>
    navigator?.userAgent?.includes('Mac') || navigator?.userAgent?.includes('iPhone') ?
        {reviews: '2.1k', rate: 5} : {reviews: '8.28k', rate: 4.5};

/**
 * Receives a currency string and returns it as number
 * Practically is the inverse of formatAsCurrency().
 */
export const currencyToNumber = (p: string): number => {
    return +p.replace(/[^\d.]/g, '');
};

export const rejectQueryFn = (message?: string) =>
    Promise.reject(new Error(message ?? '',
    ));

export type BannerController =
  | 'alist'
  | 'details'
  | 'manufacturerlist'
  | 'register'
  | 'search'
  | 'start';

export const CONTROLLER_PLACES: Record<BannerController, string[]> = {
    start: [
        'home-main-app',
        'home-middle-1',
        'home-bottom',
    ],
    manufacturerlist: ['list-main'],
    alist: [
        'list-main',
        'left-top',
        'page-top',
    ],
    details: [],
    search: ['list-main'],
    register: [],
};

export const imgPlaceHolderArray = [
    'https://www.cyberpuerta.mx/out/pictures/myprofile/no-pic-mr.jpg',
    'https://www.cyberpuerta.mx/out/pictures/myprofile/no-pic-mrs.jpg',
    'https://www.cyberpuerta.mx/out/pictures/myprofile/no-pic.jpg',
    '',
];

export const getKey = (data: Array<any>, value: any) => {
    const key = data.find((e) => e.name === value);
    return key ? key.id : '';
};

export function openFiscalDataLink() {
    window.open(EXTERNAL_LINKS.fiscalData, '_blank');
}

export const getCardKind = (item: ICpArticle | IWarranty) => {
    if (item.offer && item.offer.percent && item.isDailyOffer) {
      return 'FeaturedOffer';
    } else if (item.offer && item.offer.percent) {
      return 'Offer';
    } else if (item.isDailyOffer) {
      return 'Featured';
      // @ts-expect-error eol is supported?
    } else if (item.eol || item.stockStatus === -1) {
      return 'Oos';
      // @ts-expect-error eol is supported?
    } else if (item.offer && item.offer.percent && item.eol) {
      return 'OosOffer';
    } else {
      return 'Standard';
    }
};

export const isHighDemandSeason = () => new Date() <= new Date('2024-01-14 23:59:59');

export const codesIgnored = (removeFinalStatus = false): number[] => {
    const status = [
        OrderStatuses.CANCELLATION_PROCESS,
        OrderStatuses.DELIVERED_AND_PAID,
        OrderStatuses.FULLY_DELIVERED,
        OrderStatuses.DELIVERED_PAYMENT_PENDING_CARD_EXPIRED,
        OrderStatuses.DELIVERED_PAYMENT_PENDING_CARD_NOT_EXPIRED,
        OrderStatuses.NULL,
        OrderStatuses.PENDING_TO_BE_PAID,
        OrderStatuses.CANCELLED,
    ] as number[];
    return removeFinalStatus ? status.filter((e) => e !== OrderStatuses.FULLY_DELIVERED) : status;
};

export const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

// Note: this function accepts 'yyyy-mm-dd' format
export const getFormattedDateByKind = (
    data: string,
    formatKind: 'ETA' | 'dd/mm/yyy' = 'ETA',
    fullDate = false,
) => {
    if (!data) return '';
    if (data.includes('/')) {
        return data;
    }
    const date = new Date(data + (data.includes('T') ? '' : 'T00:00:00'));
    if (formatKind === 'ETA') {
 return `${('0' + date.getDate()).slice(-2)} de ${months[date.getMonth()]}${fullDate ? ` de ${date.getFullYear()}` : ''}`;
}
    if (formatKind === 'dd/mm/yyy') {
 return `${('0' + date.getDate()).slice(-2)}/${date.getMonth() + 1}/${date.getFullYear()}`;
}
};

export const greaterDate = (dates: string[]): Date => {
    let greater = new Date(dates[0] + (dates[0].includes('T') ? '' : 'T00:00:00'));
    if (dates.length > 1) {
        for (let x = 1; x < dates.length; x++) {
            const dateToCompare = new Date(dates[x] + (dates[x].includes('T') ? '' : 'T00:00:00'));
            greater = dateToCompare.getTime() > greater.getTime() ? dateToCompare : greater;
        }
    }

    return greater;
};

export const WARRANTY_TYPES = {
    SMARTNET_WARRANTY: '5c7817831eff2',
    DELL_WARRANTY: '5c771f2c54097',
    CAREPACK_WARRANTY: '5ea07d1a3c4929.73032758',
    APPLE_WARRANTY: '60bf6e8bb8bd22.50216782',
    AIG_WARRANTY: '62c8d733a974d2.28868521',
    FORTINET_WARRANTY: '64346461a2a024.05348447',
};

export const copyToClipboard = (text: string) => navigator?.clipboard?.writeText(text);

export const ORDER_THANK_YOU_CYBERMERCH_DATA = {
    CATALOG_ID: '65ca692751a3a',
    CATALOG_TYPE: 'cat',
    CATALOG_FILTERS: {},
    CATALOG_PAGE: 0,
    CATALOG_SORT: 'cprelevance-desc',
    ETA_TYPE: 'physical',
    CATALOG_LINK: '/Hogar/Cybermerch/T-Shirts/',
};


export const imageResizer = async (file: File, size = 1024) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const bitmap = await createImageBitmap(file);
    const {width: fileWidth, height: fileHeight} = bitmap;

    // Initialize
    let width = fileWidth;
    let height = fileHeight;

    // get new dimensions
    if (width > height) {
        if (width > size) {
            height *= size / width;
            width = size;
        }
    } else {
        if (height > size) {
            width *= size / height;
            height = size;
        }
    }

    canvas.width = width;
    canvas.height = height;

    ctx?.drawImage(bitmap, 0, 0, width, height);

    const blob = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg', 1);
    });

    return new File(
        [blob as BlobPart],
        `${file.name.split('.')[0]}.jpeg`,
        {
            type: 'image/jpeg',
            lastModified: new Date()[Symbol.toPrimitive]('number'),
        },
    );
};

export const tradeTrackerValidParams = [
    'aid',
    'tt',
    'dst',
    'dim',
    'mat',
    'mnm',
    'mty',
    'rnd',
    'ref',
    'rur',
    'tst',
    'tdt',
];

export const toBase64 = (file: any): Promise<string> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
    reader.onerror = reject;
});

export const PRIVACY_POLICY_URL = 'https://www.cyberpuerta.mx/Condiciones-generales/#Aviso-privacidad';
export const QNA_GUIDE_URL = 'https://www.cyberpuerta.mx/Como-hacer-preguntas-y-respuestas/';
export const REVIEWS_INFO_URL = 'https://www.cyberpuerta.mx/Informacion-sobre-opiniones/';
