export type ICpType = 'primary'|'secondary'|'warning'|'critical'|'success'|'mkt'|'neutral'|'neutral-dark'|'white'
// export type ICpTypeModifier = 'light'|'extraLight'|'gradient'
export type ICpTypeWithModifier =
    'primary'|'primary-light'|'primary-extra-light'|'primary-dark'|
    'secondary'|'secondary-light'|'secondary-extra-light'|'secondary-dark'|
    'warning'|'warning-light'|'warning-extra-light'|'warning-dark'|
    'critical'|'critical-light'|'critical-extra-light'|'critical-dark'|
    'success'|'success-light'|'success-extra-light'|'success-dark'|
    'mkt'|'mkt-light'|'mkt-extra-light'|'mkt-dark'|
    'neutral'|'neutral-light'|'neutral-extra-light'|'neutral-dark'|'white'|
    'tertiary'|'tertiary-dark'|'tertiary-light'|'tertiary-extra-light'
export type ICpTextVariants = 'heading-h1'|'heading-h1-fake'|'heading-h2'|'heading-h3'|'heading-h4'|'heading-h3-fake'|
    'subheading'|'body-bold'|'body-link'|'body-regular'|'caption-bold'|'caption-regular'|'caption-cart-counter'|
    'button-regular'|'button-bold'|'price-from'|'price-regular'|'price-offer'|'price-total'|'main-menu'|'caption-link'|
    'heading-h1-pdp'|'heading-h3-pdp'|'display-1'|'headline'|'highlight'|'highlight-bold'
export type ICpBadgeType = 'american'|'bbva'|'debit'|'masterCard'|'monex'|'oxxo'|'paypal'|'spei'|'visa'|'estafeta'|'dhl'|
    'dinersClub'|'discover'|'masterCardAlt'|'unionPay'|'jcb'|'cvv'|'cardNumber'|'santander'|
    'bancoAzteca'|'Banorte'|'citibanamex'|'hsbc'|'inbursa'|'bancoppel'|'warranty'|'paypalStandard'| 'paqueteExpress'
export type ICpPillType = 'featuredProduct'|'offer'|'gift'|'esd'|'eol'|'oosEol'|'extendedTime'|'bundle'|'offer-label'
|'featured-short'|'featured-large'|'featured-offer'|'offer-discount'|'Oos'|'new-short'|'new-large'

export type MonthsNumbers = '01' | '02' | '03' | '04' | '05' | '06' | '07' | '09' | '10' | '11' | '12'
type MonthsNamesType = 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december'

export type StatusCpspType = 'cpsp[envio]'|'cpsp[instock]'|'cpsp[isnew]'|'cpsp[isoffer]'|'cpsp[msku]'

interface colorsObject {
    background: ICpTypeWithModifier,
    color: ICpTypeWithModifier,
    active: ICpTypeWithModifier,
    activeColor?: ICpTypeWithModifier,
    border?: boolean,
}

interface optionsObject {
    disabled?: boolean
    hollow?: boolean
}

export function useColors(variant: ICpType, opts: optionsObject = {}): colorsObject {
  if (opts.disabled) {
    return opts.hollow ?
      {
        background: 'white',
        color: 'neutral-light',
        active: 'white',
        border: true,
      } :
      {
        background: 'neutral-extra-light',
        color: 'neutral-light',
        active: 'neutral-extra-light',
      };
  }

  if (opts.hollow) {
    return {
      background: 'white',
      color: variant,
      activeColor: `${variant}-dark` as ICpTypeWithModifier,
      active: `${variant}-extra-light` as ICpTypeWithModifier,
      border: true,
    };
  }

  switch (variant) {
  case 'primary':
    return {
      background: 'primary',
      color: 'white',
      active: 'primary-dark',
    };
  case 'secondary':
    return {
      background: 'secondary',
      color: 'white',
      active: 'secondary-dark',
    };
  case 'warning':
    return {
      background: 'warning',
      color: 'white',
      active: 'warning-dark',
    };
  case 'mkt':
    return {
      background: 'mkt',
      color: 'white',
      active: 'mkt-dark',
    };
  case 'critical':
    return {
      background: 'critical',
      color: 'white',
      active: 'critical-dark',
    };
  case 'success':
    return {
      background: 'success',
      color: 'white',
      active: 'success-dark',
    };

  default:
    return {
      background: 'primary',
      color: 'white',
      active: 'primary-dark',
    };
  }
}

export const toCssVariable = (color: ICpTypeWithModifier): string => `var(--color-${color})`;

// Card types
export type ICardReview = {
  id: string,
  rate: number,
  image: string,
  pdp: boolean,
  title: string,
  body: string,
  userName: string,
  date: string,
  verified: boolean,
  allReviews: boolean,
  dislikesCount: number,
  likesCount: number,
}
