import {StorageSerializers, useSessionStorage} from '@vueuse/core';
import {QUERY_KEYS} from '~/utils/services/keys';
import type {IBannerPlaceParams} from '~/utils/services/api';
import {getBannerPlaces, getBanners} from '~/utils/services/api';
import type {IBannerPlace} from '~/types';

// export const useBannerPlace = (bannerPlaceParams: IBannerPlaceParams) => {
//   const app = useNuxtApp();
//   const route = useRoute();
//   const url = createBannersCacheKey(route.path);
//   const cacheKey = `${QUERY_KEYS.banners(bannerPlaceParams.placeId)}/${bannerPlaceParams.controllerId}${url}`;
//   return useCachedAsyncData(cacheKey, () => {
//     return getBannerPlaces(bannerPlaceParams);
//   }, {}, {cacheKey, cacheTags: [cacheKey], cacheExpires: 3600 * 12, event: app.ssrContext?.event});
// };

export const useBannerPlace = (bannerPlaceParams: IBannerPlaceParams) => {
  const app = useNuxtApp();
  const contextStr = JSON.stringify(bannerPlaceParams.context)
  .replaceAll('"', '')
  .replaceAll('"', ',')
  .replaceAll('}', ',')
  .replaceAll('\\', ',')
  .replaceAll('{', ',');
  const cacheKey = `${QUERY_KEYS.banners(bannerPlaceParams.placeId)}/${bannerPlaceParams.controllerId}/${contextStr}}`;
  return useCachedAsyncData(
    cacheKey,
    () => getBannerPlaces(bannerPlaceParams),
    {
      transform: (input) => {
        return {
          data: input,
          fetchedAt: new Date(),
        };
      },
    },
    {cacheKey, cacheTags: [cacheKey], cacheExpires: 3600 * 12, event: app.ssrContext?.event},
  );
};


export const useBanners = (bannerPlaceParams: IBannerPlaceParams, server?: boolean) => {
  const KEY = QUERY_KEYS.banners('all') + bannerPlaceParams.placeId;
  if (server) {
    return useCachedAsyncData(KEY, () => getBanners(bannerPlaceParams), {server: true}, {cacheKey: KEY, cacheTags: [KEY], cacheExpires: 3600 * 3});
  }
  return useAsyncData(KEY, () => getBanners(bannerPlaceParams), {server: false, lazy: true});
};

/** Just experimenting */
export const useBannerPlaceCached = async (
  bannerPlaceParams: IBannerPlaceParams,
) => {
  // Use sessionStorage to cache data
  const cached = useSessionStorage<IBannerPlace>(
    QUERY_KEYS.banners(bannerPlaceParams.placeId),
    null,
    {
      serializer: StorageSerializers.object,
    },
  );
  if (!cached.value) {
    const {data} = await useAsyncData(
      QUERY_KEYS.banners(bannerPlaceParams.placeId),
      () => getBannerPlaces(bannerPlaceParams),
    );
    cached.value = data.value;
  }
  return cached;
};

/**
 * This function help us to create a key for urls with query strings
 * @param url
 * @returns url without query params
 */
function createBannersCacheKey(url: string) {
  const parts = url.split('?')[0].split('/').filter(Boolean);
  return `/${parts.join('/')}.cache`;
}
