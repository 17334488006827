import {defineStore} from 'pinia';

interface HomeBannersStore {
    firstImageLoaded: boolean
}

/**
 * This store help us to know when the first image of a banner
 * is loaded to load the rest of the images
 */
export const useHomeBannersStore = defineStore('bannersStore', {
  state: (): HomeBannersStore => ({
    firstImageLoaded: false,
  }),
  actions: {
    setImageLoaded() {
        this.firstImageLoaded = true;
    },
  },
  getters: {
    getImageLoaded(): boolean {
        return this.firstImageLoaded;
    },
  },
});
