import type {RouterConfig} from '@nuxt/schema';
import type {RouteRecordRaw as _RouteRecordRaw} from 'vue-router';

type RouteRecordRaw = _RouteRecordRaw & {
  /** Due to SEO reasons, we must add a trailing slash at the end of any path */
  path: `/${string}/` | '/' | '/:link*';
};

export const homeRoute: RouteRecordRaw = {
  name: 'home',
  path: '/',
  component: () => import('~/pages/index.vue'),
  meta: {
    menuSelected: 'home',
    layout: 'home-layout',
    navHeaderOptions: {
      title: 'Hola,',
      headline: 'Ingresa aquí',
      showLongSearchBar: false,
      showSearch: true,
      showCart: true,
      blueVersion: true,
    },
    navHeaderOptionsAuth: {
      title: 'Hola,',
      showLongSearchBar: true,
      showCart: true,
      numOfItems: 0,
    },
    // Avoid refetching on tab change
    keepalive: true,
  },
};

export const cmsRoute: RouteRecordRaw = {
  name: 'content',
  path: '/:link*',
  component: () => import('~/pages/misc/cms.vue'),
  meta: {
    menuSelected: 'home',
    navHeaderOptions: {
      title: '',
      showLongSearchBar: false,
      showSearch: true,
      showCart: true,
      blueVersion: true,
      showGoBack: true,
      isFakeTitle: true,
    },
    routerConfig: {
      cls: ['content'],
    },
  },
};

export const detailsRoute: RouteRecordRaw = {
  name: 'details',
  path: '/:link*',
  component: () => import('~/pages/details/[id]/index.vue'),
  meta: {
    menuSelected: 'categories',
    title: 'Detalles del producto',
    layout: 'home-layout',
    navHeaderOptions: {
      title: '',
      showLongSearchBar: true,
      showGoBack: true,
    },
    routerConfig: {
      cls: ['details'],
    },
  },
};

export const testRoute: RouteRecordRaw = {
  name: 'test',
  path: '/test/',
  component: () => import('~/pages/test.vue'),
  meta: {
    middleware: 'internal-users-guard',
    menuSelected: 'home',
    title: 'Detalles del producto',
    navHeaderOptions: {
      title: '',
      showLongSearchBar: true,
      showGoBack: true,
    },
  },
};

export const detailsAllQuestionsAndAnswersRoute: RouteRecordRaw = {
  name: 'details-all-questions-and-answers',
  path: '/:link*',
  component: () => import('~/pages/details/[id]/all-questions-and-answers.vue'),
  meta: {
    menuSelected: null,
    title: 'Todas las preguntas y respuestas',
    navHeaderOptions: {
      title: 'Todas las preguntas y respuestas',
      showLongSearchBar: false,
      showGoBack: true,
      showCart: false,
    },
    routerConfig: {
      cls: ['cpqa_writequestion'],
    },
  },
};
export const detailsAllReviewsRoute: RouteRecordRaw = {
  name: 'details-all-reviews',
  path: '/:link*',
  component: () => import('~/pages/details/[id]/all-reviews.vue'),
  meta: {
    menuSelected: null,
    title: 'Todas las opiniones',
    navHeaderOptions: {
      title: 'Todas las opiniones',
      showLongSearchBar: false,
      showGoBack: true,
      showCart: false,
    },
    routerConfig: {
      cls: ['cpreviews_all'],
    },
  },
};

export const catalogManRoute: RouteRecordRaw = {
  name: 'catalog',
  path: '/:link*',
  component: () => import('~/pages/catalog.vue'),
  meta: {
    layout: 'default-catalog',
    menuSelected: 'categories',
    title: 'Detalles del producto',
    navHeaderOptions: {
      title: '',
      showLongSearchBar: false,
      showSearch: true,
      showGoBack: true,
    },
    routerConfig: {
      cls: ['manufacturerlist', 'alist', 'search'],
    },
  },
};
export const alchemyForceRoutes: RouteRecordRaw[] = [
  {
    name: 'catalog-af',
    path: '/:link*',
    component: () => import('~/pages/alchemy-force/index.vue'),
    meta: {
      layout: 'default-catalog',
      menuSelected: 'categories',
      title: 'ALCHEMY FORCE',
      navHeaderOptions: {
        title: 'ALCHEMY FORCE',
        showLongSearchBar: false,
        showSearch: true,
        showGoBack: true,
      },
      routerConfig: {
        cls: ['catalog_af'],
      },
    },
  },
  {
    name: 'af-configurator',
    path: '/Alchemy-force/:id/configurador/',
    component: () => import('~/pages/alchemy-force/af-config.vue'),
    meta: {
      menuSelected: null,
      title: 'Configurador',
      navHeaderOptions: {
        title: 'Configurar mi PC',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'af-details',
    path: '/:link*',
    component: () => import('~/pages/details/[id]/af-details.vue'),
    meta: {
      menuSelected: 'categories',
      title: 'Especificaciones',
      layout: 'af-default',
      navHeaderOptions: {
        title: 'Especificaciones',
        showLongSearchBar: true,
        showGoBack: true,
        showCart: true,
      },
      routerConfig: {
        cls: ['details_af'],
      },
    },
  },
  {
    name: 'af-versions',
    path: '/Alchemy-force/:id/versiones/',
    component: () => import('~/pages/alchemy-force/af-versions.vue'),
    meta: {
      menuSelected: null,
      title: 'Versiones',
      layout: 'af-default',
      navHeaderOptions: {
        title: 'Versiones',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
    },
  },
];
export const accountRoutes: RouteRecordRaw[] = [
  {
    name: 'sign-up',
    path: '/bienvenido/',
    component: () => import('~/pages/account/sign-up.vue'),
    meta: {
      menuSelected: null,
      title: 'Registrarse en Cyberpuerta',
      navHeaderOptions: {
        title: 'Registrarse en Cyberpuerta',
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        showSearch: false,
      },
      middleware: 'without-session-guard',
    },
  },
  {
    name: 'account',
    path: '/Mi-cuenta/',
    component: () => import('~/pages/account/index.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Mi cuenta',
      navHeaderOptions: {
        title: 'Mi cuenta',
        showLongSearchBar: false,
        showAccount: true,
        showSearch: true,
        headline: 'Hola',
        numOfItems: 0,
      },
      navHeaderOptionsAuth: {
        title: 'Mi cuenta',
        showLongSearchBar: false,
        showAccount: true,
        showSearch: true,
        headline: 'Hola, {{name}}',
        numOfItems: 0,
      },
      // Avoid refetching on tab change
      keepalive: true,
      middleware: 'auth-guard',
    },
  },
  {
    name: 'account-orders',
    path: '/historial-de-pedidos/',
    component: () => import('~/pages/account/orders/index.vue'),
    meta: {
      name: 'orders',
      path: '/account/orders',
      menuSelected: 'account',
      title: 'Mis pedidos',
      middleware: 'auth-guard',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: true,
        showGoBack: true,
        showSearch: true,
        title: 'Mis pedidos',
      },
      routerConfig: {
        cls: ['account_order'],
      },
    },
  },
  // TODO: Pending (Does not exist)
  {
    name: 'account-orders-payment-instructions',
    path: '/mis-pedidos/:id/instrucciones-de-pago/',
    component: () => import('~/pages/account/orders/[id]/payment-instructions.vue'),
    meta: {
      name: 'account-orders-payment-instructions',
      path: '/account/orders/[id]/payment-instructions',
      menuSelected: 'account',
      title: 'Instrucciones de pago',
      middleware: 'auth-guard',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        showSearch: false,
        title: 'Instrucciones de pago',
      },
    },
  },
  // TODO: Pending (Does not exist)
  {
    name: 'account-orders-invoicing',
    path: '/account/orders/invoicing/',
    component: () => import('~/pages/account/orders/invoicing.vue'),
    meta: {
      menuSelected: null,
      title: 'Editar datos de facturación',
      middleware: 'auth-guard',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        showSearch: false,
        title: 'Editar datos de facturación',
      },
    },
  },
  // TODO: Pending (Does not exist)
  {
    name: 'account-settings',
    path: '/Mi-cuenta/configuracion/',
    component: () => import('~/pages/account/settings/index.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Configuración de cuenta',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Configuración de cuenta',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
      },
    },
  },
  // TODO: Pending (Does not exist)
  {
    name: 'account-settings-deletion',
    path: '/Mi-cuenta/configuracion/borrar/',
    component: () => import('~/pages/account/settings/account-deletion.vue'),
    meta: {
      name: 'account-deletion',
      path: '/account/settings/account-deletion',
      menuSelected: null,
      middleware: 'auth-guard',
      title: 'Eliminar cuenta de Cyberpuerta',
      navHeaderOptions: {
        title: 'Eliminar cuenta de Cyberpuerta',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        showCart: false,
      },
      routerConfig: {
        types: ['delete_account'],
      },
    },
  },
  {
    name: 'account-settings-edit-profile',
    path: '/mi-perfil/',
    component: () => import('~/pages/account/settings/edit-profile.vue'),
    meta: {
      menuSelected: null,
      title: 'Editar perfil',
      middleware: 'auth-guard',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Editar perfil',
      },
    },
  },
  // TODO: Pending (Does not exist)
  {
    name: 'account-settings-my-contributions',
    path: '/mi-perfil/aportaciones/',
    component: () => import('~/pages/account/settings/my-contributions.vue'),
    meta: {
      name: 'my-contributions',
      path: '/account/settings/my-contributions',
      middleware: 'auth-guard',
      menuSelected: null,
      title: 'Mis aportaciones',
      navHeaderOptions: {
        title: 'Mis aportaciones',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        showCart: false,
      },
    },
  },
  {
    name: 'account-settings-password',
    path: '/mi-contrasena/',
    component: () => import('~/pages/account/settings/password.vue'),
    meta: {
      name: 'password',
      path: '/account/settings/password',
      menuSelected: null,
      middleware: 'auth-guard',
      title: 'Configurar contraseña',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Configurar contraseña',
      },
    },
  },
  {
    name: 'new-password',
    path: '/nueva-contrasena/',
    component: () => import('~/pages/account/settings/new-password.vue'),
    meta: {
      name: 'new-password',
      path: '/nueva-contrasena',
      menuSelected: null,
      title: 'Ingresa tu nueva contraseña',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Ingresa tu nueva contraseña',
      },
      routerConfig: {
        cls: ['forgotpwd'],
        cleanQuery: true,
      },
    },
  },
  {
    name: 'account-settings-subscriptions',
    path: '/preferencias-de-correo/',
    component: () => import('~/pages/account/settings/subscriptions.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Suscripciones',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Suscripciones',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
        showGoBack: true,
      },
      routerConfig: {
        cls: ['cpemail_subscriptions'],
        cleanQuery: true,
      },
    },
  },
  {
    name: 'account-track-order',
    path: '/rastrear-pedido/',
    component: () => import('~/pages/account/orders/track-order.vue'),
    meta: {
      menuSelected: null,
      title: 'Rastrear pedido',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Rastrear pedido',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        showCart: false,
        numOfItems: 0,
        showGoBack: true,
      },
    },
  },
  {
    name: 'account-favorites',
    path: '/mis-favoritos/',
    component: () => import('~/pages/account/favorites.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Mis favoritos',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Mis favoritos',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
      },
    },
  },
  {
    name: 'account-my-reviews',
    path: '/mis-opiniones/',
    component: () => import('~/pages/account/my-reviews.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Mis opiniones',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Mis opiniones',
        placeholder: 'Mis opiniones',
        showCart: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        showGoBack: true,
      },
    },
  },
  {
    name: 'account-autonomous-refunds',
    path: '/mis-reembolsos/',
    component: () => import('~/pages/account/autonomous-refunds/index.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Mis reembolsos',
      middleware: 'autonomous-refunds-guard',
      navHeaderOptions: {
        title: 'Mis reembolsos',
        placeholder: 'Mis reembolsos',
        showCart: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        showGoBack: true,
      },
    },
  },
  {
    name: 'autonomous-refunds-history',
    path: '/mi-historial-de-reembolsos/',
    component: () => import('~/pages/account/autonomous-refunds/refunds-history.vue'),
    meta: {
      menuSelected: null,
      title: 'Mi historial de reembolsos',
      middleware: 'autonomous-refunds-guard',
      navHeaderOptions: {
        title: 'Mi historial de reembolsos',
        placeholder: 'Mi historial de reembolsos',
        showCart: false,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        showGoBack: true,
      },
    },
  },
  {
    name: 'autonomous-request-refund',
    path: '/solicitar-reembolso/',
    component: () => import('~/pages/account/autonomous-refunds/request-refund.vue'),
    meta: {
      menuSelected: null,
      title: 'Solicitar reembolso',
      middleware: 'autonomous-refunds-guard',
      navHeaderOptions: {
        title: 'Solicitar reembolso',
        placeholder: 'Solicitar reembolso',
        showCart: false,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        showGoBack: true,
      },
    },
  },
  {
    name: 'autonomous-refunds-enter-bank-account',
    path: '/mis-reembolsos/alta-cuenta-bancaria/',
    component: () => import('~/pages/account/autonomous-refunds/enter-bank-account.vue'),
    meta: {
      menuSelected: null,
      title: 'Dar de alta una cuenta bancaria para reembolso',
      middleware: 'autonomous-refunds-guard',
      navHeaderOptions: {
        title: 'Dar de alta una cuenta bancaria para reembolso',
        placeholder: 'Dar de alta una cuenta bancaria para reembolso',
        showCart: false,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        showGoBack: true,
      },
    },
  },
  {
    name: 'account-non-reviewed-products',
    path: '/mis-articulos-no-calificados/',
    component: () => import('~/pages/account/non-reviewed-products.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Productos sin calificar',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Productos sin calificar',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
      },
    },
  },
  {
    name: 'account-public-profile',
    path: '/public-profile/',
    component: () => import('~/pages/account/public-profile.vue'),
    meta: {
      name: 'public-profile',
      path: '/account/public-profile',
      menuSelected: 'account',
      title: 'Perfil público',
      middleware: 'auth-guard',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Perfil público',
      },
    },
  },
  {
    name: 'account-shipping-billing',
    path: '/mis-direcciones/',
    component: () => import('~/pages/account/shipping-billing.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Datos de envío, pago y facturación',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Datos de envío, pago y facturación',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
      },
    },
  },
  {
    name: 'account-my-questions',
    path: '/mis-preguntas/',
    component: () => import('~/pages/account/my-questions.vue'),
    meta: {
      name: 'my-questions',
      path: '/account/my-questions',
      menuSelected: 'account',
      title: 'Mis preguntas',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Mis preguntas',
        placeholder: 'Mis preguntas',
        showCart: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        showGoBack: true,
      },
    },
  },
  {
    name: 'account-my-downloads',
    path: '/mis-descargas/',
    component: () => import('~/pages/account/my-downloads.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Descargas',
      middleware: 'auth-guard',
      navHeaderOptions: {
        title: 'Descargas',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
        showGoBack: true,
      },
    },
  },
  {
    name: 'account-enter-mail-screen',
    path: '/olvide-mi-contrasena/',
    component: () => import('~/pages/account/enter-mail-screen.vue'),
    meta: {
      menuSelected: null,
      title: 'Recuperar contraseña',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        showSearch: false,
        title: 'Recuperar contraseña',
      },
      middleware: [
        function(to) {
          if (to.query.uid && to.query.email) {
            return navigateTo({path: '/nueva-contrasena', query: to.query});
          }
        },
      ],
    },
  },
  {
    name: 'link-accounts-welcome-back',
    path: '/bienvenido-de-vuelta/',
    component: () => import('~/pages/account/link-accounts-welcome-back.vue'),
    meta: {
      menuSelected: null,
      title: '',
      navHeaderOptions: {
        showLongSearchBar: true,
        showCart: true,
        showGoBack: false,
        showSearch: false,
      },
    },
  },
  {
    name: 'sign-up-welcome',
    path: '/nos-alegra-verte/',
    component: () => import('~/pages/account/sign-up-welcome.vue'),
    meta: {
      menuSelected: null,
      title: '',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        title: 'Registro exitoso',
        showGoBack: false,
        showSearch: false,
      },
    },
  },
  {
    name: 'account-my-warranties',
    path: '/mis-garantias/',
    component: () => import('~/pages/account/warranties/index.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Mis garantías',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: true,
        title: 'Mis garantías',
        showGoBack: true,
        showSearch: true,
      },
      middleware: 'warranties-guard',
    },
  },
  {
    name: 'account-my-warranties-manage-info',
    path: '/mis-garantias/gestionar/',
    component: () => import('~/pages/account/warranties/manage-info.vue'),
    meta: {
      menuSelected: null,
      title: 'Gestionar información',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        title: 'Gestionar información',
        showGoBack: true,
        showSearch: false,
      },
      middleware: 'warranties-guard',
    },
  },
  {
    name: 'account-my-warranties-serial-number',
    path: '/mis-garantias/numero-de-serie/',
    component: () => import('~/pages/account/warranties/serial-number.vue'),
    meta: {
      menuSelected: null,
      title: 'Número de serie',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        title: 'Número de serie',
        showGoBack: true,
        showSearch: false,
      },
      middleware: 'warranties-guard',
    },
  },
  {
    name: 'account-my-warranties-activation-data',
    path: '/mis-garantias/datos-de-activacion/',
    component: () => import('~/pages/account/warranties/activation-data.vue'),
    meta: {
      menuSelected: null,
      title: 'Datos de activación',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        title: 'Datos de activación',
        showGoBack: true,
        showSearch: false,
      },
      middleware: 'warranties-guard',
    },
  },
  {
    name: 'account-my-warranties-activation-product',
    path: '/mis-garantias/equipo-de-activacion/',
    component: () => import('~/pages/account/warranties/activation-product.vue'),
    meta: {
      menuSelected: null,
      title: 'Equipo donde se activará la garantía',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        title: 'Equipo donde se activará la garantía',
        showGoBack: true,
        showSearch: false,
      },
      middleware: 'warranties-guard',
    },
  },
];

// TODO: brand directory is necessary???

export const brandsRoutes: RouteRecordRaw[] = [
  {
    name: 'brands',
    path: '/Por-Marca/',
    component: () => import('~/pages/brands/index.vue'),
    meta: {
      menuSelected: 'home',
      title: 'Todas las marcas',
      navHeaderOptions: {
        title: 'Todas las marcas',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        showCart: true,
      },
    },
  },
];

export const categoriesRoutes: RouteRecordRaw[] = [
  // TODO: Pending (Does not exist)
  {
    name: 'categories',
    // @ts-expect-error custom path, parseo-seo should add the trailing slash
    path: '/Categorias/:link*',
    component: () => import('~/pages/categories/index.vue'),
    meta: {
      menuSelected: 'categories',
      layout: 'home-layout',
      title: 'Categorías',
      navHeaderOptions: {
        title: 'Categorías',
        showLongSearchBar: false,
        showSearch: true,
        numOfItems: 0,
      },
      // Avoid refetching on tab change
      // keepalive: true,
    },
  },
  {
    name: 'categories_parent',
    path: '/:link*',
    component: () => import('~/pages/categories/index.vue'),
    meta: {
      menuSelected: 'categories',
      layout: 'home-layout',
      title: 'Categorías',
      navHeaderOptions: {
        title: 'Categorías',
        showLongSearchBar: false,
        showSearch: true,
        numOfItems: 0,
      },
      // Avoid refetching on tab change
      // keepalive: true,
      routerConfig: {
        cls: ['categories_menu'],
      },
    },
  },
];

// TODO: category directory is necessary???

// TODO: Pending (By SEO API)
export const detailsRoutes: RouteRecordRaw[] = [
  {
    name: 'details-ask-question',
    path: '/details/:id/ask-question/',
    component: () => import('~/pages/details/[id]/ask-question.vue'),
    meta: {
      name: 'details-ask-question',
      path: '/details/[id]/ask-question',
      menuSelected: null,
      title: 'Hacer una pregunta',
      navHeaderOptions: {
        title: 'Hacer una pregunta',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'details-gallery',
    path: '/details/:id/gallery/',
    component: () => import('~/pages/details/[id]/gallery.vue'),
    meta: {
      menuSelected: null,
      title: 'Imagen del producto',
      navHeaderOptions: {
        title: 'Imagen del producto',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'details-specs',
    path: '/details/:id/specs/',
    component: () => import('~/pages/details/[id]/specs.vue'),
    meta: {
      menuSelected: null,
      title: 'Especificaciones',
      navHeaderOptions: {
        title: 'Especificaciones',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  // {
  //   name: 'af-details',
  //   path: '/details/:id/af-details',
  //   component: () => import('~/pages/details/[id]/af-details.vue'),
  //   meta: {
  //     menuSelected: null,
  //     title: 'Especificaciones',
  //     layout: 'af-default',
  //     navHeaderOptions: {
  //       title: 'Especificaciones',
  //       showLongSearchBar: true,
  //       showGoBack: true,
  //       showCart: false
  //     }
  //   }
  // },
  // {
  //   name: 'details-questions',
  //   path: '/details/:id/questions',
  //   component: () => import('~/pages/details/[id]/questions.vue'),
  //   meta: {
  //     menuSelected: 'categories',
  //     title: 'Todas las opiniones',
  //     navHeaderOptions: {
  //       title: 'Todas las preguntas y respuestas',
  //       showGoBack: true,
  //       showLongSearchBar: false,
  //       showCart: false
  //     }
  //   }
  // },
  // {
  //   name: 'details-reviews',
  //   path: '/details/:id/reviews',
  //   component: () => import('~/pages/details/[id]/reviews.vue'),
  //   meta: {
  //     menuSelected: 'categories',
  //     title: 'Todas las opiniones',
  //     navHeaderOptions: {
  //       title: 'Todas las opiniones',
  //       showLongSearchBar: false,
  //       showGoBack: true,
  //       showCart: false
  //     }
  //   }
  // },
  {
    name: 'details-write-new-review',
    path: '/:link*',
    component: () => import('~/pages/details/[id]/write-new-review.vue'),
    meta: {
      menuSelected: null,
      title: 'Escribir opinión',
      navHeaderOptions: {
        title: 'Escribir opinión',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
      routerConfig: {
        cls: ['cpreviews_write'],
      },
    },
  },
  {
    name: 'details-all-answers',
    path: '/details/:id/all-answers/:questionId/',
    component: () => import('~/pages/details/[id]/all-answers.vue'),
    meta: {
      menuSelected: null,
      title: 'Todas las respuestas',
      navHeaderOptions: {
        title: 'Todas las respuestas',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'details-write-answer',
    path: '/details/:id/all-answers/:questionId/responder/',
    component: () => import('~/pages/details/[id]/write-answer.vue'),
    meta: {
      menuSelected: null,
      title: 'Escribir respuesta',
      navHeaderOptions: {
        title: 'Escribir respuesta',
        showLongSearchBar: false,
        showGoBack: true,
        showCart: false,
      },
    },
  },
];

export const menuRoutes: RouteRecordRaw[] = [
  // TODO: Pending (Does not exist)
  {
    name: 'menu',
    path: '/menu/',
    component: () => import('~/pages/menu/index.vue'),
    meta: {
      menuSelected: 'menu',
      title: 'Menu',
      layout: 'home-layout',
      navHeaderOptions: {
        title: 'Hola,',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        headline: 'Ingresa a tu cuenta',
        numOfItems: 0,
      },
      navHeaderOptionsAuth: {
        title: 'Hola,',
        showLongSearchBar: false,
        showAccount: true,
        showSearch: true,
        headline: '{{name}}',
        numOfItems: 0,
      },
    },
  },
];

export const miscRoutes: RouteRecordRaw[] = [
  // TODO: Pending (Does not exist)
  {
    name: 'connect-with-us',
    path: '/conecta-con-nosotros/',
    component: () => import('~/pages/misc/connect-with-us.vue'),
    meta: {
      menuSelected: 'menu',
      title: 'Conecta con nosotros',
      navHeaderOptions: {
        title: 'Conecta con nosotros',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
        showGoBack: true,
      },
    },
  },
  {
    name: 'faq',
    path: '/Preguntas-frecuentes/',
    component: () => import('~/pages/misc/faq.vue'),
    meta: {
      name: 'faq',
      path: '/misc/faq',
      menuSelected: null,
      title: 'Preguntas frecuentes | Cyberpuerta.mx',
      navHeaderOptions: {
        title: 'Preguntas frecuentes',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
      },
    },
  },
  {
    name: 'global-ranking',
    path: '/ranking-global/',
    component: () => import('~/pages/misc/global-ranking.vue'),
    meta: {
      menuSelected: null,
      title: 'Ranking global',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Ranking global',
      },
    },
  },
  {
    name: 'information-center',
    path: '/Centro-de-Informacion/',
    component: () => import('~/pages/misc/information-center.vue'),
    meta: {
      menuSelected: 'menu',
      title: 'Centro de información',
      navHeaderOptions: {
        title: 'Centro de información',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
        showGoBack: true,
      },
    },
  },
  // TODO: Pending (Does not exist)
  {
    name: 'sac',
    path: '/contacto/',
    component: () => import('~/pages/misc/sac.vue'),
    meta: {
      name: 'sac',
      path: '/misc/sac',
      menuSelected: 'menu',
      title: 'Atención al cliente',
      navHeaderOptions: {
        title: 'Atención al cliente',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
      },
    },
  },
  {
    name: 'shopping-tools',
    path: '/Herramientas-de-compra/',
    component: () => import('~/pages/misc/shopping-tools.vue'),
    meta: {
      menuSelected: 'menu',
      title: 'Herramientas de compra',
      navHeaderOptions: {
        title: 'Herramientas de compra',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
        showGoBack: true,
      },
    },
  },
];

export const searchRoutes: RouteRecordRaw[] = [
  // TODO: Pending (Does not exist) / (By SEO API)
  {
    name: 'search',
    // @ts-expect-error custom path, parseo-seo should add the trailing slash
    path: '/busqueda/:q?',
    component: () => import('~/pages/search/[[q]].vue'),
    meta: {
      menuSelected: null,
      title: 'Search',
      navHeaderOptions: {
        showLongSearchBar: true,
        showSearch: false,
        showCart: false,
        showGoBack: true,
        placeholder: 'Cyberpuerta',
        numOfItems: 0,
        blueVersion: true,
        searchFocus: true,
      },
    },
  },
];

export const viewHistoryRoutes: RouteRecordRaw[] = [
  {
    name: 'view-history',
    path: '/Historial/',
    component: () => import('~/pages/viewHistory/index.vue'),
    meta: {
      menuSelected: 'menu',
      title: 'Historial',
      navHeaderOptions: {
        title: 'Historial',
        showGoBack: true,
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
      },
    },
  },
];

export const checkoutRoutes: RouteRecordRaw[] = [
  {
    name: 'basket',
    path: '/carrito-de-compras/',
    component: () => import('~/pages/basket.vue'),
    meta: {
      menuSelected: null,
      title: 'Carrito de compras',
      layout: 'home-layout',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Carrito de compras',
      },
    },
  },
  {
    name: 'checkout-address',
    path: '/elegir-direccion-usuario/',
    component: () => import('~/pages/checkout-basket/address.vue'),
    meta: {
      menuSelected: null,
      title: 'Ingresar dirección de envío',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Ingresar dirección de envío',
      },
    },
  },
  {
    name: 'checkout-billing',
    path: '/elegir-facturacion-usuario/',
    component: () => import('~/pages/checkout-basket/billing.vue'),
    meta: {
      menuSelected: null,
      title: 'Ingresar datos de facturación',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Ingresar datos de facturación',
      },
    },
  },
  {
    name: 'checkout-payment',
    path: '/envio-y-pago/',
    component: () => import('~/pages/checkout-basket/payment.vue'),
    meta: {
      menuSelected: null,
      title: 'Seleccionar envío y pago',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Seleccionar envío y pago',
      },
    },
  },
  {
    name: 'checkout-confirmation',
    path: '/verificar-y-enviar/',
    component: () => import('~/pages/checkout-basket/payment-confirmation.vue'),
    meta: {
      menuSelected: null,
      title: 'Confirmar pedido',
      middleware: 'auth-guard',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Confirmar pedido',
      },
    },
  },
  {
    name: 'order-confirmation',
    path: '/gracias-por-su-compra/',
    component: () => import('~/pages/checkout-basket/order-confirmation.vue'),
    meta: {
      menuSelected: null,
      title: 'Gracias por su compra',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Tu compra ha sido confirmada',
      },
    },
  },
  {
    name: 'verify-identity',
    path: '/verificacion-de-identidad/',
    component: () => import('~/pages/checkout-basket/verify-identity.vue'),
    meta: {
      menuSelected: null,
      routerConfig: {
        cls: ['cpjumio_redirect'],
      },
    },
  },
  {
    name: 'redirect',
    path: '/redirect/',
    component: () => import('~/pages/checkout-basket/redirect.vue'),
    meta: {
      menuSelected: null,
    },
  },
  {
    name: 'monthly-payment',
    path: '/opciones-de-pago/',
    component: () => import('~/pages/checkout-basket/monthly-payment.vue'),
    meta: {
      menuSelected: null,
      title: 'Opciones de pago a mensualidades',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Opciones de pago a mensualidades',
      },
    },
  },
  {
    name: 'monthly-payment-af',
    path: '/opciones-de-pago/',
    component: () => import('~/pages/checkout-basket/monthly-payment-af.vue'),
    meta: {
      menuSelected: null,
      title: 'Opciones de pago a mensualidades',
      layout: 'af-default',
      navHeaderOptions: {
        showLongSearchBar: false,
        showCart: false,
        showGoBack: true,
        title: 'Opciones de pago a mensualidades',
      },
    },
  },
  {
    name: 'ppcp-form',
    path: '/payment/paypal-ppcp-form/',
    component: () => import('~/pages/checkout-basket/ppcp-form.vue'),
    meta: {
      menuSelected: null,
      title: 'Pago con tarjeta',
    },
  },
];

export const returnsModuleRoutes: RouteRecordRaw[] = [
  {
    name: 'order-product-details',
    path: '/order-product-details/',
    component: () => import('~/pages/account/orders/returns-module/product-details.vue'),
    meta: {
      middleware: 'return-module-guard',
      menuSelected: null,
      title: 'Detalles del producto',
      navHeaderOptions: {
        title: 'Detalles del producto',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'cancel-overdue-products',
    path: '/cancel-overdue-products/',
    component: () => import('~/pages/account/orders/returns-module/cancel-overdue-products.vue'),
    meta: {
      menuSelected: null,
      title: 'Cancelar los productos con atraso de mi pedido',
      navHeaderOptions: {
        title: 'Cancelar los productos con atraso de mi pedido',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-report-reason',
    path: '/Motivo-del-reporte/',
    component: () => import('~/pages/account/orders/returns-module/report-reason.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Solicitar devolución',
      navHeaderOptions: {
        title: 'Solicitar devolución',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-package-status',
    path: '/Estatus-del-producto/',
    component: () => import('~/pages/account/orders/returns-module/package-status.vue'),
    meta: {
      menuSelected: 'account',
      title: 'Solicitar devolución',
      navHeaderOptions: {
        title: 'Solicitar devolución',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'cancel-pending-report',
    path: '/cancel-pending-report/',
    component: () => import('~/pages/account/orders/returns-module/cancel-pending-report.vue'),
    meta: {
      menuSelected: null,
      title: 'Cancelar reporte pendiente',
      navHeaderOptions: {
        title: 'Cancelar reporte pendiente',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-add-evidence',
    path: '/add-evidence/',
    component: () => import('~/pages/account/orders/returns-module/rm-add-evidence.vue'),
    meta: {
      menuSelected: null,
      title: 'Solicitar devolución',
      navHeaderOptions: {
        title: 'Solicitar devolución',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-describe-reason',
    path: '/describe-reason/',
    component: () => import('~/pages/account/orders/returns-module/rm-describe-reason.vue'),
    meta: {
      menuSelected: null,
      title: 'Solicitar devolución',
      navHeaderOptions: {
        title: 'Solicitar devolución',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-serial-number',
    path: '/serial-number/',
    component: () => import('~/pages/account/orders/returns-module/rm-serial-number.vue'),
    meta: {
      menuSelected: null,
      title: 'Solicitar devolución',
      navHeaderOptions: {
        title: 'Solicitar devolución',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-preferred-action',
    path: '/preferred-action/',
    component: () => import('~/pages/account/orders/returns-module/rm-preferred-action.vue'),
    meta: {
      menuSelected: null,
      title: 'Solicitar devolución',
      navHeaderOptions: {
        title: 'Solicitar devolución',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-not-received-amount',
    path: '/not-received-amount/',
    component: () => import('~/pages/account/orders/returns-module/rm-not-received-amount.vue'),
    meta: {
      menuSelected: null,
      title: 'No he recibido mi producto',
      navHeaderOptions: {
        title: 'No he recibido mi producto',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
  {
    name: 'rm-success-screen',
    path: '/request-success/',
    component: () => import('~/pages/account/orders/returns-module/rm-success-screen.vue'),
    meta: {
      menuSelected: null,
      title: 'Solicitud enviada',
      navHeaderOptions: {
        title: 'Solicitud enviada',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: true,
        numOfItems: 0,
        showGoBack: true,
        showCart: true,
      },
    },
  },
  {
    name: 'rm-update-report',
    path: '/update-report/',
    component: () => import('~/pages/account/orders/returns-module/rm-update-report.vue'),
    meta: {
      menuSelected: null,
      title: 'Actualizar reporte',
      navHeaderOptions: {
        title: 'Actualizar reporte',
        showLongSearchBar: false,
        showAccount: false,
        showSearch: false,
        numOfItems: 0,
        showGoBack: true,
        showCart: false,
      },
    },
  },
];

export const unavailableRoute: RouteRecordRaw = {
  name: 'unavailable',
  path: '/:link*',
  component: () => import('~/pages/unavailable.vue'),
  meta: {
    menuSelected: null,
    title: 'No es posible acceder a esta página',
    navHeaderOptions: {
      showLongSearchBar: true,
      showAccount: false,
      showSearch: false,
      numOfItems: 0,
      showGoBack: false,
    },
  },
};

export const routes: RouteRecordRaw[] = [
  ...checkoutRoutes,
  ...menuRoutes,
  ...miscRoutes,
  ...searchRoutes,
  ...viewHistoryRoutes,
  ...detailsRoutes,
  ...categoriesRoutes,
  ...brandsRoutes, // '/Por-Marca'
  ...accountRoutes,
  ...returnsModuleRoutes,
  ...alchemyForceRoutes,
  homeRoute, // '/'
  detailsRoute, // '/:link*'
  detailsAllQuestionsAndAnswersRoute, // '/'
  detailsAllReviewsRoute,
  catalogManRoute, // '/:link*'
  cmsRoute, // '/:link*',
  unavailableRoute,
  testRoute,
];

export default <RouterConfig>{
  scrollBehavior: () => {
    // TODO: This is a hack
    document.getElementById('main-content')?.scrollTo({top: 0});
    return {
      el: '#main-content',
      top: -1000,
    };
  },
  routes: () => [...routes],
};
