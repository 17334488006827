import {createGtm} from '@gtm-support/vue-gtm';

export default defineNuxtPlugin({
  name: 'vue-gtm-client',
  parallel: true,
  env: {
    islands: false,
  },
  setup(nuxtApp) {
    nuxtApp.vueApp.use(createGtm({
      id: 'GTM-MQPN922Q',
      enabled: true,
      debug: false,
      defer: true,
      trackOnNextTick: true,
      vueRouter: useRouter(),
    }));
  },
});
