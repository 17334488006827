import {
  activateUserAccount,
  sendVerificationEmail,
  changePassword,
  requestPasswordReset,
  getFidInfo,
  fidLogin,
  fidAbort,
  deleteFid,
  deletePassword,
  logoutUser,
  recoverPassword,
} from '~/utils/services/api/account/auth/auth';
import {QUERY_KEYS} from '~/utils/services/keys';
import {useCpUser} from '~/composables/useCpUser';
import {AuthActions} from '~/server/utils/auth';
import {useGTM} from '~/composables/useGTM';
import {useUXNavigationStore} from '~/stores';
import * as Sentry from '@sentry/vue';

export const useAuthActions = () => {
  const {signIn, signOut, data: authUser} = useAuth();
  const {redirectInfo} = useCpUser();

  const $gtm = useGTM();
  const loginWithCp = (username: string, password: string) => {
    return signIn('credentials', {
      username,
      password,
      redirect: false,
      lastAccessToken: authUser.value?.token,
    });
  };

  const createSimpleSession = async (force?: boolean) => {
    if (!authUser.value?.token || force) {
      await signIn('credentials', {
        action: AuthActions.CREATE_SIMPLE_SESSION,
        redirect: false,
      });
    }
  };

  const refreshUser = async () => {
    await signIn('credentials', {
      action: AuthActions.REFRESH,
      redirect: false,
      lastAccessToken: authUser.value?.token,
      lastUserEmail: authUser.value?.user.email,
    });
    return authUser.value;
  };

  const logout = async () => {
    const token = authUser.value?.token;
    if (!token) {
      throw new Error(
        'You are calling logout but no user is currently logged in.',
      );
    }
    // Finish session in the server (CP API).
    try {
      await logoutUser(token);
    } catch (error) {
      // Expired token, just renew session in the next step.
    } finally {
      // Finish Nitro session.
      await signOut({redirect: false});
      // GTM logout
      $gtm?.trackEvent({
        event: 'logout',
      });
      // We always need a simple session.
      await createSimpleSession(true);
      navigateTo({name: 'home'});
    }
  };

  const handleSuccessLogin = (routeName?: string) => {
    // GTM log
    $gtm?.trackEvent({
      event: 'login',
      method: 'Manual',
      user_id: authUser.value?.user.id,
    });
    // Close Login Modal
    useEvent('show-login-modal', {show: false});
    // Redirect to the page that the user was trying to access
    const redirectData = redirectInfo.value;
    if (redirectData.routeName || routeName) {
      navigateTo({name: redirectData.routeName || routeName, params: redirectData.params, query: redirectData.query});
    }
    // Just reseting redirect data
    redirectData.routeName = null;
    // reset ux store
    useUXNavigationStore().showDownloadAppSection = true;

    Sentry.setUser({email: authUser.value?.user.email});
  };

  const activateAccount = (activationCode: string) => {
    const authState = useCpUser();
    if (!authState.user.value?.email) {
      return Promise.reject(
        new Error(
          'User must be defined before calling activateAccount. Make sure to login first.',
        ),
      );
    }

    return activateUserAccount(authState.user.value.email, activationCode);
  };

  const resendActivationEmail = () => {
    const authState = useCpUser();
    if (!authState.user.value?.email) {
      return Promise.reject(
        new Error(
          'User must be defined before calling activateAccount. Make sure to login first.',
        ),
      );
    }
    return sendVerificationEmail(authState.user.value.email);
  };

  const useChangePassword = (params: {
    confirmPassword: string
    oldPassword: string
    password: string
  }) => {
    const {user, userEmail} = useCpUser();
    if (!user.value) {
      return rejectQueryFn(
        'User must be defined before calling activateAccount. Make sure to login first.',
      );
    }
    $gtm?.trackEvent({
      event: 'update_password',
    });
    return changePassword(userEmail!, params);
  };

  const useRecoverPassword = (email: string, params: {
    confirmPassword: string
    password: string
    updateId: string
  }) => {
    return recoverPassword(email, params);
  };

  const useRequestPasswordReset = (email: string) => {
    $gtm?.trackEvent({
      event: 'request_password',
    });
    return requestPasswordReset(email);
  };

  const useGetFidInfo = () =>
    useAsyncData(QUERY_KEYS.auth.fidInfo, () => getFidInfo());

  const useFidLogin = (provider: string, credential: string) =>
    fidLogin(provider, credential);

  const useFidAbort = (provider: string) =>
    useAsyncData(QUERY_KEYS.auth.fidLoginAbort, () => fidAbort(provider));

  const useDeleteFid = (fidId: string) =>
    useAsyncData(QUERY_KEYS.auth.deleteFid, () => deleteFid(fidId));

  const useDeletePassword = () =>
    useAsyncData(QUERY_KEYS.auth.deletePassword, () => deletePassword());

  return {
    logout,
    loginWithCp,
    refreshUser,
    activateAccount,
    resendActivationEmail,
    useChangePassword,
    useRequestPasswordReset,
    useGetFidInfo,
    useFidLogin,
    useFidAbort,
    useDeleteFid,
    useDeletePassword,
    handleSuccessLogin,
    createSimpleSession,
    useRecoverPassword,
  };
};
