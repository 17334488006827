<script setup lang="ts">
  import {computed, ref} from 'vue';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import useCpBem from '~/composables/useCpBem';
  import CpText from '~/components/cp/CpText/CpText.vue';
  import {toCssVariable as toCss, useColors} from '~/composables/useCpType';
  import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';
  import type {ICpRouteLocationNamedRaw} from '~/utils/services/screens';

  const toCssVariable = toCss;
  const props = withDefaults(defineProps<{
          icon?: string
          label: string
          kind?: 'icon'|'iconlabel'|'label'
          color?: 'primary'|'secondary'|'critical'
          disabled?: boolean
          hollow?: boolean
          loading?: boolean
          href?: string
          to?: ICpRouteLocationNamedRaw | string;
          fullWidth?: boolean
          type?: 'button'|'submit'|'reset'
          noPadding?: boolean
          allowPointerEventsOnDisable?: boolean
          target?: string
          withoutBorder?: boolean
      }>(), {
    color: 'primary',
    kind: 'label',
    icon: 'info',
    target: '_self',
    allowPointerEventsOnDisable: false,
  });

  const emit = defineEmits({
    click: null,
  });

  const colors = computed(() => {
    const {disabled, hollow} = props;
    return useColors(props.color, {disabled, hollow});
  });
  const active = ref(false);

  const width = computed(() => props.fullWidth ? '100%' : (props.kind === 'icon' ? '48px' : 'auto'));
  const {bm} = useCpBem('cp-button');
</script>

<template>
    <component
        :is="href || to ? CpNuxtLink : 'button'"
        :class="bm({
            disabledLoading: disabled! && !allowPointerEventsOnDisable || loading!,
            hollow: hollow && !withoutBorder,
            noPadding, active,
            activeHollow: active && hollow && !allowPointerEventsOnDisable && !withoutBorder,
        })"
        :aria-label="label || icon"
        v-bind="{type, href, disabled: disabled && !allowPointerEventsOnDisable, to}"
        :type="type"
        :target="target"
        data-testid="cp-button"
        @mousedown="active = true"
        @mouseup="active = false"
        @touchstart.passive="active = true"
        @touchend.passive="active = false"
        @click="emit('click')"
    >
        <CpIconStandard
            v-if="kind !== 'label' || loading"
            :icon="loading ? 'sync' : icon!"
            :type="active && hollow && !allowPointerEventsOnDisable ? colors.activeColor : colors.color"
            kind="standard"
            :modifier="(disabled && hollow) ? 'light' : undefined"
        />
        <CpText
            v-if="kind !== 'icon' && !loading"
            variant="button-bold"
            :type="active && hollow && !allowPointerEventsOnDisable ? colors.activeColor : colors.color"
        >
            {{ label }}
        </CpText>
    </component>
</template>

<style scoped lang="scss">
    .cp-button {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 12px $cp-gutter;
        gap: $cp-gutter-small;
        width: v-bind(width);
        height: 48px;
        background: v-bind("toCssVariable(colors.background)");
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        text-decoration: none;
        border: 0;
        border-radius: $cp-border-radius;
        &--active {
          background: v-bind("toCssVariable(colors.active)");
        }
        &--hollow{
            border: 1px solid v-bind("toCssVariable(colors.color)");
        }
        &--active-hollow {
          border: 1px solid v-bind("toCssVariable(colors.activeColor)");
        }
        &--disabled-loading{
          pointer-events: none;
        }
        &--no-padding {
          padding: 0;
        }
    }
</style>
