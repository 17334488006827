<script setup lang="ts">
  import {computed} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import type {ICpRouteLocationNamedRaw, RouterName} from '~/utils/services/screens';
  import {useCpNavigateToParams} from '~/composables/useCpNavigateToParams';

  const props = defineProps<{
    pageName?: RouterName
    link?: string
    to?: ICpRouteLocationNamedRaw | string | null
    blank?: boolean
    href?: string
  }>();

  const {b} = useCpBem('cp-nuxt-link');

  const toComputed = computed(() => {
    return props.to ?? useCpNavigateToParams(props.pageName, props.href ? props.href : props.link);
  });
</script>

<template>
    <NuxtLink :class="b" :to="toComputed" :prefetch="false" :target=" blank ? '_blank' : '_self'">
        <slot />
    </NuxtLink>
</template>

<style scoped lang="scss">
  .cp-nuxt-link {
    text-decoration: none;
  }
</style>
