import type {IPaginatedResponse} from '~/types';
import {$cpFetch} from '~/composables/useCpFetch';
import {brandsPaths} from '~/utils/services/paths';

const PAGE_SIZE_PARAM = '500';

export type IBrand = {
    id: string;
    image: string;
    link: string;
    title: string;
};

export type IBrandsFilters = {
    featured?: boolean;
    firstLetter?: string;
    lastLetter?: string;
    search?: string;
};

export type ILetterGroup = { letter: string; total: number };
// TODO: Improve this type (meta is present also in PaginatedResponse
type AllBrandsResponse = IPaginatedResponse<IBrand> & {
    meta: {
        letterTotals: ILetterGroup[];
    };
};

type BrandsResponse = IPaginatedResponse<IBrand>;

export const getAllBrands = (options: IBrandsFilters) => {
    return $cpFetch<BrandsResponse>(brandsPaths.allBrands, {
        params: {
            ...options,
            'page[size]': PAGE_SIZE_PARAM,
        },
    });
};

export const getHomeBrands = () => {
    return $cpFetch<AllBrandsResponse>(brandsPaths.allBrands, {
        params: {
            'featured': true,
            'page[size]': 20,
        },
    });
};
