import {useCpUser} from '~/composables/useCpUser';
import type {IFidAccountData} from '~/types';
// import { ISignupFormData } from '~/types'
import type {CpUser} from '~/typings/next-auth';

export type LoginSuccessResponse = {
  token: string
}

export type SimpleSession = {
  id: string
}

export type AuthCredentials =
  | {
      email: string
      token: string
    }
  | {
      email: string
      password: string
    }

export type UserAccountUrls = {
  lang: string
  url: string
}

export type RegistrationType = 'app-start' | 'standard' | 'checkout'

export type LogoutSuccessResponse = {
  messages: string[]
  user: string
}

export type AcceptedProviders = 'google' | 'apple' | 'cp'

export type FidLoginData = {
  credential: string
  provider: Omit<AcceptedProviders, 'cp'>
  options?: {
    name: string
  }
}

/** User account exists and login */
export type FidLoginResponseLinked = {
  redirectTo: string
  token: string
  user: string
}

/** Is new user in CP and is registered */
export type FidLoginResponseNewUser = FidLoginResponseLinked & {
  newRegister: boolean
}

/** User email is already registered and is candidate for linking account */
export type FidLoginResponseCandidate = {
  isCandidate: boolean
  redirectTo: string
}

export type ActivatedAccountResponse = {
  msg: string
  status: string
}

export type FidLoginResponsePayload =
  | FidLoginResponseLinked
  | FidLoginResponseNewUser
  | FidLoginResponseCandidate

export type GoogleUser = {
  email: string
  idToken: string
  scopes: Array<string>
  serverAuthCode: string
  user: {
    email: string
    familyName: string
    givenName: string
    id: string
    name: string // full name
    photo: string // url
  }
}

export const authPaths = {
  user: (userId: string) => `/account/${userId}` as const,
  fidLogin: (provider: Omit<AcceptedProviders, 'cp'>) =>
    `/account/fid-login/${provider}` as const,
  fidAbort: (provider: string) =>
    `/account/fid-login/${provider}/abort` as const,
  fidLink: '/account/fid-link/login' as const,
  login: (userId: string) => `${authPaths.user(userId)}/login` as const,
  logout: (userId: string) => `${authPaths.user(userId)}/logout` as const,
  simpleSession: '/account/simple-session' as const,
  signup: '/account' as const,
  passwordReset: (userId: string) =>
    `${authPaths.user(userId)}/recover-password` as const,
  activateAccount: (userId: string) =>
    `${authPaths.user(userId)}/activate` as const,
  sendVerificationEmail: (email: string) =>
    `/validator/email/${email}` as const,
  fidInfo: (email: string) => `/account/${email}/fid` as const,
  deleteFid: (email: string, fidId: string) =>
    `/account/${email}/fid/${fidId}` as const,
};

export const getUser = (email: string, headers?: HeadersInit) => {
  return $cpFetch<CpUser>(authPaths.user(email), {headers});
};

export const signUp = (
  data: {
    user: string
    password: string
    password2: string
    name: string
    subscription?: boolean
  },
  source?: 'app-start' | 'standard' | 'checkout',
) => {
  return $cpFetch(authPaths.signup, {
    method: 'POST',
    body: data,
    query: {
      fnc: 'signUp',
      cl: source,
    },
  });
};

export const activateUserAccount = (userId: string, activationCode: string) => {
  return $cpFetch<ActivatedAccountResponse>(authPaths.activateAccount(userId), {
    method: 'POST',
    parseResponse: (txt) => (txt === '' ? '' : JSON.parse(txt)),
    body: {code: activationCode},
  });
};

export const sendVerificationEmail = (email: string) => {
  return $cpFetch<string>(authPaths.sendVerificationEmail(email), {
    parseResponse: (txt) => (txt === '' ? '' : JSON.parse(txt)),
  });
};

export const changePassword = (
  email: string,
  params: {
    confirmPassword: string
    oldPassword: string
    password: string
  },
) => {
  return $cpFetch(authPaths.user(email), {
    method: 'PATCH',
    body: {
      type: 'changePassword',
      props: params,
    },
  });
};

export const recoverPassword = (
  email: string,
  params: {
    confirmPassword: string
    password: string
    updateId: string
  },
) => {
  return $cpFetch(authPaths.user(email), {
    method: 'PATCH',
    body: {
      type: 'recoverPassword',
      props: params,
    },
  });
};

export const deleteUserAccount = (
  email: string,
  code: string,
  reason: string,
) => {
  return $cpFetch(authPaths.user(email), {
    method: 'DELETE',
    body: {
      code,
      reason,
    },
  });
};

export const requestPasswordReset = (email: string) => {
  return $cpFetch(authPaths.passwordReset(email), {
    method: 'POST',
  });
};

export const getFidInfo = () => {
  return $cpFetch<IFidAccountData>(authPaths.fidInfo(useCpUser().userEmail!));
};

export const fidLogin = (provider: string, credential: string) => {
  return $cpFetch(authPaths.fidLogin(provider), {
    method: 'POST',
    headers: {
      Referer: 'app',
    },
    body: {
      credential,
    },
  });
};

export const fidLogin2 = (params: FidLoginData) => {
  return $cpFetch<FidLoginResponsePayload>(
    authPaths.fidLogin(params.provider),
    {
      method: 'POST',
      body: params,
      headers: {
        // TODO: check this value.
        Referrer: 'web-mobile',
      },
    },
  );
};

export const fidAbort = (provider: string) => {
  return $cpFetch(authPaths.fidAbort(provider), {
    method: 'POST',
  });
};

export const deleteFid = (fidId: string) => {
  return $cpFetch(authPaths.deleteFid(useCpUser().userEmail!, fidId), {
    method: 'DELETE',
  });
};

export const deletePassword = () => {
  return $cpFetch(authPaths.user(useCpUser().userEmail!), {
    method: 'PATCH',
    body: {
      type: 'deletePassword',
      props: {},
    },
  });
};

export const logoutUser = (token: string) => {
  return $cpFetch(authPaths.logout(useCpUser().userEmail!), {
    method: 'POST',
    body: {token},
  });
};

/** Link provider account */
export const fidLink = () => {
  return $cpFetch<{ token: string }>(authPaths.fidLink, {
    method: 'POST',
    body: {
      password: '',
    },
  });
};
