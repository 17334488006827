<script setup lang="ts">
  import CpText from '~/components/cp/CpText/CpText.vue';
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import IsotypePrimary from '~/assets/img/isotype.png';
  import IsotypeGrey from '~/assets/img/isotype_grey.png';

  defineProps({
    active: {
      type: Boolean,
      required: true,
    },
    icon: {
     type: String,
     required: true,
    },
    caption: {
     type: String,
     required: true,
    },
    to: {
      type: String,
      required: true,
    },
  });

  const {em, e} = useCpBem('bottom-nav-menu');
</script>

<template>
    <NuxtLink :class="em('item',{'active': active})" :to="{ name: to }" :rel="to==='account'? 'nofollow': ''" :prefetch="false">
        <div v-show="active" class="active-line" />
        <CpIconStandard v-if="icon !== 'home'" :icon="icon" :type="active ? 'primary' : 'neutral'" />
        <div v-else :class="e('logo')">
            <v-img v-if="active" :src="IsotypePrimary" cover :alt="caption" />
            <v-img v-else :src="IsotypeGrey" cover :alt="caption" />
        </div>
        <CpText variant="caption-regular" :type="active ? 'primary':'neutral'">
            {{ caption }}
        </CpText>
    </NuxtLink>
</template>

<style scoped lang="scss">
  .bottom-nav-menu{
    &__item {
      display: flex;
      flex-direction: column;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 68px;
      color: var(--color-neutral);
      margin: auto;

      &--active {
        color: var(--color-primary);
      }
    }
    &__logo {
      height: 24px;
      width: 16px;
      margin-bottom: 2px;
    }
  }

  .active-line {
    position: absolute;
    top: 0;
    width: 24px;
    height: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: var(--color-primary);
  }

</style>
