import {defineStore} from 'pinia';
import {STORE_KEYS} from '~/stores/storeKeys';
import type {ParseUrlResponse} from '~/types';

interface IRouterStore {
  firstPass: boolean
  firstPassClient: boolean
  historyChanged: boolean
  forceAmbiguous: boolean
  unknownRoute: boolean
  seoUrlCache: Record<string, ParseUrlResponse|null>
  tradeQueryParams: null | object
}

export const useRouterStore = defineStore(STORE_KEYS.Router, {
  state: (): IRouterStore => ({
    firstPass: true,
    firstPassClient: true,
    historyChanged: false,
    forceAmbiguous: false,
    unknownRoute: false,
    seoUrlCache: {},
    tradeQueryParams: null,
  }),
  actions: {},
  getters: {},
});
