<template>
    <i v-if="icon" class="cp-custom-icons" v-html="icon" />
</template>

<script setup lang="ts">
import {onMounted, ref} from 'vue';

const props = defineProps<{
    name?: string
    width?: string | number
    height?: string | number
  }>();

const icon = ref();

onMounted(async () => {
  // Auto-load icons
  const icons = Object.fromEntries(
    Object.entries(import.meta.glob('~/assets/icons/*.svg', {as: 'raw'})).map(([key, value]) => {
      const filename = key.split('/').pop()!.split('.').shift();
      return [filename, value];
    }),
  );
    // Lazily load the icon
    icon.value = props.name && (await icons?.[props.name]?.());
});
</script>

<style scoped lang="scss">
  .cp-custom-icons {
    display: block;
    height: 100%;
    width: 100%;
  }
</style>
