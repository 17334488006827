import {defineStore} from 'pinia';
import {STORE_KEYS} from '~/stores/storeKeys';
import {useAllBrands} from '~/composables/queries/brands';
import type {IBrand} from '~/utils/services/api/brands/brands';
import type {IRadioButtonItem} from '~/types';

interface IFilter extends IRadioButtonItem<string> {
    index: number;
    featured?: boolean;
    firstLetter?: string;
    lastLetter?: string;
}

interface IBrandsData {
    title: string
    brands: IBrand[]
}

type Filter = {
    index: number;
    label: string;
    total: number;
    featured?: boolean;
    firstLetter?: string;
    lastLetter?: string;
}

type LetterGroup = { letter: string; total: string | number }

type filter = 'Marcas destacadas'|'A-C'|'D-F'|'G-I'|'J-L'|'M-O'|'P-R'|'S-U'|'V-X'|'Y-Z'|'0-9'

interface IBrandsStore {
    availableFilters: IFilter[];
    searchValue: string;
    filters?: any;
    data: IBrandsData[];
    selectedFilter: filter | null;
    filterElements: filter[];
    filterElementsData: IRadioButtonItem<string>[];
    loading: boolean;
    total: number;
    totalCategory: number;
}

const FEATURED_BRANDS_FILTER = {
    featured: true,
    // Default length from response
    total: 21,
    label: 'Marcas destacadas',
    index: 0,
};

export const useBrandsStore = defineStore(STORE_KEYS.Brands, {
    state: (): IBrandsStore => ({
        availableFilters: [],
        searchValue: 'acer',
        filters: {},
        data: [],
        selectedFilter: 'Marcas destacadas',
        filterElements: ['Marcas destacadas', 'A-C', 'D-F', 'G-I', 'J-L', 'M-O', 'P-R', 'S-U', 'V-X', 'Y-Z', '0-9'],
        filterElementsData: [],
        loading: false,
        total: 0,
        totalCategory: 0,
    }),
    actions: {
        async init() {
          this.selectedFilter = 'Marcas destacadas';
          this.filters = {featured: true};
          await this.applyFilters();
        },
        async applyFilters() {
            this.loading = true;
            if (this.selectedFilter !== null) {
                this.searchValue = '';
                let index = this.filterElements.findIndex((a: filter) => a === this.selectedFilter);
                if (index === this.filterElements.length) {
                    index = 1;
                }
                if (index !== 0) {
                    this.filters = {
                        firstLetter: this.selectedFilter.split('-')[0],
                        lastLetter: this.selectedFilter.split('-')[1],
                    };
                } else {
                    this.filters = {
                        featured: true,
                    };
                }
            } else {
                this.filters = {
                    search: this.searchValue,
                };
            }
            const response = await useAllBrands(this.filters);
            if (response.data.value) {
                this.data = this.mapResponse(response.data.value.data);
                this.filterElementsData = generateFilters(response.data.value?.meta.letterTotals as LetterGroup[])
                    .map((a: Filter) => {
                        return {
                            label: a.label,
                            index: a.index,
                            quantity: a.total,
                            value: a.label,
                        };
                    });

                if (this.selectedFilter !== null) {
                    this.total = this.totalCategory = response.data.value.data.length;
                } else {
                    this.total = response.data.value.data.length;
                    this.totalCategory = this.filterElementsData
                        .reduce((a, b) => a + (b.quantity ?? 0), 0);
                }
            } else {
                this.data = [];
            }

            this.loading = false;
        },
        async nextPage() {
            let index = this.filterElements.findIndex((a: filter) => a === this.selectedFilter) + 1;
            if (index === this.filterElements.length) {
                index = 1;
            }
            this.selectedFilter = this.filterElements[index];
            this.data = [];
            await this.applyFilters();
        },
        mapResponse(response: IBrand[]) : IBrandsData[] {
            if (this.filters.featured) {
                return [{title: 'Marcas destacadas', brands: response}];
            }
            if (this.filters.search) {
                return [{title: 'Resultados de la búsqueda', brands: response}];
            }
            const brandsByLetter: Record<string, IBrand[]> = {};
            response.forEach((brand) => {
                const firstLetter = brand.title.charAt(0).toUpperCase();
                if (firstLetter !== '') {
                    if (!brandsByLetter[firstLetter]) {
                        brandsByLetter[firstLetter] = [];
                    }
                    brandsByLetter[firstLetter].push(brand);
                }
            });
            return Object.entries(brandsByLetter).map(([letter, brands]) => ({
                title: letter,
                brands,
            }));
        },
    },
    getters: {
        getSearchValue(state): string {
            return state.searchValue;
        },
        getAvailableFilters(state) : IFilter[] {
            return state.availableFilters;
        },
        getNextPageLabel(state): string {
           let index = state.filterElements.findIndex((a: filter) => a === this.selectedFilter) + 1;
            if (index === state.filterElements.length) {
                index = 1;
            }
           return `Ver marcas de "${state.filterElements[index]}"`;
        },
    },
});

const groupLetterFilters = (arr: LetterGroup[]): Filter[] => {
    const letterGroups = [];
    let filterIndex = 1;
    for (let i = 0; i <= arr.length; i += 3) {
        const firstLetter = arr[i].letter;
        const auxObj: Filter = {
            firstLetter,
            lastLetter: '',
            total: 0,
            label: '',
            index: filterIndex,
        };
        if (i + 3 > arr.length) {
            auxObj.lastLetter = arr[i + 1].letter;
            auxObj.total = Number(arr[i].total) + Number(arr[i + 1].total);
        } else {
            auxObj.lastLetter = arr[i + 2].letter;
            auxObj.total =
                Number(arr[i].total) +
                Number(arr[i + 1].total) +
                Number(arr[i + 2].total);
        }
        auxObj.label = `${firstLetter}-${auxObj.lastLetter}`;
        letterGroups.push(auxObj);
        filterIndex++;
    }
    return letterGroups;
};

const filterLetters = (letterTotals: LetterGroup[]) => {
    const letterArray: LetterGroup[] = [];
    letterTotals.forEach((letterObj: LetterGroup) => {
        if (/[a-zA-Z]/.test(letterObj.letter)) {
            letterArray.push(letterObj);
        }
    });
    return letterArray;
};

const filterNotLetters = (letterTotals: LetterGroup[], index: number) => {
    /*
    This filter label is defined by Design Team. We do not need to generate it based on available brand names
     */
    const numbersFilter: Filter = {
        firstLetter: '0',
        lastLetter: '9',
        total: 0,
        label: '0-9',
        index,
    };

    letterTotals.forEach((letterObj: LetterGroup) => {
        if (!/[a-zA-Z]/.test(letterObj.letter)) {
            numbersFilter.total += Number(letterObj.total);
        }
    });
    return numbersFilter;
};

export const generateFilters = (letterTotals: LetterGroup[]): Filter[] => {
    const availableFilters = [];
    availableFilters.push(FEATURED_BRANDS_FILTER);
    const onlyLetters = filterLetters(letterTotals);
    availableFilters.push(...groupLetterFilters(onlyLetters));
    const onlyNotLetters = filterNotLetters(
        letterTotals,
        availableFilters.length,
    );
    availableFilters.push(onlyNotLetters);
    return availableFilters;
};
