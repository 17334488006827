<script setup lang="ts">
    import useCpBem from '~/composables/useCpBem';
    import CpText from '~/components/cp/CpText/CpText.vue';

    withDefaults(defineProps<{
        number?: number,
    }>(), {
        number: 0,
    });

    const {b} = useCpBem('cp-cart-counter');
</script>

<template>
    <div :class="b">
        <CpText variant="caption-bold" type="white">
            {{ number }}
        </CpText>
    </div>
</template>

<style lang="scss" scoped>
    .cp-cart-counter {
        background-color: var(--color-secondary);
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: $cp-gutter-small;
        width: $cp-gutter;
        height: $cp-gutter;
    }
</style>
