import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';
import type {ICarePackProduct, IMyWarrantyArticle} from '~/types';
import type {newWarrantyPayload} from '~/utils/services/api/account/warranties';

export interface IWarrantiesStore {
  serialNumber: string
  brand: string
  productToActivate: null | ICarePackProduct
  warrantyToManage: null | IMyWarrantyArticle
  activationData: null | newWarrantyPayload
  orderNumber: string
  hpElegibleProducts: Array<ICarePackProduct>
  selectedWarrantyInfoId: string
}

export const useWarrantiesStore = defineStore(STORE_KEYS.Warranties, {
    state: (): IWarrantiesStore => ({
        serialNumber: '',
        brand: '',
        productToActivate: null,
        warrantyToManage: null,
        activationData: null,
        orderNumber: '',
        hpElegibleProducts: [],
        selectedWarrantyInfoId: '',
    }),
    actions: {
        setSerialNumber(sn: string) {
 this.serialNumber = sn;
},
        setProductToActivate(product: ICarePackProduct) {
 this.productToActivate = product;
},
        setWarrantyToManage(article: IMyWarrantyArticle) {
 this.warrantyToManage = article;
},
        setBrand(brand: string | undefined) {
 if (brand) {
 this.brand = brand;
}
},
        setActivationData(data: newWarrantyPayload) {
 this.activationData = data;
},
        setOrderNumber(number: string | undefined) {
 if (number) {
 this.orderNumber = number;
}
},
        setHpElegibleProducts(data: Array<ICarePackProduct>) {
 this.hpElegibleProducts = data;
},
        setSelectedWarrantyInfoId(data: string) {
 this.selectedWarrantyInfoId = data;
},
    },
    getters: {
        getSerialNumber: (state) => state.serialNumber,
        getProductToActivate: (state) => state.productToActivate,
        getBrand: (state) => state.brand,
        getWarrantyToManage: (state) => state.warrantyToManage,
        getActivationData: (state) => state.activationData,
        getOrderNumber: (state) => state.orderNumber,
        getHpElegibleProducts: (state) => state.hpElegibleProducts,
        getSelectedWarrantyInfoId: (state) => state.selectedWarrantyInfoId,
    },
});
