import {createVuetify} from 'vuetify';
import type {ThemeDefinition} from 'vuetify';
import {aliases, md} from 'vuetify/iconsets/md';

const cpMobile: ThemeDefinition = {
  dark: false,
  colors: {
    'background': '#FFFFFF',
    'surface': '#FFFFFF',
    'info': '#0066BB',
    'primary': '#0066BB',
    'primary-lighten-1': '#128ADF',
    'primary-lighten-2': '#EDF6FD',
    'primary-darken-1': '#00499C',
    'secondary': '#FF9900',
    'secondary-lighten-1': '#FFA826',
    'secondary-lighten-2': '#FFF7EB',
    'secondary-darken-1': '#EF6D00',
    'critical': '#FF0055',
    'critical-lighten-1': '#FF5F89',
    'critical-lighten-2': '#FFEBF0',
    'critical-darken-1': '#C5004F',
    'error': '#FF0055',
    'warning': '#FF9900',
    'warning-lighten-1': '#FDCF52',
    'warning-lighten-2': '#FFFAEB',
    'warning-darken-1': '#C99403',
    'success': '#00CC99',
    'success-lighten-1': '#00CC99',
    'success-lighten-2': '#EFFBF6',
    'success-darken-1': '#008048',
    'mkt': '#FF8D0A',
    'mkt-lighten-1': '#FFA826',
    'mkt-lighten-2': '#FFF7EB',
    'mkt-darken-1': '#EF6D00',
    'neutral': '#A0A0A0',
    'neutral-lighten-1': '#CCCCCC',
    'neutral-lighten-2': '#EEEEEE',
    'neutral-darken-1': '#333333',
  },
};

export default defineNuxtPlugin({
  name: 'vuetify',
  parallel: true,
  env: {
    islands: false,
  },
  setup(nuxtApp) {
    nuxtApp.vueApp.use(createVuetify({
      ssr: true,
      icons: {
        defaultSet: 'md',
        aliases,
        sets: {
          md,
        },
      },
      theme: {
        defaultTheme: 'cpMobile',
        themes: {cpMobile},
        variations: {
          colors: ['primary', 'secondary', 'critial', 'warning', 'success', 'mkt', 'neutral'],
          lighten: 2,
          darken: 1,
        },
      },
    }));
  },
});
