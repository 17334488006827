import type {RouteLocationRaw} from 'vue-router';
import type {IProductDetails} from './services/api';
import {WARRANTY_TYPES} from '~/utils';
import type {ICpArticle} from '~/types';
import Cyberpuerta from '~/assets/img/cyberpuerta.svg';
import type {IAfPdp} from '~/types/afTypes';

export const generatePdpJsonLd = (pdp: IProductDetails | null): string => {
  if (!pdp?.article) {
    return '';
  }
  try {
    const isWarranty = Object.values(WARRANTY_TYPES).includes(pdp.article?.type ?? '');
    const date = new Date();
    date.setDate(date.getDate() + 2);
    const dateFormatted = date.toISOString().split('T')[0];

    return JSON.stringify(
      {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        'name': pdp.article.title || pdp.article.sku,
        'image': pdp.article.pictures?.length ? pdp.article.pictures : Cyberpuerta,
        'description': pdp.description?.substring(0, 5000),
        'sku': pdp.article.sku?.replaceAll(' ', '_'),
        'aggregateRating':
          (pdp.article.rating.total as number) > 0 ?
            {
              '@type': 'AggregateRating',
              'ratingValue': pdp.article.rating.global,
              'ratingCount': pdp.article.rating.total,
              'reviewCount': pdp.article.rating.total,
            } :
            undefined,
        'brand': pdp.article.brand.title ?
          {
            '@type': 'Brand',
            'name': pdp.article.brand?.title,
            'logo': pdp.article.brand?.image,
            'url': pdp.article.brand?.link,
          } :
          {},
        'category': pdp?.breadCrumbs
          .slice(0, -1)
          .map((cat) => cat.title)
          .join('/'),
        'offers': pdp.article.eol ?
          {
            '@type': 'Offer',
            'availability': 'Discontinued',
            'priceCurrency': 'MXN',
            'price': pdp.article.price,
            'itemCondition': 'NewCondition',
          } :
          {
            '@type': 'Offer',
            'url': pdp.article.link,
            'availability': pdp.article.notBuyable || pdp.article.stockStatus === -1 ? 'OutOfStock' : 'InStock',
            'priceCurrency': 'MXN',
            'priceValidUntil': dateFormatted,
            'price': pdp.article.price,
            'itemCondition': 'NewCondition',
            'shippingDetails': {
              '@type': 'OfferShippingDetails',
              'shippingRate': {
                '@type': 'MonetaryAmount',
                'value': pdp.article.freeShipping ? 0 : pdp.article.shipping,
                'currency': 'MXN',
              },
              'deliveryTime': {
                '@type': 'ShippingDeliveryTime',
                'handlingTime': {
                  '@type': 'QuantitativeValue',
                  'minValue': 0,
                  'maxValue': pdp.article.isEsd ? 0 : 1,
                  'unitCode': 'DAY',
                },
                'transitTime': {
                  '@type': 'QuantitativeValue',
                  'minValue': pdp.article.isEsd ? 0 : 1,
                  'maxValue': pdp.article.isEsd ? 0 : 4,
                  'unitCode': 'DAY',
                },
              },
              'shippingDestination': [
                {
                  '@type': 'DefinedRegion',
                  'addressCountry': 'MX',
                },
              ],
            },
            'hasMerchantReturnPolicy': {
              '@type': 'MerchantReturnPolicy',
              'applicableCountry': 'MX',
              'returnPolicyCategory': (pdp.article.isEsd || isWarranty) ?
                'https://schema.org/MerchantReturnNotPermitted' :
                'https://schema.org/MerchantReturnFiniteReturnWindow',
              'merchantReturnDays': 30,
              'returnShippingFeesAmount': {
                '@type': 'MonetaryAmount',
                'value': 0,
                'currency': 'MXN',
              },
              'returnMethod': 'https://schema.org/ReturnByMail',
              'returnFees': 'https://schema.org/FreeReturn',
            },
          },
        'isSimilarTo': pdp.variants ? pdp.variants.map((variant) => {
          return {
            '@type': 'Product',
            'url': variant.url,
            'description': variant.description,
            'image': variant.image,
          };
        }) : [],
      },
      null,
      '',
    );
  } catch (e) {
    return '';
  }
};

export const generateBreadcrumbsJsonLd = (pdp: IProductDetails | null): string => {
  if (!pdp?.breadCrumbs) {
    return '';
  }
  try {
    return JSON.stringify(
      {
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        'itemListElement': pdp.breadCrumbs.map((item, index) => ({
          '@type': 'ListItem',
          // We must start with 1, see: https://developers.google.com/search/docs/appearance/structured-data/breadcrumb
          'position': index + 1,
          'item': {
            '@id': item.url,
            'name': item.title,
          },
        })),
      },
      null,
      '',
    );
  } catch (e) {
    return '';
  }
};

export const generateHome1JsonLd = () => {
  try {
    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'Organization',
      'name': 'Cyberpuerta, S.A. de C.V.',
      'url': 'https://www.cyberpuerta.mx/',
      'logo': 'https://www.cyberpuerta.mx/out/cyberpuertaV5/img/logo2.png',
      'contactPoint': [
        {
          '@type': 'ContactPoint',
          'telephone': '+52-33 4737 1360',
          'contactType': 'customer service',
        },
        {
          '@type': 'ContactPoint',
          'telephone': '+52-55 4737 1360',
          'contactType': 'customer service',
        },
        {
          '@type': 'ContactPoint',
          'telephone': '+52-81 4737 1360',
          'contactType': 'customer service',
        },
      ],
      'sameAs': ['http://www.facebook.com/Cyberpuerta', 'http://x.com/CyberPuerta'],
    });
  } catch (e) {
    return '';
  }
};
export const generateHome2JsonLd = () => {
  try {
    return JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      'url': 'https://www.cyberpuerta.mx/',
      'potentialAction': {
        '@type': 'SearchAction',
        'target': 'https://www.cyberpuerta.mx/index.php?cl=search&searchparam={search_term_string}',
        'query-input': 'required name=search_term_string',
      },
    });
  } catch (e) {
    return '';
  }
};

interface CatalogJsonLdOptions {
  canonical: string,
  title: string,
  breadcrumb: BreadCrumbItem[],
  articles: ICpArticle[],
  catalogTotal: number,
  maxArticles: number
}


export const generateCatalogJsonLd = ({
  canonical,
  title,
  breadcrumb,
  articles,
  catalogTotal,
  maxArticles,
}: CatalogJsonLdOptions) => {
  const current = canonical;
  const name = title;
  try {
    const router = useRouter();
    const url = new URL(current);
    const breadcrumbListJsonLd = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      'itemListElement': breadcrumb.map((item, index) => {
        const itemValue = (item.value && (typeof item.value !== 'string')) ?
          url.origin + (router.resolve(item.value as RouteLocationRaw).path) :
          (typeof item.value === 'string') ? item.value : current;
        return {
          '@type': 'ListItem',
          'position': (index + 1),
          'item': {
            '@id': itemValue,
            'name': item.label,
          },
        };
      }),
    };
    const ItemListJsonLd = {
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      'numberOfItems': catalogTotal,
      'name': name,
      'url': current,
      'itemListElement': articles.filter((a) => !!a.pictures).slice(0, maxArticles).map((article, index) => {
        const props: { aggregateRating?: unknown, brand?: unknown, category?: string, offers?: unknown } = {};
        const price = article.price.toString().replace('$', '').replace(',', '');
        const isWarranty = Object.values(WARRANTY_TYPES).includes(article.type ?? '');

        if (article.rating && article.rating.global) {
          props.aggregateRating = {
            '@type': 'AggregateRating',
            'ratingValue': article.rating.global ?? 0,
            'ratingCount': article.rating.total ?? 0,
            'reviewCount': article.rating.total ?? 0,
          };
        }

        if (article.brand) {
          props.brand = {
            '@type': 'Brand',
            'name': article.brand?.title,
            'logo': article.brand?.image,
            'url': article.brand?.link,
          };
        }

        if (article.category) {
          props.category = article.category.title;
        }

        if (article.eol) {
          props.offers = {
            '@type': 'Offer',
            'availability': 'Discontinued',
            'priceCurrency': 'MXN',
            'price': price,
          };
        } else {
          const date = new Date();
          date.setDate(date.getDate() + 2);
          const dateFormatted = date.toISOString().split('T')[0];
          props.offers = {
            '@type': 'Offer',
            'url': article.link,
            'availability': article.notBuyable || article.stockStatus === -1 ? 'OutOfStock' : 'InStock',
            'priceCurrency': 'MXN',
            'priceValidUntil': dateFormatted,
            'price': price,
            'itemCondition': 'NewCondition',
            'shippingDetails': {
              '@type': 'OfferShippingDetails',
              'shippingRate': {
                '@type': 'MonetaryAmount',
                'value': article.freeShipping ? 0 : article.shipping,
                'currency': 'MXN',
              },
              'deliveryTime': {
                '@type': 'ShippingDeliveryTime',
                'handlingTime': {
                  '@type': 'QuantitativeValue',
                  'minValue': 0,
                  'maxValue': article.isEsd ? 0 : 1,
                  'unitCode': 'DAY',
                },
                'transitTime': {
                  '@type': 'QuantitativeValue',
                  'minValue': article.isEsd ? 0 : 1,
                  'maxValue': article.isEsd ? 0 : 4,
                  'unitCode': 'DAY',
                },
              },
              'shippingDestination': [
                {
                  '@type': 'DefinedRegion',
                  'addressCountry': 'MX',
                },
              ],
            },
            'hasMerchantReturnPolicy': {
              '@type': 'MerchantReturnPolicy',
              'applicableCountry': 'MX',
              'returnPolicyCategory': (article.isEsd || isWarranty) ?
                'https://schema.org/MerchantReturnNotPermitted' :
                'https://schema.org/MerchantReturnFiniteReturnWindow',
              'merchantReturnDays': 30,
              'returnShippingFeesAmount': {
                '@type': 'MonetaryAmount',
                'value': 0,
                'currency': 'MXN',
              },
              'returnMethod': 'https://schema.org/ReturnByMail',
              'returnFees': 'https://schema.org/FreeReturn',
            },
          };
        }

        return {
          '@type': 'ListItem',
          'position': (index + 1),
          'item': {
            '@type': 'Product',
            'image': article.pictures,
            'url': article.link,
            'name': ( article.title ?? article.sku ?? '').substring(0, 150),
            'description': article.title,
            'sku': article.sku?.replaceAll(' ', '_'),
            ...props,
          },
        };
      }),
    };
    return JSON.stringify([
      breadcrumbListJsonLd,
      ItemListJsonLd,
    ]);
  } catch (e) {
    return '';
  }
};

export const generateAFPDPJsonLd = (afData: IAfPdp, description: string) => {
  try {
    const pdp = afData.included[0].attributes;
    const date = new Date();
    date.setDate(date.getDate() + 2);
    const dateFormatted = date.toISOString().split('T')[0];
    // TODO: Update JSON when back is ready
    return JSON.stringify(
      {
        '@context': 'https://schema.org/',
        '@type': 'Product',
        'name': pdp.name || pdp.sku,
        'image': [pdp.image],
        'description': description,
        'sku': pdp.sku,
        'aggregateRating':
          pdp.review > 0 ?
            {
              '@type': 'AggregateRating',
              'ratingValue': pdp.rate,
              'ratingCount': pdp.review,
              'reviewCount': pdp.review,
            } :
            undefined,
        'brand': {
            '@type': 'Brand',
            'name': 'ALCHEMY FORCE',
            'logo': 'https://cdn.cyberpuerta.mx/storage/brands/AFLogoBG2.png',
            'url': 'https://www.cyberpuerta.mx/Por-Marca/ALCHEMY-FORCE/',
        },
        'category': "Cómputo/PC's de Escritorio",
        'offers': {
            '@type': 'Offer',
            'url': `https://www.cyberpuerta.mx/${pdp.link}`,
            'availability': pdp.stock <= 0 ? 'OutOfStock' : 'InStock',
            'priceCurrency': 'MXN',
            'priceValidUntil': dateFormatted,
            'price': pdp.price,
            'itemCondition': 'NewCondition',
            'shippingDetails': {
              '@type': 'OfferShippingDetails',
              'shippingRate': {
                '@type': 'MonetaryAmount',
                'value': 0,
                'currency': 'MXN',
              },
              'deliveryTime': {
                '@type': 'ShippingDeliveryTime',
                'handlingTime': {
                  '@type': 'QuantitativeValue',
                  'minValue': 0,
                  'maxValue': 1,
                  'unitCode': 'DAY',
                },
                'transitTime': {
                  '@type': 'QuantitativeValue',
                  'minValue': 1,
                  'maxValue': 5,
                  'unitCode': 'DAY',
                },
              },
              'shippingDestination': [
                {
                  '@type': 'DefinedRegion',
                  'addressCountry': 'MX',
                },
              ],
            },
            'hasMerchantReturnPolicy': {
              '@type': 'MerchantReturnPolicy',
              'applicableCountry': 'MX',
              'returnPolicyCategory': 'https://schema.org/MerchantReturnFiniteReturnWindow',
              'merchantReturnDays': 30,
              'returnShippingFeesAmount': {
                '@type': 'MonetaryAmount',
                'value': 0,
                'currency': 'MXN',
              },
              'returnMethod': 'https://schema.org/ReturnByMail',
              'returnFees': 'https://schema.org/FreeReturn',
            },
          },
      },
      null,
      '',
    );
  } catch (error) {
    console.log(error);
    
    return '';
  }
}