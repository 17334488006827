import {QUERY_KEYS} from '~/utils/services/keys';
import {getBrands} from '~/utils/services/api';
import type {IBrandsFilters} from '~/utils/services/api/brands/brands';
import {getAllBrands, getHomeBrands} from '~/utils/services/api/brands/brands';

export const useBrand = (id: string) => {
    return useAsyncData(QUERY_KEYS.brands.home, () => getBrands());
};

export const useAllBrands = (filters: IBrandsFilters) => {
    return useAsyncData(
        QUERY_KEYS.brands.all(filters.firstLetter ?? '', filters.search),
        () => getAllBrands(filters),
    );
};

export const useGetHomeBrands = () => {
    return useCachedAsyncData(QUERY_KEYS.brands.home, () => getHomeBrands(), useClientCache(), {cacheKey: QUERY_KEYS.brands.home, cacheTags: [QUERY_KEYS.brands.home], cacheExpires: 3600 * 12});
};
