<script setup lang="ts">
  import {computed} from 'vue';
  import useCpBem from '~/composables/useCpBem';
  import type {ICpTypeWithModifier} from '~/composables/useCpType';
  import {toCssVariable as toCss} from '~/composables/useCpType';
  import CpCustomIcons from '~/components/cp/CpIcon/CpIconStandard/CpCustomIcons.vue';

  const toCssVariable = toCss;
  const props = withDefaults(defineProps<{
              /** Icon name */
              icon: string
              kind?: 'small'|'standard'|'large'|'extra-large'|'extra-extra-large'
              type?: ICpTypeWithModifier
              activeColor?: ICpTypeWithModifier
              // Added the next props to AF button
              hollow?: boolean
              backgroundHollow?: ICpTypeWithModifier
          }>(), {
    kind: 'standard',
    type: 'neutral-dark',
    activeColor: undefined,
    backgroundHollow: 'secondary',
  });

  // Add the icon in assets/icons (svg), after adding it here
  const customIcons: any = {magic_button: true, heart_plus: true, whatsapp: true, timer_outline: true, deployed_code_history: true, new_product: true};

  const isGradient = computed(() => (props.type as string).includes('gradient'));

  const activeColorCSS = computed(() => {
    return toCssVariable(props.activeColor ?? props.type);
  });

  const color = computed(() => props.hollow ? toCssVariable(props.backgroundHollow) : toCssVariable(props.type));

  const iconImg = computed(() => {
    return {
      icon: customIcons[props.icon] == null,
    };
  });
  const customIconSize = computed(() => {
    switch (props.kind) {
    case 'standard':
      return 0.48;
    case 'small':
      return 0.32;
    case 'large':
      return 0.64;
    default:
      return 0.94;
    }
  });

  const iconSize = {
    'small': 16,
    'standard': 24,
    'large': 32,
    'extra-large': 48,
    'extra-extra-large': 64,
  };

  const {bm, em, e} = useCpBem('cp-icon-standard');
</script>

<template>
    <div :class="bm({[kind]: true})" data-testid="icon-standard">
        <v-icon
            v-if="iconImg.icon"
            :size="iconSize[kind]"
            :class="[em(`font`, {isGradient, hollow}), 'material-icons-round']"
            :icon="icon"
        />
        <div v-else :class="em(`custom-icon`,{isGradient})">
            <CpCustomIcons :name="props.icon" :class="e`img`" />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .cp-icon-standard {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        font-size: 16px;
        &--extra-extra-large {
            width: 64px;
            height: 64px;
            font-size: 51px;
        }
        &--extra-large {
            width: 48px;
            height: 48px;
            font-size: 31px;
        }
        &--large {
            width: 32px;
            height: 32px;
            font-size: 21px;
        }
        &--small {
            font-size: 10.5px;
            width: 16px;
            height: 16px;
        }

        &__font{
            color: v-bind(color);
            width: 100%;
            height: 100%;
            &--is-gradient{
                background: v-bind("toCssVariable(props.type)");
                background-clip: text;
                -webkit-background-clip: text;
                color: transparent;
            }
            &--hollow {
              -webkit-text-stroke: 1px v-bind("toCssVariable(props.type)");
              text-stroke: 1px v-bind("toCssVariable(props.type)");
            }

            &:active {
              color: v-bind("activeColorCSS");
            }
        }

        &__custom-icon {
          scale: v-bind(customIconSize);
          fill: v-bind("toCssVariable(props.type)");
          margin-top: 2px;

          &--is-gradient{
            background: v-bind("toCssVariable(props.type)");
          }
        }
    }
</style>
