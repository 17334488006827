import type {IReviewsOptions} from '~/types';

export const QUERY_KEYS = {
    account: {
        user: (id: string) => `user-${id}` as const,
        questions: 'questions' as const,
        createQuestion: 'create-question' as const,
        nowReviewedProducts: 'now-reviewed-products' as const,
        answers: 'answers' as const,
        reviews: 'reviews' as const,
        productHistory: 'product-history' as const,
        addProductHistory: 'add-product-history' as const,
        deleteProductHistory: 'delete-product-history' as const,
        publicProfile: 'public-profile' as const,
        wishlists: 'wishlists' as const,
        createWishlist: 'create-wishlists' as const,
        updateWishlist: 'update-wishlists' as const,
        deleteWishlist: 'delete-wishlist' as const,
        addresses: 'addresses' as const,
        createAddresses: 'create-addresses' as const,
        updateAddresses: 'update-addresses' as const,
        deleteAddresses: 'delete-addresses' as const,
        billingData: 'billing-data' as const,
        createBillingData: 'create-billing-data' as const,
        updateBillingData: 'update-billing-data' as const,
        deleteBillingData: 'delete-billing-data' as const,
        selectBillingData: 'select-billing-data' as const,
        paypalBillingAgreements: 'paypal-billing-agreement' as const,
        deletePaypalBillingAgreements: 'delete-paypal-billing-agreement' as const,
        orders: (id: string | undefined = undefined) => id ? `order-${id}` : 'orders' as const,
        orderTracking: (id: string | undefined = undefined) => id ? `tracking-${id}` : 'tracking' as const,
        ordersConfirmationMail: 'orders-confimation-mail' as const,
        ordersPreviewEmail: 'orders-preview-email' as const,
        cancelOrder: 'cancel-orders' as const,
        sendEsdCode: 'send-esd-code' as const,
        downloads: 'downloads' as const,
        createOrder: 'create-order' as const,
        address: (id: string) => `address-${id}` as const,
        deleteVaultCard: (id: string) => `delete-vault-card-${id}` as const,
        paymentBlock: 'payment-block' as const,
        ordersOverdueProducts: 'orders-overdue-products' as const,
        proportionalDeliveryCost: (orderId: string | undefined) => `proportional-delivery-cost-${orderId}` as const,
        returnCases: (orderId: string | undefined) => `return-cases-${orderId}` as const,
    },
    article: {
        all: 'articles' as const,
        details: (id: string) => `article/${id}/details` as const,
        detailsNewReview: (id: string) => `article/${id}/write-new-review` as const,
        reviews: (id: string, sorted: string, search: string) => `${QUERY_KEYS.article.details(id)}/reviews/${sorted}/${search}`,
        questions: (id: string, search: string) => `${QUERY_KEYS.article.details(id)}/questions/${search}` as const,
        wishlists: (id: string) => `${QUERY_KEYS.article.details(id)}/wishlist` as const,
    },
    brands: {
        all: (firstLetter: string, search = '') => `all/brands/${firstLetter}/${search}` as const,
        home: 'brands' as const,
    },
    environment: 'env' as const,
    home: 'home' as const,
    banners: (name: string) => `/utils/banner-find/${name}` as const,
    catalog: {
        single: (id: string) => `article/${id}` as const,
        catalog: 'catalog' as const,
        results: '/catalog/filter' as const,
        articles: (type: string) => `catalog-articles-${type}` as const,
        brands: 'brands' as const,
        recommendations: (slot: string) => `recommendations-${slot}` as const,
        lastSeen: 'last-seen' as const,
        search: 'last-seen' as const,
    },
    categories: {
        flat: 'flatCategories' as const,
        tree: 'tree' as const,
    },
    utils: {
        eta: (productId: string) => `eta-${productId}` as const,
        flat: 'flatCategories' as const,
        tree: 'categoryTree' as const,
        globalRank: 'globalRank' as const,
        billingByIDCIF: 'billing-by-id-cif' as const,
        billingUsageCodeById: 'billing-usage-code-by-id' as const,
        billingCertificate: 'billing-certificate' as const,
        useCms: 'useCms' as const,
        contentReport: 'content-report' as const,
        parseSeoUrl: 'parse-seo-url' as const,
        noStockSubscription: 'no-stock-subscription' as const,
        validateBankAccount: 'validate-bank-account' as const,
    },
    refunds: {
        availableSAF: 'ref-available-SAF' as const,
        bankAccounts: 'ref-bank-accounts' as const,
        history: 'ref-history' as const,
        addRefundBankAccount: 'ref-add-bank-account' as const,
        deleteRefundBankAccount: 'ref-delete-bank-account' as const,
        regenerateBankAccount: 'ref-regenerate-bank-account' as const,
        confirmBankAccount: 'ref-confirm-bank-account' as const,
        saveRefund: 'ref-save' as const,
    },
    validator: {
        address: 'address' as const,
        deleteAccount: 'delete-account' as const,
        verifyIdentity: 'verify-identity' as const,
    },
    auth: {
        fidInfo: 'fid' as const,
        fidLogin: 'fidLogin' as const,
        fidLoginAbort: 'fidLoginAbort' as const,
        deleteFid: 'deleteFid' as const,
        deletePassword: 'deletePassword' as const,
    },
    user: {
        basket: (id: string) => `user-${id}-basket` as const,
        basketAdd: (userId: string) => `user-${userId}-basket-add` as const,
        basketUpdated: (userId: string) => `user-${userId}-basket-update` as const,
    },
    basket: {
        savedForLaterArticles: 'saved-for-later-articles' as const,
        saveForLater: (id: string) => `save-for-later-${id}` as const,
        accessories: (userId: string, articleId: string) => `user-${userId}-basket-article-${articleId}` as const,
    },
    checkout: {
        paymentMethods: 'payment-methods' as const,
        addVoucher: 'add-voucher' as const,
        validatePaymentMethod: 'validate-payment-method' as const,
        transactionContext: 'paypal-transaction-context' as const,
        ppcpPaymentInfo: (id: string) => `ppcp-payment-info-${id}` as const,
    },
    deniedCarriers: 'denied-carriers' as const,
    warranties: {
        all: 'warranties' as const,
        carePackProduct: 'care-pack-product' as const,
        single: (id: string) => `warranty-${id}` as const,
        delete: (id: string) => `delete-warranty-${id}` as const,
        update: (id: string) => `update-warranty-${id}` as const,
        create: (id: string) => `create-warranty-data-${id}` as const,
        sendData: (orderId: string | undefined, brand: string | undefined) => `send-data-${orderId}-${brand}` as const,
        sendAIGMail: (orderId: string) => `aig-email-${orderId}`,
    },
    reviews: {
        create: 'create' as const,
        vote: 'vote' as const,
        myReviews: (userId: string, reviewOptions: IReviewsOptions) => `my-reviews-${userId}-${reviewOptions.search}-${reviewOptions.sortType}-${reviewOptions['page[number]']}` as const,
        rating: (productId: string) => `my-reviews/${productId}/rating` as const,
        myReview: (userId: string, articleId: string) => `mi-review-${userId}-${articleId}` as const,
    },
    // this is for /orders not /account/orders
    orders: {
        cancelExpiredArticles: (orderId: string | undefined) => `cancel-expired-articles-${orderId}` as const,
        regenerateWaybillByReturnCase: (orderId: string | undefined, returnCaseId: string | undefined) => `regenerate-waybill-order-${orderId}-rc-${returnCaseId}` as const,
        regenerateWaybillByOrder: (orderId: string | undefined) => `regenerate-waybill-order-${orderId}` as const,
        archiveReturnCase: (orderId: string | undefined, returnCaseId: string | undefined) => `archive-rc-${returnCaseId}-from-order-${orderId}` as const,
        createReturnCase: (articleId: string) => `create-rc-${articleId}` as const,
        updateReturnCase: (rmId: string | undefined) => `update-rc-${rmId}` as const,
        articlesPerWaybill: (orderId: string | undefined) => `waybills-${orderId}` as const,
        paymentInformation: (orderId: string | undefined) => `payment-information-${orderId}` as const,
    },
    paypal: {
        installmentsMSI: 'paypal-installments-msi' as const,
        paypalInstallments: 'paypal-installments' as const,
        paypalPaymentSettings: 'paypal-payment-settings' as const,
        paypalPaymentFraudnetInfo: 'paypal-payment-fraudnet-info' as const,
        paypalRememberedCards: 'paypal-remembered-cards' as const,
        paypalDeleteRememberedCard: (cardId: string) => `paypal-delete-remembered-card-${cardId}` as const,
        paypalCreateRememberedCard: (card: string) => `paypal-create-remembered-card-${card}` as const,
    },
    af: {
        etaCalculator: (articleId: string) => `af-eta-calculator-${articleId}` as const,
        getBrands: (articleId: string) => `get-brands-${articleId}` as const,
        getAf: (articleId: string) => `get-af-${articleId}` as const,
        installmentsMSI: (articleId: string) => `installments-msi-${articleId}` as const,
        detailsMSI: (articleId: string) => `details-msi-${articleId}` as const,
        configurations: (articleId: string) => `alchemy-force-${articleId}-conf` as const,
        attributes: (articleId: string) => `alchemy-force-${articleId}-att` as const,
        components: (articleId: string) => `alchemy-force-${articleId}-com` as const,
        getCrosselling: (articleId: string) => `get-crosselling-${articleId}}` as const,
        getVersions: (articleId: string) => `get-versions-${articleId}}` as const,
    },
};
