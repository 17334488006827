export const STORE_KEYS = {
    Auth: 'cp/auth',
    Router: 'cp/router',
    Details: 'cp/details',
    BannerManager: 'cp/banner-manager',
    CategoriesMenu: 'cp/categories-menu',
    Wishlist: 'cp/wishlist',
    Routes: 'cp/routes',
    Profile: 'cp/profile',
    RecentQueries: 'cp/recentQueries',
    orders: 'cp/orders',
    Brands: 'cp/brands',
    Checkout: 'cp/checkout',
    Warranties: 'cp/warranties',
    ParseSeoUrl: 'cp/parse-seo-url',
    Catalog: 'cp/catalog',
    ReturnsModule: 'cp/returns-module',
    CatalogAF: 'cp/catalog-af',
    UXNavigationStore: 'cp/ux-navigation',
  };
