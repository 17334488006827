export const useCpUser = () => {
  const {data} = useAuth();
  const redirectInfo = useState<{ routeName: string | null; params: any, query: any }>(
    'auth.redirectInfo',
    () => ({
      routeName: null,
      params: {},
      query: {},
    }),
  );

  const setRouteData = (route: string, params?: any, query?: any) => {
    redirectInfo.value.routeName = route;
    if (params) {
      redirectInfo.value.params = params;
    } else {
      redirectInfo.value.params = {};
    }
    if (query) {
      redirectInfo.value.query = query;
    } else {
      redirectInfo.value.query = {};
    }
  };

  const resetRouteData = () => {
    redirectInfo.value = {
      routeName: null,
      params: {},
      query: {},
    };
  };

  const actions = {
    setRouteData,
    resetRouteData,
  };

  // I validate against data.value?.user.id because data.value?.user may be {}, that equals to true in some scenarios.
  const user = computed(() => data.value?.user.id ? data.value.user : null);

  const getters = {
    user,
    // We can't use status === "authenticated" because that status may mean just having a simple session.
    isAuthenticated: computed(() => Boolean(data.value?.user.id)), // Boolean(data.value?.user.id),
    userId: user.value?.id,
    userEmail: user.value?.email,
    userEmailComputed: computed(() => Boolean(user.value?.email)),
    userName: computed(() => user.value?.nameAccount),
    hasAddress: Boolean(user.value?.zip),
    isConfirmed: computed(() => Boolean(user.value?.emailActivation)),
    redirectInfo,
  };

  return {
    ...actions,
    ...getters,
  };
};
