import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';
import type {IAccountPublicProfile} from '~/types';
import {imgPlaceHolderArray} from '~/utils';
import {useCpUser} from '~/composables/useCpUser';

interface IProfileStore {
    publicProfile: null | IAccountPublicProfile
}

export const useProfileStore = defineStore(STORE_KEYS.Profile, {
  state: (): IProfileStore => ({
    publicProfile: null,
  }),
  actions: {
    setProfile(profile: IAccountPublicProfile | null) {
      this.publicProfile = profile;
    },
  },
  getters: {
    getPublicProfile(state) {
      return state.publicProfile;
    },
    getAvatarImage() {
      const authStore = useCpUser();
      return authStore.user.value ? imgPlaceHolderArray.includes(authStore.user.value?.picture) ? undefined : authStore.user.value?.picture : undefined;
    },
  },
});
