<script lang="ts" setup>
  import useCpBem from '~/composables/useCpBem';

  defineProps<{
    icon: string
  }>();

  const {b, e} = useCpBem('cp-icon-empty-state');
</script>

<template>
    <div :class="b">
        <v-icon :class="e`icon`" :icon="icon" />
    </div>
</template>

<style scoped lang="scss">
    .cp-icon-empty-state {
        width: 96px;
        height: 96px;
        display: flex;
        justify-content: center;
        align-items: center;
        &__icon{
            font-size: 86px;
            color: var(--color-icon);
        }
    }
</style>
