/**
 * This function help us to get a unique key from a string
 * this can be used to hash an object after stringify
 * @example
 * hashCode(JSON.stringify({ test: 'hello' }))
 */
export function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
