import type {FetchContext, FetchOptions} from 'ofetch';
import {ofetch} from 'ofetch';
import type {UseFetchOptions} from '#app';
export interface FetchOptionsExt extends FetchOptions {
  withoutAuthorization?: boolean;
  removeWebMobileParam?: boolean;
  useMS?: boolean;
}

export interface FetchContextExt extends FetchContext {
  options: FetchOptionsExt
}

// useFetch with required configuration to talk to our API
export const useCpFetch = <T>(
  url: string,
  options: UseFetchOptions<T> = {},
) => {
  const config = useRuntimeConfig();
  options.baseURL = config.public.apiBase;
  options.parseResponse = JSON.parse;

  let token = null;
  if (useCookie('sid').value && useCookie('useCustomSession').value) {
    token = useCookie('sid').value;
  } else {
    const {data} = useAuth();
    token = data.value?.token;
  }
  if (token) {
    options.headers = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  options.query = {...options.query, webMobile: true};
  return useFetch(url, options);
};

export const $cpFetch = ofetch.create({
  // TODO: how to return this from ENV?
  baseURL: 'https://www.cyberpuerta.mx/api/v0.1',
  parseResponse: JSON.parse,
  onRequest: ({options}: FetchContextExt) => {
    const withoutAuthorization = options.withoutAuthorization;

    if (options.useMS) {
      const config = useRuntimeConfig();
      options.baseURL = config.public.msBaseURL as string;
    }

    try {
      /** This allow to use the same auth way as App (Stateful client)
      * @see https://app.clickup.com/t/2338706/IT-14666 */
      options.query = options.query || {};
      options.query.webMobile = true;

      if (options.removeWebMobileParam) {
        delete options.query.webMobile;
      }

      if (withoutAuthorization) {
        return;
      }

      let token = null;
      if (useCookie('sid').value && useCookie('useCustomSession').value) {
        token = useCookie('sid').value;
      } else {
        const {data} = useAuth();
        token = data.value?.token;
      }
      // console.log('onRequest', token)
      // console.log('onRequestHeader', options.headers, request)
      if (token) {
        options.headers = {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        };
      }
    } catch (e) {
      console.log(e);
    }
  },
  onResponseError: ({response, options}) => {
    const {signIn, data} = useAuth();
    const url = new URL(response.url);
    const method = url.pathname.split('/').pop();

    const errorCode = response._data.errors ? response._data.errors[0].code : 0;
    const statusCode = response.status;
    const isUserLogged = data.value?.user.id;

    const isCriticalRequest = options.retryStatusCodes?.includes(401);
    // Error Code 1 means that the endpoint requires a simple session authentication
    if (
      (isUserLogged || isCriticalRequest) &&
      (errorCode === '1' || statusCode === 401) && (method !== 'identity')
    ) {
      signIn('credentials', {action: 'rotate-token'});
    }
  },
});
