<script setup lang="ts">
  import useCpBem from '~/composables/useCpBem';
  import CpButtonNavMenu from '~/components/cp/CpButtonNavMenu/CpButtonNavMenu.vue';
  import type {MenuSelected} from '~/types';

    defineProps<{
        active: MenuSelected
    }>();

    const {b} = useCpBem('cp-bottom-nav-menu');
</script>

<template>
    <div :class="b">
        <CpButtonNavMenu
            icon="home"
            caption="Inicio"
            :active="active === 'home'"
            to="home"
            data-testid="bottom-menu-home"
        />
        <CpButtonNavMenu
            icon="view_module"
            caption="Categorías"
            :active="active === 'categories'"
            to="categories"
            data-testid="bottom-menu-categories"
        />
        <CpButtonNavMenu
            icon="person"
            caption="Mi cuenta"
            :active="active === 'account'"
            to="account"
            data-testid="bottom-menu-account"
        />
        <CpButtonNavMenu
            icon="menu"
            caption="Menu"
            :active="active === 'menu'"
            to="menu"
            data-testid="bottom-menu"
        />
    </div>
</template>

<style scoped lang="scss">
    .cp-bottom-nav-menu {
      background-color: var(--color-white);
        display: flex;
        width: 100%;
        justify-content: space-around;
        border-top: solid;
        border-top-width: 1px;
        border-top-color: var(--color-neutral-extra-light);
        z-index: 1;
    }
</style>
