import type {ICpRouteLocationNamedRaw, RouterName} from '~/utils/services/screens';
import {useRouterStore} from '~/stores';

const parseLink = (link: string) => {
    // URL.canParse(link)
    if (link.startsWith('http')) {
        return link;
    } else {
        return window.location.protocol + '//' + window.location.host + link;
    }
};

export const useCpNavigateToParams = (pageName?: RouterName, link?: string): ICpRouteLocationNamedRaw | string => {
    try {
        const store = useRouterStore();
        store.forceAmbiguous = true;
        let params: string[] | null = [];
        let query: any = {};
        if (pageName && !link) {
            return {name: pageName};
        } else if (link && pageName) {
            link = parseLink(link);
            const urlObject = new URL(link);
            params = urlObject.pathname.split('/').filter((a, i) => i !== 0);

            query = paramsToObject(urlObject.searchParams);

            return {
                name: pageName,
                params: params.length > 0 ? {link: params} : { },
                query,
            };
        } else if (link && !pageName) {
            // const store = useRouterStore()
            // store.historyChanged = true
            return link;
        }
    } catch {
        return link ?? '/';
    }

    return '/';
};

const paramsToObject = (entries: URLSearchParams) => {
    const result: any = {};
    for (const [key, value] of entries) {
        result[key] = value;
    }
    return result;
};
