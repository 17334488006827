<script setup lang="ts">
    import useCpBem from '~/composables/useCpBem';
    const {e} = useCpBem('cp-header');
</script>

<template>
    <div :class="e('container')">
        <slot />
    </div>
</template>

<style scoped lang="scss">
  .cp-header {
    &__container {
      display: flex;
      align-items: center;
      background-color: var(--color-primary);
      padding: 16px;
      gap: $cp-gutter;
      height: 100%;
    }
  }
</style>
