import type {
    IPaginatedResponse,
    IUsageCodesData,
    IUserRating,
    ParseUrlResponse,
    IEtaInfo,
    IEtaCalculatorOptions,
} from '~/types';
import {isValidUrl} from '~/utils';
import {utilsPaths} from '~/utils/services/paths';
import {$cpFetch} from '~/composables/useCpFetch';
import type {IContentReport} from '~/composables';
import type {IDefaultResponse} from '~/utils/services/api/validator/validator';

export type subscriptionPayload = {email: string, articleId: string}

type subscriptionResponse = {
    status: number,
    msg: string | null
} | {
    msg: string,
    code: number,
    errors: {
        status: string,
        code: string,
        title: string,
        detail: string
    }[],
    meta: {
        messagesToDisplay: any[]
    }
}

interface IApiResponse<T> {
    data: T;
    meta: unknown[];
}

export interface IDeniedCarriers {
    deliveryCostGroups: string[];
}

export interface IClabeValidator {
    data?: { bankName: string; bankShortName: string };
}

export const parseICpsp = (receivedICpsp: URLSearchParams) => {
    const cpsp = {};
    receivedICpsp.forEach((value, key) => {
        if (key.includes('cpsp')) {
            // @ts-expect-error yes, we can also index types here.
            cpsp[`${key.replace(/\[|]|\[0]|cpsp/g, '')}`] = value;
        }
    });
    return cpsp;
};
const isKnownController = (
    controller: string,
): controller is ParseUrlResponse['cl'] => {
    return [
        'details', // Product Details
        'alist', // Catalog list by Category
        'manufacturerlist', // Catalog list by Brand
        'search', // Catalog list by Search
        'forgotpwd', // Forgot Password
        'account', // User account details
        'basket', // Basket (cart)
        'emwishlist_accountwishlists', // Wishlists
        'account_order', // Orders history
        'cpaccount_reviews', // My reviews
        'account_user', // Delivery address
        'cpqa_myquestions', // User questions and answers
        'cpreviews_write', // Write review
    ].includes(controller);
};

/** this adds a '/' if its missing */
const fixLink = (link: string) => {
    if (link.endsWith('html') || link.endsWith('/') || link.startsWith('/index.php')) {
        return link;
    }

    const queryPos = link.indexOf('?');
    if (queryPos !== -1) {
        const lastCharParamsPos = queryPos - 1;
        return link[lastCharParamsPos] === '/' ? link : link.replace('?', '/?');
    }
    return link + '/';
};

/**
 * This function parses an SEO-friendly url and returns an object describing the type of resource this url points to
 *
 * Here are some possible scenarios of what the returning object could represent
 *  - A product details page with a category as parent
 *  - A product details page with a brand as parent
 *  - A catalog list for a given category (may include filters)
 *  - A catalog list for a given brand (may include filters)
 *
 *  @param {string} url - Valid URL or Pathname to parse
 * */
export const parseSeoUrl = async (url: string): Promise<ParseUrlResponse | null > => {
    if (url === '/') {
        return ({data: []} as unknown as ParseUrlResponse);
    }

    if (url.includes('cl=cpreviews_write')) {
        return {cl: 'cpreviews_write', anid: ''};
    }

    let urlParam = fixLink(url);
    if (isValidUrl(urlParam)) {
        const urlObject = new URL(urlParam);
        const searchParams = urlObject.searchParams;
        urlParam = urlObject.pathname;
        /** These urls always ends with ".html", must not have a slash at the end
         * (see the next validation) */
        const isPdpUrl = urlParam.substr(-4) === 'html';
        // We need to ensure that URL ends with a slash (parseUrl just works like that).
        if (urlParam.substr(-1) !== '/' && !isPdpUrl) {
            urlParam += '/';
        }
        /**
         * Some urls already contain the info we need in the parameters
         * e.g. https://www.cyberpuerta.mx/index.php?cl=alist&cnid=4fe38c01729fc
         *
         * If this is the case then just return those properties
         * */
        const cl = searchParams.get('cl');
        // Support for catalog-related and details controller only
        if (cl && isKnownController(cl)) {
            /**
             * Depending on the type of cl we will try to get the corresponding parameter from the url
             *
             * For example:
             *
             * If controller is category list (alist), then look for category id (cnid)
             * If controller is brandlist (manufacturerlist), then look for brand id (mnid)
             * */
            let cpsp = {};
            switch (cl) {
                case 'alist': {
                    cpsp = parseICpsp(searchParams);
                    const cnid = searchParams.get('cnid');
                    return cnid ? {cl, cnid, cpsp} : null;
                }
                case 'cpreviews_write': {
                    cpsp = parseICpsp(searchParams);
                    const anid = searchParams.get('anid');
                    return anid ? {cl, anid} : null;
                }
                case 'manufacturerlist': {
                    cpsp = parseICpsp(searchParams);
                    const mnid = searchParams.get('mnid');
                    return mnid ? {cl, mnid, cpsp} : null;
                }
                case 'details': {
                    const anid = searchParams.get('anid');
                    return anid ? {cl, anid} : null;
                }
                case 'search': {
                    cpsp = parseICpsp(searchParams);
                    const searchparam = searchParams.get('searchparam');
                    return searchparam ? {cl, searchparam, cpsp} : null;
                }
                case 'forgotpwd': {
                    const uid = searchParams.get('uid');
                    const email = searchParams.get('email');
                    /**
                     * Handle char '+' edge case. See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams#gotchas
                     *
                     * Found out this gotcha while testing with emails containing a '+'
                     * E.g. email param is "o.bustamante+1@cyberpuerta.mx"
                     *
                     * The email is decoded as "o.bustamante 1@cyberpuerta.mx". We just replace spaces with '+' to overcome this limitation
                     *
                     * This is very rare and won't probably encounter this often since actual emails rarely have this character
                     */
                    const transformEmail = email?.replace(/ /g, '+');
                    return uid && transformEmail ?
                        {cl, uid, email: transformEmail} :
                        null;
                }
                case 'account_order': {
                    const oxorderid = searchParams.get('oxorderid');
                    return oxorderid ? {cl, oxorderid} : null;
                }
            }
        }
    }

    try {
        if (!urlParam.startsWith('https://www.cyberpuerta.mx')) {
            const url = new URL('https://www.cyberpuerta.mx' + urlParam);
            // HACK: These urls always ends with ".html", must not have a slash at the end
            if (url.pathname.endsWith('.html/')) {
                url.pathname = url.pathname.slice(0, -1);
                urlParam = url.toString();
            }
        }
    } catch {}

    const options = {
        params: {
            url: urlParam,
        },
        useMS: true,
        withoutAuthorization: true,
    };
    const data = await $cpFetch<ParseUrlResponse>(utilsPaths.parseSeoUrl, options);
    if (Array.isArray(data)) {
        return null;
    }
    return data;
};

export const getEtaInfo = (etaCalculator: Partial<IEtaCalculatorOptions>) => {
    return $cpFetch<IEtaInfo>(utilsPaths.eta, {
        params: {
            'product-type': etaCalculator ? etaCalculator.productType : 'physical',
            'delivery-method': etaCalculator ? etaCalculator.deliveryMethod : '',
            'base-min': etaCalculator ? etaCalculator.baseMin : 0,
            'base-max': etaCalculator ? etaCalculator.baseMax : 0,
            'zip': etaCalculator.zip,
        },
    });
};

export const getUserRating = (page: number) => {
    return $cpFetch<IPaginatedResponse<IUserRating>>(utilsPaths.globalRank, {
        params: {
            'page[number]': page,
        },
    });
};

export const deleteProductHistory = async () => {
    return await $cpFetch(utilsPaths.productsHistory, {
        method: 'DELETE',
    });
};

export const getUsageCodeById = async (id: string) => {
    return await $cpFetch<{
        tax_regime: IUsageCodesData,
        usage_codes: IUsageCodesData[]
    }>(utilsPaths.usageCodeById(id));
};

export const addBillingDataByIDCIF = async (idCif: string, rfc: string) => {
    return await $cpFetch<{
        data: {
            attributes: {
                business_name: string
                rfc: string
                tax_regimes: {
                    active_since: string
                    regime_id: string
                }[]
                zip_code: string
            }
            type: string
        }
    }>(utilsPaths.billingDataByIdCIF(idCif, rfc));
};

export const addBillingDataCertificate = async (certificate: any) => {
    const formData = new FormData();
    formData.append('certificate', certificate);
    return await $cpFetch<{
        data: {
            attributes: {
                business_name: string
                rfc: string
                tax_regimes: {
                    active_since: string
                    regime_id: string
                }[]
                zip_code: string
            }
            type: string
        }
    }>(utilsPaths.billingDataByCertificate, {
        method: 'POST',
        body: formData,
    });
};

/* Given an CMS id it returns the content of the CMS page in Markdown format */
type CmsContent = {
    content: string;
    description: string;
    fromTime: string;
    height: string;
    id: string;
    img: string;
    meta_tags: string;
    pageTitle: string;
    title: string;
    toTime: string;
    width: string;
};
export const getCmsPage = async (id: string, ignoreParse?: boolean) => {
    return await $cpFetch<CmsContent>(utilsPaths.getCmsPage(id), {
        /* This endpoint returns HTML, so we don't want to parse it as JSON.
         * Because HTML can contains unescaped single/double quotes and breaks all */
        parseResponse: ignoreParse ? undefined : JSON.parse,
    });
  };

export const postContentReport = async (reportData: IContentReport) => {
    return await $cpFetch(utilsPaths.contentReports, {
        method: 'POST',
        body: reportData,
    });
};

export const sendEsdCode = async (code: string) => {
    return await $cpFetch<IDefaultResponse>(utilsPaths.sendEsdCode, {
        method: 'POST',
        params: {codeId: code},
    });
};

export const noStockSubscription = (payload: subscriptionPayload) => {
    return $cpFetch<subscriptionResponse>(utilsPaths.noStockSubscription, {
        method: 'POST',
        body: payload,
    });
};

export const getDeniedCarriers = (userEmail: string) => {
    return $cpFetch<IApiResponse<IDeniedCarriers>>(utilsPaths.userDeniedCarriers(userEmail));
};

export const validateBankAccount = (clabe: string) => {
    return $cpFetch<IClabeValidator>(utilsPaths.validateBankAccount(clabe));
};
