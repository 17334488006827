import nuxtStorage from 'nuxt-storage';

export interface Repository<T> {
  find: (fn?: (e: T) => boolean) => Promise<T[]>
  findOne: (fn: (e: T) => boolean) => Promise<T | null>
  create: (e: T) => Promise<void>
  delete: (fn: (e: T) => boolean) => Promise<void>
}

export class StorageRepository<T> implements Repository<T> {
  private readonly db: string;
  private readonly storage;

  constructor(db: string) {
    this.db = db;
    this.storage = nuxtStorage.default ? nuxtStorage.default : nuxtStorage;
  }

  public async find<T>(fn?: (e: T) => boolean): Promise<T[]> {
    const data = await this.storage.localStorage.getData(this.db);
    if (fn && data) {
      return data.filter(fn);
    }
    return data ?? [];
  }

  public async findOne(fn: (e: T) => boolean): Promise<T | null> {
    const data = await this.storage.localStorage.getData(this.db);
    if (data) {
      return data.find(fn) ?? null;
    }
    return null;
  }

  public async create(e: T): Promise<void> {
    const storedRecentQueries = this.storage.localStorage.getData(this.db);

    if (storedRecentQueries === null) {
      await this.storage.localStorage.setData(this.db, [e], 20, 'd');
    } else if (Array.isArray(storedRecentQueries)) {
      await this.storage.localStorage.setData(this.db, [...storedRecentQueries.slice(-12), e], 20, 'd');
    }
  }

  public async delete(fn: (e: T) => boolean): Promise<void> {
    const storedRecentQueries = await this.storage.localStorage.getData(this.db);
    if (storedRecentQueries === null) {
      return Promise.resolve();
    }
    const newFn = (e: T) => !(fn(e));
    const newRecentQueries = storedRecentQueries.filter(newFn);
    await this.storage.localStorage.setData(this.db, newRecentQueries, 20, 'd');
  }
}
