/**
 * Generates the URLs for the previous and next pages based on the current URL,
 * total number of items, and page size.
 * 
 * This function looks for a pagination pattern ("/PGP{page_number}/") in the URL,
 * and replaces it with the correct page number for previous and next pages.
 * 
 * @param {string} realUrl - The current full URL, including query parameters.
 * @param {number} totalItems - The total number of items available for pagination.
 * @param {number} pageSize - The number of items to display per page.
 * 
 * @returns {[string, string]} - An array with two elements: 
 *    - The first element is the URL for the previous page. 
 *    - The second element is the URL for the next page.
 *    - If there is no previous or next page, the corresponding URL will be an empty string.
 * 
 * Example:
 * If the current URL is "/example/PGP2/" and there are 100 items with a page size of 10, 
 * the function will return the previous and next page URLs like: ["/example/1/", "/example/3/"].
 */
export const getPrettyPageLinks = (realUrl: string, totalItems: number, pageSize: number) => {
    const currentUrl = new URL(realUrl);
    const url = currentUrl.pathname;
    const totalPages = Math.ceil(totalItems / pageSize);
    // Search for the pagination pattern "PGP" followed by a number
    const regex = /\/PGP(\d+)\//;
    const match = url.match(regex);

    if (match) {
        // Extract the current page number
        let currentPage = Number(match[1]);

        /** 
         * Checks if the current page number exceeds the total number of pages.
         * If it does, it decreases the current page by one to ensure it doesn’t exceed the limit.
         */
        if (currentPage > totalPages) {
            currentPage--;
        }

        const previousPage = currentPage - 1;
        const nextPage = currentPage + 1;

        // If the previous page number is greater than 1, 
        // define the URL part for the previous page as "/{previousPage}/". 
        // If the previous page is page 1, use '/' to indicate the first page (root).
        const previous = previousPage > 1 ? `/${previousPage}/` : '/';
        // Construct the URL for the previous page by replacing the current page 
        // in the URL with the previous page, but only if previousPage is greater than 0
        // because previous link does not exist
        const previousUrl = previousPage > 0 ? url.replace(regex, previous) : '';
        // Construct the URL for the next page by replacing the current page 
        // in the URL with the next page, but only if nextPage is less than or equal to the total pages
        // because following link does not exist
        const nextUrl = nextPage <= totalPages ? url.replace(regex, `/${nextPage}/`) : '';

        return [previousUrl, nextUrl];
    }

    return ['', ''];
}
