import mitt from 'mitt';

type loginProps = {
  show: boolean
  route?: string
  params?: any
  query?: any
}

type ApplicationEvents = {
  'nav-menu:change': 'home' | 'categories' | 'account' | 'menu',
  'offline-retry-action': void,
  'show-login-modal': loginProps,
  'show-offline-state': void,
  'scroll-to-top': void,
}

const emitter = mitt<ApplicationEvents>();

/** @deprecated CAUTION! can cause memory-leaks, please use in extreme circumstances and only CLIENT SIDE (not really depreacted) */
export const useEvent = emitter.emit;
/** @deprecated CAUTION! can cause memory-leaks, please use in extreme circumstances and only CLIENT SIDE (not really depreacted) */
export const useListen = emitter.on;
