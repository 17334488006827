<script setup lang="ts">
    import CpText from '~/components/cp/CpText/CpText.vue';
    import useCpBem from '~/composables/useCpBem';

    defineProps<{
      title: string,
      headline?: string,
      isFirst?: boolean
      isFakeTitle?: boolean
    }>();
    defineEmits<{
      (e: 'title'): void
    }>();
    const {b, e} = useCpBem('cp-header-title');
</script>

<template>
    <div :class="b" role="button" @click="$emit('title')">
        <div :class="e('content')">
            <CpText type="white" :variant="(headline || isFakeTitle) ? 'heading-h1-fake': 'heading-h1'">
                {{ title }}
            </CpText>
        </div>
        <CpText v-if="headline" type="white" :variant="'subheading'">
            {{ headline }}
        </CpText>
    </div>
</template>

<style scoped lang="scss">
  .cp-header-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;

    &__content {
      max-height: 48px;
      overflow: hidden;
    }
  }
</style>
