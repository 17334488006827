<script setup lang="ts">
  import {watch} from 'vue';
  import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';
  import CpButtonIcon from '~/components/cp/CpButtonIcon/CpButtonIcon.vue';
  import {useBasket} from '~/composables/queries/basket';
  import {useCpUser} from '~/composables/useCpUser';
  import {useCheckoutStore} from '~/stores';

  const user = useCpUser();

  const {data} = useBasket({
    server: false,
    lazy: true,
    watch: [user.isAuthenticated],
  });

  watch(() => data.value, () => {
    useCheckoutStore().setBasketCount(data.value || 0);
  });
</script>

<template>
    <CpNuxtLink page-name="basket" data-testid="header-basket-btn">
        <CpButtonIcon :number="data?.basket.numberOfItems ?? undefined" kind="cart" type="white" />
    </CpNuxtLink>
</template>

<style scoped lang="scss">

</style>
