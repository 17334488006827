<script setup lang="ts">
    import {computed, ref} from 'vue';
    import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
    import useCpBem from '~/composables/useCpBem';
    import {useColors} from '~/composables/useCpType';
    import type {ICpTypeWithModifier} from '~/composables/useCpType';
    import CpCartCounter from '~/components/cp/CpCartCounter/CpCartCounter.vue';
    import type {CpButtonIconKind} from '@/types';

    const props = withDefaults(defineProps<{
        type?: 'primary'|'success'|'warning'|'secondary'|'critical'|'white'|'neutral'|
            'inCategory',
        kind?: CpButtonIconKind,
        disabled?: boolean,
        number?: number,
        hideDetails?: boolean
        firstStateOpen?: boolean
    }>(), {
        type: 'primary',
        kind: 'chevron',
        disabled: false,
        firstStateOpen: false,
    });

    const emit = defineEmits({
      click: null,
    });

    const colorDecoration = computed(() => {
        if (props.type === 'inCategory') {
            return {
                background: 'neutral',
                active: 'neutral-dark',
            };
        } else if (props.type === 'white') {
            return {
                background: 'white',
                active: 'primary-light',
            };
        } else if (props.type === 'neutral') {
            return {
                background: 'neutral-light',
                active: 'neutral',
            };
        }
        return useColors(props.type, {disabled: props.disabled});
    });
    const color = computed(() => {
        let c = 'neutral-light';
        if (active.value) {
            c = colorDecoration.value.active;
        } else {
            c = colorDecoration.value.background;
        }
        return c as ICpTypeWithModifier;
    });
    const active = ref(false);
    const icon = computed(() => {
        switch (props.kind) {
            case 'chevron':
                return 'chevron_right';
            case 'actions':
                return 'more_horiz';
            case 'question':
                return 'help';
            case 'delete':
                return 'delete_forever';
            case 'voted_on':
                return 'thumb_up';
            case 'voted_off':
                return 'thumb_down';
            case 'empty':
                return 'star_border';
            case 'half':
                return 'star_half';
            case 'full':
                return 'star';
            case 'filter':
                return 'filter_list';
            case 'cart':
                return 'shopping_cart';
            default:
                return props.kind;
        }
    });

    const isAccordion = ref(props.kind === 'accordion');
    const iconAccordion = ref(props.firstStateOpen ? 'expand_less' : 'expand_more');

    function changeState() {
      if (iconAccordion.value) {
        if (iconAccordion.value === 'expand_more') {
          iconAccordion.value = 'expand_less';
        } else {
          iconAccordion.value = 'expand_more';
        }
      }
      emit('click');
    }
    const {bm, e, em} = useCpBem('cp-button-icon');
</script>

<template>
    <CpIconStandard
        v-if="kind!=='cart' && kind!=='filter'"
        :class="bm({disabled: disabled || icon.toString().includes('star')})"
        :icon="isAccordion ? iconAccordion : icon"
        :type="disabled ? 'neutral-light' : color"
        @mousedown="active = true"
        @mouseup="active = false"
        @touchstart.passive="active = true"
        @touchend.passive="active = false"
        @click="changeState"
    />
    <div v-else :class="bm({disabled: disabled || icon.toString().includes('star')})">
        <CpCartCounter
            v-if="kind==='cart' && number > 0"
            :class="e`adjustment`"
            :number="number"
        />
        <div
            v-if="kind==='filter' && !hideDetails"
            :class="e`point`"
        />
        <CpIconStandard
            v-if="kind !=='cart'"
            :class="em(`main-icon`, { fixed: !hideDetails })"
            :icon="icon"
            :type="disabled ? 'neutral' : color"
            @mousedown="active = true"
            @mouseup="active = false"
            @touchstart.passive="active = true"
            @touchend.passive="active = false"
            @click="emit('click')"
        />

        <CpIconStandard
            v-if="kind==='cart'"
            :class="em(`main-icon`, { 'adjustment-cart': kind==='cart' && number > 0 })"
            :icon="icon"
            :type="disabled ? 'neutral' : color"
            @mousedown="active = true"
            @mouseup="active = false"
            @touchstart.passive="active = true"
            @touchend.passive="active = false"
            @click="emit('click')"
        />
    </div>
</template>

<style lang="scss" scoped>
    .cp-button-icon {
        cursor: pointer;
        user-select: none;
        &--disabled {
            pointer-events: none;
        }
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-content: center;
        }
        &__adjustment {
            position: relative;
            margin: auto;
            z-index: 1;
        }
        &__point {
            position: relative;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: var(--color-secondary);
            margin: auto;
            z-index: 1;
        }
        &__icon-container {
            width: 24px !important;
            height: 24px !important;
        }
        &__main-icon {
            margin: auto;
            &--fixed {
                position: relative;
                top: -8px;
            }

            &--adjustment-cart {
                margin: -8px auto 0 auto;
            }
        }
    }
</style>
