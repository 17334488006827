import {$cpFetch} from '~/composables/useCpFetch';
import type {BannerController} from '~/utils';
import type {IBannerContext, IBannerPlace} from '~/types';
import {utilsPaths} from '~/utils/services/paths';

export interface IBannerPlaceParams {
  controllerId: BannerController;
  placeId: string;
  context?: IBannerContext[];
}
export const getBannerPlaces = async ({
  controllerId,
  context,
  placeId,
}: IBannerPlaceParams) => {
  const result = await getBanners({controllerId, context, placeId});
  return result.find((bannerPlace) => bannerPlace.place === placeId);
};

export const getBanners = ({controllerId, context}: IBannerPlaceParams) => {
  const placesIds = CONTROLLER_PLACES[controllerId];
  const newContext = context?.reduce((a: any, c, i) => {
    return {
      [`context[${i}][name]`]: c.name,
      [`context[${i}][value]` + (Array.isArray(c.value) ? '[]' : '')]: c.value,
      ...a,
    };
  }, {}) ?? {};

  return $cpFetch<IBannerPlace[]>(utilsPaths.banners, {
    params: {
      'places[]': placesIds,
      'cl': controllerId,
      /** It was necessary to add this value due to a timestamp problem with the server */
      'expires': 1,
      'shop': '4f1f198301cd6',
      'now': Number.parseInt((Date.now() / 1000).toString()),
      ...newContext,
    },
  });
};
