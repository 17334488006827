import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';
import type {ParseUrlResponse} from '~/types';
interface ParseSeoUrlStore {
  urls: Record<string, ParseUrlResponse>
}
export const useParseSeoUrlStore = defineStore(STORE_KEYS.ParseSeoUrl, {
  state: (): ParseSeoUrlStore => ({
    urls: {},
  }),
  actions: {
    setByUrl(url: string, parseSeoData: ParseUrlResponse) {
      this.urls[url] = parseSeoData;
    },
  },
  getters: {
    getUrls(): Record<string, ParseUrlResponse> {
        return this.urls;
    },
  },
});
