<script setup lang="ts">
  import {ref, watch, onMounted} from 'vue';
  import {useI18n} from 'vue-i18n';
  import useCpBem from '~/composables/useCpBem';
  import CpIconStandard from '~/components/cp/CpIcon/CpIconStandard/CpIconStandard.vue';
  import CpHeaderContainer from '~/components/cp/CpHeader/CpHeaderContainer.vue';
  import CpHeaderTitle from '~/components/cp/CpHeader/CpHeaderTitle.vue';
  import {useCpGoBack} from '~/composables/useCpGoBack';
  import {useProfileStore} from '~/stores';
  import {useRecentQueriesStore} from '~/stores/recentQueriesStore';
  import CpNuxtLink from '~/components/cp/CpNuxtLink/CpNuxtLink.vue';
  import CpHeaderBasketBtn from '~/components/cp/CpHeader/CpHeaderBasketBtn.vue';
  import {useCpUser} from '~/composables/useCpUser';
  import {useSearchTerm} from '~/composables/useSearchTerm';

  const props = withDefaults(defineProps<{
    title?: string,
    headline?: string,
    numOfItems?: number,
    showSearch?: boolean,
    placeholder?: string,
    showCart?: boolean,
    showGoBack?: boolean,
    showAccount?: boolean,
    blueVersion?: boolean,
    showLongSearchBar?: boolean,
    searchFocus?: boolean
    isFakeTitle?: boolean
  }>(), {
    title: undefined,
    headline: undefined,
    placeholder: undefined,
    numOfItems: undefined,
    showSearch: false,
    showAccount: false,
    blueVersion: false,
    showCart: true,
    showGoBack: false,
    showLongSearchBar: true,
    isFakeTitle: false,
  });
  const {b, e} = useCpBem('cp-header');

  const searchBarElement = ref();

  defineEmits<{
    (e: 'navigateToSearch'): void
    (e: 'navigateToBasket'): void
  }>();
  const store = useCpUser();
  const recentQueries = useRecentQueriesStore();
  const profileStore = useProfileStore();
  const {t} = useI18n();

  function showModal() {
    if (!store.user.value && (props.headline === t('logInToYourCount') || props.headline === t('logInHere'))) {
 useEvent('show-login-modal', {show: true, route: 'home'});
}
  }

  onMounted(() => {
    if (props.showLongSearchBar && props.searchFocus) {
      searchBarElement.value?.focus();
    }
  });

  watch(() => props.searchFocus, () => {
    if (props.showLongSearchBar && props.searchFocus) {
      searchBarElement.value?.focus();
    }
  }, {flush: 'post'});

  watch(() => recentQueries.focus, (newVal) => {
    if (newVal) {
      searchBarElement.value?.focus();
    }
  }, {flush: 'post'});

  const searchSubmit = async (query: string) => {
    if (!query || !query?.trim().length) {
      return;
    }
    await useSearchTerm(query);
  };

  const searchTimer = ref<NodeJS.Timeout>();
  const searchChange = () => {
    if (searchTimer.value) {
      clearTimeout(searchTimer.value);
    }
    searchTimer.value = setTimeout(() => {
      recentQueries.refreshAutocomplete();
    }, 300);
  };

  const searchBlur = () => {
    recentQueries.focus = false;
  };

  const showSearchClick = () => {
    if (!props.searchFocus) {
      navigateTo({
          name: 'search',
          params: {q: recentQueries.selectedQuery},
        },
      );
    }
  };
</script>

<template>
    <div :class="b">
        <CpHeaderContainer>
            <CpIconStandard
                v-if="showGoBack"
                icon="arrow_back"
                active-color="primary-light"
                type="white"
                data-testid="header-go-back-btn"
                @click="useCpGoBack"
            />
            <div v-if="showLongSearchBar" :class="e('full')">
                <LazyCpSearchBar
                    v-if="showLongSearchBar"
                    ref="searchBarElement"
                    v-model="recentQueries.selectedQuery"
                    data-testid="search-bar-input"
                    :placeholder="placeholder"
                    :blue-version="blueVersion"
                    :readonly="!searchFocus"
                    @submit="searchSubmit"
                    @change="searchChange"
                    @blur="searchBlur"
                    @click="showSearchClick"
                />
            </div>
            <div v-if="!showLongSearchBar" :class="e('full')" @click="showModal()">
                <CpHeaderTitle v-if="title"
                    :title="title"
                    :is-fake-title="isFakeTitle"
                    :headline="headline"
                    data-testid="header-title"
                />
            </div>
            <CpNuxtLink v-if="showAccount" page-name="account">
                <LazyCpAvatar
                    v-if="showAccount"
                    size="small"
                    :image="profileStore.getAvatarImage"
                    :alt="store.user.value?.nameAccount ? store.user.value?.nameAccount : ''"
                />
            </CpNuxtLink>
            <ClientOnly>
                <CpNuxtLink v-if="showSearch" page-name="search" data-testid="search-bar-input-short">
                    <div :class="e('search-icon')">
                        <CpIconStandard icon="search" type="primary" @click="$emit('navigateToSearch')" />
                    </div>
                </CpNuxtLink>
                <CpHeaderBasketBtn v-if="showCart" />
            </ClientOnly>
        </CpHeaderContainer>
    </div>
</template>

<style scoped lang="scss">
  .cp-header {
    height: 72px;
    &__nuxt-link {
      text-decoration: none;
    }
    &__full {
      flex: 1;
    }
    &__search-icon {
      height: 32px;
      width: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: var(--color-white);
    }
  }
</style>

<i18n locale="es-US" lang="yaml">
  logInToYourCount: 'Ingresa a tu cuenta'
  logInHere: 'Ingresa aquí'
</i18n>
