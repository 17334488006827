import {defineStore} from 'pinia';
import {STORE_KEYS} from './storeKeys';
import type {IArticleOrder, IOrder, IOrdersStore} from '~/types';

export const useOrdersStore = defineStore(STORE_KEYS.orders, {
    state: (): IOrdersStore => ({
        currentSearch: '',
        defaultTimeInterval: undefined,
        timeInterval: 3,
        totalOrders: undefined,
        selectedOrderId: '',
        createdOrder: {
            number: '',
            realTimePayment: false,
        },
        orderTrackerId: '',
        orderData: null,
        selectedOrderArticle: null,
    }),
    actions: {
        setCurrentSearch(currentSearch: string) {
            this.currentSearch = currentSearch;
        },
        setDefaultTimeInterval(defaultTimeInterval: number | 'archived' | undefined) {
            this.defaultTimeInterval = defaultTimeInterval;
        },
        setTimeInterval(timeInterval: number | 'archived' | undefined) {
            this.timeInterval = timeInterval;
        },
        setTotalOrders(totalOrders: number | undefined) {
            this.totalOrders = totalOrders;
        },
        removeTimeInterval() {
            this.timeInterval = undefined;
        },
        removeAllFilters() {
            this.currentSearch = '';
            this.timeInterval = undefined;
        },
        setOrderId(id: string) {
            this.selectedOrderId = id;
        },
        setOrderCreatedData(number = '', realTimePayment = false) {
            this.createdOrder.number = number;
            this.createdOrder.realTimePayment = realTimePayment;
        },
        setOrderTrackerId(id: string) {
            this.orderTrackerId = id;
        },
        setOrder(order: IOrder | null) {
            this.orderData = order;
        },
        setSelectedOrderArticle(article: IArticleOrder | null) {
            this.selectedOrderArticle = article;
        },
        resetOrders() {
            this.selectedOrderArticle = null;
            this.orderData = null;
        },
    },
    getters: {
        getOrdersStore(): { currentSearch: string, timeInterval: number | 'archived' | undefined } {
            return {
                currentSearch: this.currentSearch,
                timeInterval: this.timeInterval,
            };
        },
        getSelectedOrderId: (state) => state.selectedOrderId,
        getCreatedOrderData: (state) => state.createdOrder,
        getOrderTrackerId: (state) => state.orderTrackerId,
        getOrderData: (state) => state.orderData,
        getSelectedOrderAticle: (state) => state.selectedOrderArticle,
    },
});
